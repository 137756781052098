import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { formatDateTime } from '../../Utils/dateTime'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  firstCell: {
    fontWeight: 500,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    width: '12.5rem'
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  fullWidth: {
    width: '50%'
  },
  sent: {
    color: Colors.green
  }
}))

function NotificationTableRow (props) {
  const { item }: any = props
  const classes = useStyles()

  const handleClick = () => {
    if (item.type === 'coupon') {
      props.history.push(`/coupon-notifications/${item.id}`)
    } else {
      props.history.push(`/notifications/${item.id}`)
    }
  }
  const statusClass = item.active ? '' : classes.deleted

  const renderConversion = () => {
    if (!item.receiverCount || !item.viewCount) return ''
    const ratio = (item.viewCount || 0) / item.receiverCount
    // Error?
    if (ratio > 1) return ''

    return `${Math.round(ratio * 100)} %`
  }

  const formatValue = (val) => {
    if (val === null) return ''
    return val
  }

  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell, item.sentAt ? classes.sent : ''].join(' ')}>
        {formatDateTime(item.publishedAt)}
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.fullWidth].join(' ')}>{item.titleFi || item.titleEn}</TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        {formatValue(item.receiverCount)}
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        {formatValue(item.viewCount)}
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.lastCell].join(' ')}>
        {renderConversion()}
      </TableCell>
    </TableRow>
  )
}

export default withRouter(NotificationTableRow)
