import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    borderLeft: `1px solid ${Colors.lightBorder}`,
    width: '26.25rem',
    display: 'flex',
    flexDirection: 'column'
  },
  innerContainer: {
    padding: '2rem'
  }
}))

const PageActions = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {props.children}
      </div>
    </div>
  )
}

export default PageActions
