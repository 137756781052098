import React from 'react'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Api } from '../../Services'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  rootDeleted: {
    border: `1px solid ${Colors.red}`
  },
  truncated: {
    maxWidth: '16rem',
    maxHeight: '4em',
    overflow: 'hidden'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  firstCell: {
    fontWeight: 500,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontWeight: 500
  }
}))

function CouponCampaignTableRow (props) {
  const { item } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const getQrCodeLink = (campaignId) => Api.generateQrCodeDownloadLink(campaignId)

  const handleClick = () => props.history.push(`/campaigns/${item.id}`)

  return (
    <TableRow classes={{ root: props.item.active ? classes.root : classes.rootDeleted }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        <span>{get(item, 'nameFi')}</span>
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        <div className={classes.truncated}>
          <span>{get(item, 'couponCampaignOrganizations.length')}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {get(item, 'scanCount')}
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {get(item, 'useCount')}
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {get(item, 'startDate') ? moment(item.startDate).format('DD.MM.YYYY') : null}
        {' - '}
        {get(item, 'endDate') ? moment(item.endDate).format('DD.MM.YYYY') : null}
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {get(item, 'creatorName')}
      </TableCell>
      <TableCell className={classes.cell}>
        {get(item, 'couponCampaignOrganizations.length', 0) ? (
          <a href={getQrCodeLink(item.id)} target="_blank" rel="noreferrer">
            {t('download_qr_codes')}
          </a>
        ) : null}
      </TableCell>
    </TableRow>
  )
}

export default withRouter(CouponCampaignTableRow)
