import React from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { DataMessage } from '..'
import NotificationTableRow from './NotificationTableRow'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  },
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: '2.5rem',
    padding: '0 1rem',
    backgroundColor: Colors.tableHeaderBackgroundColor,
    color: Colors.tableHeaderTextColor,
    fontWeight: 700,
    fontFamily: Fonts.heading,
    textTransform: 'uppercase'
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    width: '12.5rem'
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }
}))

function NotificationTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.spacer} />
          <NotificationTableRow item={item} type={props.type} />
        </React.Fragment>
      ))
    }
    return null
  }

  const renderHeader = () => {
    const columnNames = [t('send_time'), t('title'), t('receivers'), t('openers'), t('conversion')]

    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          {columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.firstCell}`
            else if (index === columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`
            return <TableCell key={index} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <>
      <Table
        classes={{ root: classes.root }}
      >
        {renderHeader()}
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_notifications')} />
    </>
  )
}

export default NotificationTable

