import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { Modal } from '@material-ui/core';
import AddIcon from '../../Assets/Icons/add-dark.svg'
import DeleteIcon from '../../Assets/Icons/chip-delete.svg'
import SearchIcon from '../../Assets/Icons/search.svg'
import AlertIcon from '../../Assets/Icons/alert.svg'

import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Input, Title } from '..';
import { chunk } from 'lodash';
import BaseDialog from '../Common/Dialog';


const useStyles = makeStyles((theme) => ({
  container: {
  },
  chipsContainer: {
  },
  footerSpacer: {
    height: '3rem'
  },
  clearFix: {
    display: 'table',
    clear: 'both'
  },
  icon: {
    height: '100%',
    width: '100%',
    maxHeight: '2rem',
    marginLeft: '1rem'
  },
  deleteIcon: {
    height: '100%',
    width: '100%',
    maxHeight: '1.5rem'
  },

  addButton: {
    boxSizing: 'border-box',
    minHeight: '3.5rem',
    marginRight: '1.875rem',
    marginBottom: '1.25rem',
    float: 'left',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1.25rem',
    color: Colors.black,
    border: `1px solid ${Colors.black}`,
    padding: '0 1rem',
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 1px ${Colors.black}`
    }
  },
  organizationButton: {
    minHeight: '3.5rem',
    marginRight: '1.25rem',
    marginBottom: '1.25rem',
    float: 'left',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1.25rem',
    color: Colors.white,
    border: `1px solid #343434`,
    paddingLeft: '1rem',
    backgroundColor: '#343434', // NOTE: If you change this color, also change color from chip-delete SVG icon
    borderRadius: '0.3125rem'
  },
  organizationButtonName: {

  },
  organizationButtonDeleteContainer: {
    height: '3.125rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    '&:hover': {
      opacity: 0.8
    }
  },
  modalContainer: {
    backgroundColor: Colors.white,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: '5vh',
    bottom: '5vh',
    left: '7vw',
    right: '7vw',
    borderTopLeftRadius: '.3125rem',
    borderTopRightRadius: '.3125rem'
  },
  modalHeader: {
    padding: '2rem 2rem 0'
  },
  modalActions: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.inputGrey,
    padding: '1.25rem 2rem 0'
  },
  modalContentContainer: {
    flex: 1,
    overflow: 'auto'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 2rem 2rem',
    flex: 1,
  },
  modalColumn: {
    padding: '0 1rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  modalFooter: {
    borderTop: `1px solid ${Colors.lightBorder}`,
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem 2rem 1rem 3rem'
  },
  modalCounterRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    fontSize: '1.25rem',
    color: Colors.content,
    fontFamily: Fonts.content
  },
  modalSpacer: {
    width: '1rem'
  },
  alertIcon: {
    height: '100%',
    width: '100%',
    maxWidth: '1.75rem',
    marginLeft: '1.75rem',
    marginRight: '.66rem'
  },
  clearSelections: {
    fontSize: '1.25rem',
    color: Colors.content,
    padding: '1rem',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  modalButton: {
    minWidth: '11.25rem'
  }
}))

export default function OrganizationSelector(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [newOrganizationIds, setNewOrganizationIds] = useState([])
  const [deleteOrganizationId, setDeleteOrganizationId] = useState(null)

  const toggleModal = () => {
    if (!modalVisible) {
      // Update state
      setNewOrganizationIds([...props.organizationIds])
    }
    setSearch('')
    setModalVisible(!modalVisible)
  }

  const toggleOrganization = (organizationId) => {
    const updatedOrganizationIds = props.organizationIds.filter(orgId => orgId !== organizationId)
    if (updatedOrganizationIds.length === props.organizationIds.length) {
      updatedOrganizationIds.push(organizationId)
    }
    props.setOrganizationIds(updatedOrganizationIds)
  }

  const deleteOrganization = (id) => {
    if (props.deleteWarning) {
      if (!deleteOrganizationId) {
        setDeleteOrganizationId(id)
      } else {
        toggleOrganization(id)
        setDeleteOrganizationId(null)
      }
    } else {
      toggleOrganization(id)
    }
  }

  const toggleNewOrganization = (organizationId) => {
    const updatedNewOrganizationIds = newOrganizationIds.filter(orgId => orgId !== organizationId)
    if (updatedNewOrganizationIds.length === newOrganizationIds.length) {
      updatedNewOrganizationIds.push(organizationId)
    }
    setNewOrganizationIds(updatedNewOrganizationIds)
  }

  const clearNewOrganizations = () => {
    setNewOrganizationIds(props.organizationIds)
  }

  const commitNewOrganizations = () => {
    props.setOrganizationIds(newOrganizationIds)
    toggleModal()
  }

  const renderSelectedOrganization = (organization) => {
    return (
      <button
        className={classes.organizationButton}
      >
        <div className={classes.organizationButtonName}>
          {organization.name}
        </div>
        <div
          className={classes.organizationButtonDeleteContainer}
          onClick={() => deleteOrganization(organization.id)}
        >
          <img src={DeleteIcon} className={classes.deleteIcon} alt='remove' />
        </div>
      </button>
    )
  }

  const renderSelectedOrganizations = () => {
    return props.organizationIds.map(organizationId => {
      const match = props.organizations.find(org => org.id === organizationId)
      if (match) {
        return renderSelectedOrganization(match)
      }
      return null
    })
  }

  const renderOrganizationSelect = (organization) => {
    return (
      <Checkbox
        label={organization.name}
        onChange={() => toggleNewOrganization(organization.id)}
        checked={newOrganizationIds.includes(organization.id)}
      />
    )
  }

  const getOrganizationOptions = () => {
    const filteredOrganizations = props.organizations.filter(org => {
      // Don't display already selected organizations
      if (props.organizationIds.includes(org.id)) return false

      // Don't display inactive organizations
      if (!org.active) return false

      return (!search || (org.name ? org.name.toLowerCase(): '').includes(search.toLowerCase()))
    })

    return filteredOrganizations
  }

  const renderModalSelectCounter = () => {
    return (
      <div className={classes.modalCounterRow}>
        <img src={AlertIcon} className={classes.alertIcon} alt='info' />
        {`${(newOrganizationIds.length - props.organizationIds.length)} ${t('num_companies_selected')}`}
      </div>
    )
  }

  const renderModal = () => {
    const organizations = getOrganizationOptions()

    // Responsiveness
    let chunkDivider = 1
    if (window.innerWidth > 854) chunkDivider = 2
    if (window.innerWidth > 1280) chunkDivider = 3

    const chunkSize = Math.ceil(organizations.length / chunkDivider) || 1
    const organizationChunks = chunk(organizations, chunkSize)

    return (
      <Modal
        open={modalVisible}
        onClose={toggleModal}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalHeader}>
            <Title
              title={t('select_companies')}
              description={t('select_companies_description')}
              type='subtitle'
            />
          </div>
          <div className={classes.modalActions}>
            <Input
              placeholder={t('filter_by_name')}
              value={search}
              onChange={setSearch}
              leftIcon={SearchIcon}
              whiteBackground
            />
          </div>
          <div className={classes.modalContentContainer}>
            <div className={classes.modalContent}>
              {organizationChunks.map(chunk => {
                return (
                  <div className={classes.modalColumn}>
                    {chunk.map(renderOrganizationSelect)}
                  </div>
                )
              })}
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              text={t('save')}
              onClick={commitNewOrganizations}
              buttonStyle={classes.modalButton}
            />
            <div className={classes.modalSpacer} />
            <Button
              text={t('cancel')}
              onClick={toggleModal}
              buttonStyle={classes.modalButton}
              secondary
              variant
            />
            {renderModalSelectCounter()}
            <div onClick={clearNewOrganizations} className={classes.clearSelections}>
              {t('clear_selections')}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  const renderConfirmDeleteModal = () => {
    if (props.deleteWarning) {
      return (
        <BaseDialog
          open={!!deleteOrganizationId}
          handleClose={() => setDeleteOrganizationId(null)}
          handleOk={() => deleteOrganization(deleteOrganizationId)}
          title={props.deleteWarning.title}
          description={props.deleteWarning.content}
        />
      )
    }
    return null
  }

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.chipsContainer}>
          <button
            className={classes.addButton}
            onClick={toggleModal}
          >
            {t('add_companies')}
            <img src={AddIcon} className={classes.icon} alt='add' />
          </button>
          {renderSelectedOrganizations()}
        </div>
        <div className={classes.clearFix} />
      </div>
      {renderModal()}
      {renderConfirmDeleteModal()}
    </div>
  )
}
