import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { Colors } from '../../Utils/theme'
import { UINotification } from '../../Components'
import Logo from '../../Assets/Images/app_logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  leftColumn: {
    boxSizing: 'border-box',
    flex: 1,
    padding: '2rem',
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  rightColumn: {
    minHeight: '100vh',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url('/images/background.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundColor: Colors.sidebarBackgroundColor,
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem',
      minHeight: '6rem',
      height: '3rem'
    }
  },
  logo: {
    userSelect: 'none',
    height: 'auto',
    width: '100%',
    maxWidth: '18rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '12rem'
    }
  }
}))

function AuthLayout(props) {
  const classes = useStyles()

  const { appStore } = useStore()
  const { notification, clearNotification } = appStore

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.rightColumn}>
          <div>
            <img src={Logo} className={classes.logo} alt='logo' />
          </div>
        </div>
        <div className={classes.leftColumn}>
          {props.children}
        </div>
      </main>
      <UINotification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AuthLayout)
