import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { v4 as uuidv4 } from 'uuid'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Dialog, PollForm, PollActions, PageContainer, PageContent, PageActions } from '../../../Components'

const CreatePoll = (props) => {
  const { pollStore } = useStore()

  useEffect(() => {
    pollStore.getPollCategories()
  }, [])

  useEffect(() => {
    if (pollStore.createOk) {
      props.history.push('/polls')
    }
  }, [pollStore.createOk])

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')

  const [startDateNow, setStartDateNow] = useState(true)
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [startTime, setStartTime] = useState('00:00')
  const [endDate, setEndDate] = useState(null)
  const [endTime, setEndTime] = useState(null)

  const [draft, setDraft] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [missingTranslationsWarning, setMissingTranslationsWarning] = useState(false)

  const [userLang, setUserLang] = useState(null)
  const [userTypes, setUserTypes] = useState(null)
  const [categoryId, setCategoryId] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [video, setVideo] = useState(null)
  const [mediaType, setMediaType] = useState(null)

  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setVideo(null)
      setMediaType('photo')
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setVideo(null)
    setMediaType(null)
    setNewPhoto(null)
  }

  const handleDeleteVideo = () => {
    setMediaType(null)
    setVideo(null)
    setPhoto(null)
  }

  // Initialize with one empty question
  const [questions, setQuestions] = useState([{
    id: uuidv4(),
    type: 'select',
    titleFi: '',
    titleEn: '',
    contentFi: '',
    contentEn: '',
    answersFi: ['', ''],
    answersEn: ['', '']
  }])

  const handleSetStartDate = (val) => setStartDate(val)
  const handleSetStartTime = (val) => setStartTime(val)
  const handleSetEndDate = (val) => setEndDate(val)
  const handleSetEndTime = (val) => setEndTime(val)

  const { t } = useTranslation()

  const missingTranslations = () => {
    let missingTranslation = (!!titleFi !== !!titleEn) || (!!contentFi !== !!contentEn)
    // Check questions
    for (const q of questions) {
      if ((!!q.titleFi !== !!q.titleEn) || (!!q.contentFi !== !!q.contentEn)) {
        missingTranslation = true
      }
    }
    return missingTranslation
  }

  const createPoll = () => {
    if (!userLang && missingTranslations() && !missingTranslationsWarning) {
      return setMissingTranslationsWarning(true)
    }

    let newStartDate: any = null
    if (!draft && startDate) {
      newStartDate = moment(startDate).startOf('day').format()
      if (startTime) {
        const timeMoment = moment('2000-01-01 ' + startTime)
        newStartDate = moment(newStartDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }
    if (!startDate && !startTime && startDateNow) {
      newStartDate = moment().add(5, 'seconds').startOf('minute')
    }

    let newEndDate: any = null
    if (endDate) {
      newEndDate = moment(endDate).startOf('day').format()
      if (endTime) {
        const timeMoment = moment('2000-01-01 ' + endTime)
        newEndDate = moment(newEndDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    setSubmitted(true)

    pollStore.createPoll({
      draft,
      titleFi,
      titleEn,
      contentFi,
      contentEn,
      newPhoto: newPhoto,
      video,
      validFrom: newStartDate ? moment(newStartDate).toISOString() : newStartDate,
      validUntil: newEndDate ? moment(newEndDate).toISOString() : newEndDate,
      questions,
      categoryId
    })
  }

  const renderContent = () => {
    return (
      <PollForm
        video={video}
        setVideo={setVideo}
        handleDeleteVideo={handleDeleteVideo}
        photo={photo}
        handleSetPhoto={handleSetPhoto}
        handleRemovePhoto={handleRemovePhoto}
        mediaType={mediaType}
        setMediaType={setMediaType}
        titleFi={titleFi}
        setTitleFi={setTitleFi}
        titleEn={titleEn}
        setTitleEn={setTitleEn}
        contentFi={contentFi}
        setContentFi={setContentFi}
        contentEn={contentEn}
        setContentEn={setContentEn}
        questions={questions}
        setQuestions={setQuestions}
        userLang={userLang}
        setUserLang={setUserLang}
        userTypes={userTypes}
        setUserTypes={setUserTypes}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        categories={pollStore.pollCategories}
        submitted={submitted}
      />
    )
  }

  return (
    <>
      <PageHeader
        title={t('create_poll')}
      />
      <PageContainer>
        <PageContent size='small'>
          {renderContent()}
        </PageContent>
        <PageActions>
          <PollActions
            startDateNow={startDateNow}
            setStartDateNow={setStartDateNow}
            startDate={startDate}
            setStartDate={handleSetStartDate}
            startTime={startTime}
            setStartTime={handleSetStartTime}
            endDate={endDate}
            setEndDate={handleSetEndDate}
            endTime={endTime}
            setEndTime={handleSetEndTime}
            draft={draft}
            setDraft={setDraft}
            onSave={createPoll}
            onCancel={() => window.location.reload()}
            submitted={submitted}
            mode='create'
          />
          <Dialog
            open={missingTranslationsWarning}
            handleClose={() => setMissingTranslationsWarning(false)}
            handleOk={createPoll}
            title={t('poll_missing_translation_title')}
            description={t('poll_missing_translation_content')}
            okText={t('save_without_translation')}
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreatePoll)
