import apisauce from 'apisauce'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/v2/',
  Stage: 'https://treapp.stage.geniem.io/v2/',
  Production: 'https://treapp.production.geniem.io/v2/'
}

// const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

// Select API URL to use
let apiUrl = API_URLS.Production

if (process.env.NODE_ENV === 'development') {
  apiUrl = API_URLS.Local
}

if (window.location.hostname.includes('.stage.geniem.io')) {
  apiUrl = API_URLS.Stage
}

const create = () => {
  const api = apisauce.create({
    baseURL: apiUrl,
    headers: {},
    timeout: 20000,
    withCredentials: true
  })

  return {
    // Auth
    login: (data) => api.post('auth/login', data),
    logout: () => api.put('auth/logout'),
    logoutOthers: () => api.put('auth/logout-others'),
    requestPasswordReset: (data) => api.post('auth/password-reset', data),
    getPasswordReset: (code) => api.get(`auth/password-reset/${code}`),
    resetPassword: (data) => api.put('auth/password-reset', data),
    inviteUser: (data) => api.post('auth/email-invite', data),
    verifyUserInvite: (data) => api.put('auth/email-invite', data),
    createEmailChange: (data) => api.post('auth/email-change', data),
    getEmailChange: (code) => api.get(`auth/email-change/${code}`),
    verifyEmailChange: (data) => api.put('auth/email-change', data),
    getUserInvite: (code) => api.get(`auth/email-invite/${code}`),
    getUserInvites: () => api.get('auth/invited'),
    keepAuthAlive: () => api.put('auth/keep-alive'),

    // User
    getMe: () => api.get('user/me'),
    updateMe: (data) => api.put('user/me', data),
    updateUser: (id, data) => api.put(`user/${id}`, data),
    changePassword: (data) => api.put('user/me/password', data),
    getMyOrganizationUsers: () => api.get('user'),
    getMyOrganizationUser: (id) => api.get(`user/${id}`),
    createUser: (data) => api.post('user', data),
    deactivateUser: (id: number) => api.delete(`user/${id}`),
    getUser: (id) => api.get(`user/${id}`),

    // Organization
    getMyOrganization: () => api.get('organization/my'),
    updateMyOrganization: (data) => api.put('organization/my', data),
    getOrganizations: () => api.get('organization'),
    getOrganization: (id: number) => api.get(`organization/${id}`),
    createOrganization: (data) => api.post('organization', data),
    updateOrganization: (id: number, data) => api.put(`organization/${id}`, data),

    // Jana main entities
    getMainEntities: () => api.get(`coupon/jana-main-entity`, {
      mainEntityTypes: [
        'music_event',
        'sport_event',
        'theme_event'
      ],
      mainEntityFields: [
        'event_start_date',
        'event_end_date'
      ]
    }),

    // Coupons
    getCouponNotifications: () => api.get('notification/coupon'),
    getCoupons: () => api.get('coupon'),
    getCoupon: (couponId) => api.get(`coupon/${couponId}`),
    getCouponAnalytics: (couponId) => api.get(`coupon/analytics/${couponId}`),
    getCouponCategory: (id) => api.get(`coupon/category/${id}`),
    getCouponOrganization: (id) => api.get(`coupon/organization/${id}`),
    createCoupon: (coupon) => api.post('coupon', coupon),
    updateCoupon: (couponId, coupon) => api.put(`coupon/${couponId}`, coupon),
    updateCouponCategory: (id, category) => api.put(`coupon/category/${id}`, category),
    updateCouponOrganization: (id, organization) => api.put(`coupon/organization/${id}`, organization),
    getCouponOrganizations: () => api.get('coupon/organizations'),
    getCouponCategories: () => api.get('coupon/categories'),
    createCouponOrganization: (data) => api.post('coupon/organization', data),
    createCouponCategory: (data) => api.post('coupon/category', data),
    getDatahubOrganizations: () => api.get('coupon/datahub/organization'),
    exportOrganizationCouponUsesCsv: (organizationIds, filename = 'export.xlsx') => window.open(`${apiUrl}coupon/export/organizations/${filename}?ids=${organizationIds.join(',')}`),
    exportCouponUsesCsv: (couponId, filename = 'export.xlsx') => window.open(`${apiUrl}coupon/export/coupon/${couponId}/${filename}`),
    massEditOrganizations: (organizationIds, fields) => api.put('coupon/organizations/mass-edit', { organizationIds, fields }),

    // Coupon campaigns
    getCouponCampaigns: () => api.get('coupon-campaign'),
    getCouponCampaign: (couponCampaignId) => api.get(`coupon-campaign/${couponCampaignId}`),
    createCouponCampaign: (couponCampaign) => api.post('coupon-campaign', couponCampaign),
    updateCouponCampaign: (id, couponCampaign) => api.put(`coupon-campaign/${id}`, couponCampaign),
    generateQrCodeDownloadLink: (id) => `${apiUrl}coupon-campaign/${id}/qr-codes-png-zip`,
    getCouponCampaignAnalytics: (couponCampaignId) => api.get(`coupon-campaign/${couponCampaignId}/analytics`),

    // Notifications
    getNotifications: () => api.get('notification'),
    getNotification: (id) => api.get(`notification/${id}`),
    createNotification: (notification) => api.post('notification', notification),
    updateNotification: (id, notification) => api.put(`notification/${id}`, notification),

    // Influence / feedbacks
    getCityFeedbacks: () => api.get('influence/city-feedback'),
    getCityFeedback: (id) => api.get(`influence/city-feedback/${id}`),
    getFaultReports: () => api.get('influence/fault-report'),
    getFaultReport: (id) => api.get(`influence/fault-report/${id}`),

    // Polls
    getPolls: () => api.get('poll/all'),
    getPollAnswers: (id) => api.get(`poll/answers/${id}`),
    getPoll: (id) => api.get(`poll/${id}`),
    getPollFull: (id) => api.get(`poll/full/${id}`),
    updatePoll: (id, poll) => api.put(`poll/${id}`, poll),
    createPoll: (poll) => api.post('poll', poll),
    getPollExport: (id, filename = 'export.xlsx') => window.open(`${apiUrl}poll/export/${id}/${filename}`),
    getPollViewsAnalytics: (pollId) => api.get(`analytics2/view-poll/${pollId}`),
    getPollCategories: () => api.get('poll/category'),
    getPollCategory: (id) => api.get(`poll/category/${id}`),
    createPollCategory: (pollCategory) => api.post('poll/category', pollCategory),
    updatePollCategory: (id, pollCategory) => api.put(`poll/category/${id}`, pollCategory),
    deletePollCategory: (id) => api.delete(`poll/category/${id}`),

      // Tests
      getTests: () => api.get('test/all'),
      getTestAnswers: (id) => api.get(`test/answers/${id}`),
      getTest: (id) => api.get(`test/${id}`),
      getTestFull: (id) => api.get(`test/full/${id}`),
      updateTest: (id, test) => api.put(`test/${id}`, test),
      createTest: (test) => api.post('test', test),
      getTestExport: (id, filename = 'export.xlsx') => window.open(`${apiUrl}test/export/${id}/${filename}`),
      getTestViewsAnalytics: (testId) => api.get(`analytics2/view-test/${testId}`),
      getTestCategories: () => api.get('test/category'),
      getTestCategory: (id) => api.get(`test/category/${id}`),
      createTestCategory: (testCategory) => api.post('test/category', testCategory),
      updateTestCategory: (id, testCategory) => api.put(`test/category/${id}`, testCategory),
      deleteTestCategory: (id) => api.delete(`test/category/${id}`),
      getTestAnswerResults: (id, deviceId) => api.get(`test/${id}/results?deviceId=${deviceId}`),
      deleteUserAnswers: (id, deviceId) => api.delete(`test/${id}/answers?deviceId=${deviceId}`),

    // Analytics
    getAnalytics: () => api.get('analytics'),
    getInvolvementAnalytics: () => api.get('analytics/involvement'),
    getFeedbackSumsAnalytics: (startDate, endDate) => api.get('analytics/feedback-sum', { startDate, endDate }),
    getDeviceYearMonthDistributions: () => api.get('device/year-month-distribution'),

    // Analytics v2
    getActiveUsersAnalytics: (startDate, endDate) => api.get('analytics2/active-users', { startDate, endDate }),
    getUsageSummaryAnalytics: (startDate, endDate) => api.get('analytics2/usage-summary', { startDate, endDate }),
    getFullDimensionalScreenViewsAnalytics: (startDate, endDate) => api.get('analytics2/full-dimensional-screen-views', { startDate, endDate }),
    getDemographics28d: (date) => api.get('analytics2/demographics', { date }),
    getCustomEvents: (startDate, endDate) => api.get('analytics2/custom-events', { startDate, endDate }),
    getCouponViewsAnalytics: (couponId) => api.get(`analytics2/view-coupon/${couponId}`),
    getCouponCampaignLotteryLinkOpenedCountAnalytics: (couponCampaignId) => api.get(`analytics2/coupon-campaign-lottery-link-opened/${couponCampaignId}`),
    getBundledNotificationViews: (notificationIds) => api.post('analytics2/view-notification/bundle', notificationIds),

    // Logs
    getMyLoginLog: (params = {}) => api.get('log/my/login', params),

    getMediaUrl: (id) => `${apiUrl}media/${id}`,

    getVideoEmbed: (url) => api.get('media/video-embed', { url }),

    uploadMedia: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post('media/upload', formData, options)
    }
  }
}

const Api = create()

export default Api
