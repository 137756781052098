import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Input, Button, RadioSelect } from '../../Components';
import { Colors, Fonts } from '../../Utils/theme';
import { Test } from '../../Models/TestStore';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  inlineRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontFamily: Fonts.heading,
    fontWeight: 400,
    fontSize: '1.5rem',
    padding: 0,
    margin: 0,
    marginBottom: '1rem',
  },
  boxContainer: {
    border: `1px solid ${Colors.lightBorder}`,
    padding: '.75rem 1.5rem 0',
    marginBottom: '1rem',
    borderRadius: '.1875rem',
  },
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row',
  },
  dateTimeLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    paddingLeft: '2rem',
    paddingRight: '.25rem',
    fontSize: '1.125rem',
    minWidth: '4.85rem',
    fontWeight: 300,
  },
  dateTimeInput: {
    flex: 1,
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem',
  },
  footerSpacer: {
    width: '2rem',
  },
  deleteButton: {
    fontSize: '1rem',
    padding: '.5rem 0',
    color: Colors.red,
    cursor: 'pointer',
    marginBottom: '1.25rem',
  },
  activateButton: {
    fontSize: '1rem',
    padding: '.5rem 0',
    color: Colors.brandColor2,
    cursor: 'pointer',
    marginBottom: '1.25rem',
  },
}));

type TestActionsProps = {
  test: Test;
  onChange: (test: Test) => void;
  onSave: () => void;
  onCancel: () => void;
  toggleActive?: () => void;
};

const TestActions = ({
  test,
  onChange,
  onSave,
  onCancel,
  toggleActive,
}: TestActionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleSetStartDate = (value) => {
    onChange({
      ...test,
      validFrom: value,
    });
  };

  const handleSetStartTime = (value) => {
    const [hours, minutes] = value.split(':');
    const current = test.validFrom ? new Date(test.validFrom) : new Date();
    current.setHours(hours, minutes);
    onChange({
      ...test,
      validFrom: current,
    });
  };

  const handleSetEndDate = (value) => {
    onChange({
      ...test,
      validUntil: value,
    });
  };

  const handleSetEndTime = (value) => {
    const [hours, minutes] = value.split(':');
    const current = test.validUntil ? new Date(test.validUntil) : new Date();
    current.setHours(hours, minutes);
    onChange({
      ...test,
      validUntil: current,
    });
  };

  const handleSetStartType = (value) => {
    if (value === 'draft') {
      onChange({
        ...test,
        draft: true,
        validFrom: null,
      });
      return;
    }
    if (value === 'now') {
      onChange({
        ...test,
        draft: false,
        validFrom: null,
      });
      return;
    }

    onChange({
      ...test,
      draft: false,
      validFrom: new Date(),
    });
  };

  const handleSetEndType = (value) => {
    if (value === 'time') {
      const now = new Date();
      now.setFullYear(now.getFullYear() + 1);
      const validForYear = new Date(now);
      onChange({
        ...test,
        validUntil: validForYear,
      });
      return;
    }
    onChange({
      ...test,
      validUntil: null,
    });
  };

  // TODO: implementation
  const validationMessage = (field) => {
    // if (props.submitted && !field) {
    //   return {
    //     error: true,
    //     helperText: t('required_field')
    //   }
    // }
    return {};
  };

  const getStartTypeOptions = () => {
    if (!test.id) {
      return [
        {
          value: 'now',
          label: t('poll_publish_immediately'),
        },
        {
          value: 'draft',
          label: t('poll_save_as_draft'),
        },
        {
          value: 'time',
          label: t('at_the_selected_time'),
        },
      ];
    }

    return [
      {
        value: 'draft',
        label: t('poll_save_as_draft'),
      },
      {
        value: 'time',
        label: t('at_the_selected_time'),
      },
    ];
  };

  const getStartTypeValue = () => {
    if (test.draft) return 'draft';
    if (!test.validFrom && !test.draft) return 'now';
    return 'time';
  };

  const getEndTypeOptions = () => [
    {
      value: 'valid_until_further_notice',
      label: t('valid_until_further_notice'),
    },
    {
      value: 'time',
      label: t('ends_on_time'),
    },
  ];

  const getEndTypeValue = () => {
    if (!test.validUntil) return 'valid_until_further_notice';
    return 'time';
  };

  const getDateTimeValue = (d: Date | string | null, format: string) => {
    return moment(d || new Date()).format(format);
  };

  const renderStartInput = () => {
    if (getStartTypeValue() === 'time') {
      return (
        <>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>{t('date_short')}</div>
            <div className={classes.dateTimeInput}>
              <Input
                value={getDateTimeValue(test.validFrom, 'YYYY-MM-DD')}
                onChange={handleSetStartDate}
                type="date"
                {...validationMessage(test.validFrom)}
              />
            </div>
          </div>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>{t('time_short')}</div>
            <div className={classes.dateTimeInput}>
              <Input
                value={getDateTimeValue(test.validFrom, 'HH:mm')}
                onChange={handleSetStartTime}
                type="time"
                {...validationMessage(test.validFrom)}
              />
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const renderEndInput = () => {
    if (getEndTypeValue() === 'time') {
      return (
        <>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>{t('date_short')}</div>
            <div className={classes.dateTimeInput}>
              <Input
                value={getDateTimeValue(test.validUntil, 'YYYY-MM-DD')}
                onChange={handleSetEndDate}
                type="date"
                {...validationMessage(test.validUntil)}
              />
            </div>
          </div>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>{t('time_short')}</div>
            <div className={classes.dateTimeInput}>
              <Input
                value={getDateTimeValue(test.validUntil, 'HH:mm')}
                onChange={handleSetEndTime}
                type="time"
                {...validationMessage(test.validUntil)}
              />
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <div className={classes.inlineRow}>
        <h3 className={classes.title}>{t('poll_starts_at')}</h3>
        <div className={classes.flex} />
        {toggleActive ? (
          <button
            type="button"
            className={classes.deleteButton}
            onClick={toggleActive}
          >
            {test.active ? t('delete_poll') : t('activate_poll')}
          </button>
        ) : null}
      </div>

      <div className={classes.boxContainer}>
        <RadioSelect
          value={getStartTypeValue()}
          options={getStartTypeOptions()}
          onChange={handleSetStartType}
        />
        {renderStartInput()}
      </div>
      <h3 className={classes.title}>{t('poll_ends_at')}</h3>
      <div className={classes.boxContainer}>
        <RadioSelect
          value={getEndTypeValue()}
          options={getEndTypeOptions()}
          onChange={handleSetEndType}
        />
        {renderEndInput()}
      </div>

      <div className={classes.footerContainer}>
        <Button text={t('save')} onClick={onSave} margin fullWidth />
        <div className={classes.footerSpacer} />
        <Button
          text={t('cancel')}
          onClick={onCancel}
          margin
          secondary
          variant
        />
      </div>
    </div>
  );
};

export default TestActions;
