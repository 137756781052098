import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '3rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 2rem',
    backgroundColor: Colors.pageHeaderBackgroundColor,
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}))

export default function Header(props) {
  const classes = useStyles()

  return (
    <div>
      <AppBar position='static' className={classes.appBar}>
        <Toolbar disableGutters />
      </AppBar>
    </div>
  )
}
