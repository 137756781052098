import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { useLocation, withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ButtonBase from '@material-ui/core/ButtonBase'
import logo from '../../Assets/Images/app_logo.png'
import CouponLogo from '../../Assets/Images/coupon_logo.png'
import logoutIcon from '../../Assets/Icons/logout.svg'
import ChevronRightIcon from '../../Assets/Icons/chevron-right.svg'
import ChevronLeftIcon from '../../Assets/Icons/chevron-left.svg'
import * as get from 'lodash/get'
import { Colors, Fonts } from '../../Utils/theme'
import { Checkbox } from '../../Components'

const useStyles = makeStyles((theme) => ({
  drawer: {
    minHeight: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    zIndex: 103
  },
  drawerOpen: {
    position: 'relative',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    minHeight: '100vh',
    width: '16.75rem',
    backgroundColor: Colors.sidebarBackgroundColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerClose: {
    minHeight: '100vh',
    overflowY: 'hidden',
    width: '5rem',
    backgroundColor: Colors.sidebarBackgroundColor
  },
  flex: {
    flex: 1
  },
  logo: {
    marginTop: '1.75rem',
    marginBottom: '1.75rem',
    alignSelf: 'center',
    maxWidth: 'auto',
    height: '2.5rem'
  },
  logoHidden: {
    marginTop: '1.75rem',
    marginBottom: '1.75rem',
    alignSelf: 'center',
    maxWidth: '60%',
    height: 'auto',
    opacity: 0
  },
  openSpacer: {
    height: '1rem',
    '@media (max-height:780px)': {
      height: '0rem'
    }
  },
  closedSpacer: {
    height: '2.75rem',
    '@media (max-height:780px)': {
      height: '0rem'
    }
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  listItem: {
    position: 'relative',
    height: '5rem',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '@media (max-height:780px)': {
      height: '4rem'
    },
    '@media (max-height:600px)': {
      height: '3.25rem'
    }
  },
  link: {
    width: '100%',
    height: '4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1.375rem',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      '& > div > span': {
        color: `${Colors.white} !important`
      }
    }
  },
  linkClosed: {
    width: '100%',
    height: '4rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  icon: {
    width: '1.5rem',
    minWidth: 1,
    '@media (max-height:780px)': {
      height: '1.375rem'
    }
  },
  itemTextRoot: {
    margin: 0
  },
  itemText: {
    fontFamily: Fonts.heading,
    fontWeight: 700,
    color: Colors.white80,
    fontSize: '1rem',
    marginLeft: '2.75rem'
  },
  logoutButton: {
    marginBottom: '1rem',
    alignSelf: 'center',
    backgroundColor: Colors.sidebarButton,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '3.5rem',
    width: '85%',
    borderRadius: '0.3125rem',
    alignItems: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: Colors.sidebarButtonHover
    },
    '@media (max-height:780px)': {
      height: '3.25rem'
    }
  },
  logoutText: {
    margin: 0,
    marginLeft: '.5rem',
    fontFamily: Fonts.heading,
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.white
  },
  logoutIcon: {
    maxHeight: '2rem',
    maxWidth: '2rem',
    '@media (max-height:780px)': {
      maxHeight: '1.75rem',
      maxWidth: '1.75rem'
    }
  },
  chevronContainer: {
    position: 'fixed',
    left: '3.95rem',
    zIndex: 1300,
    animation: 'fadeIn linear .5s'
  },
  chevronContainerOpen: {
    position: 'fixed',
    left: '15.75rem',
    zIndex: 1300,
    animation: 'fadeIn linear 1s'
  },
  chevron: {
    background: Colors.white,
    height: '2rem',
    width: '2rem',
    borderRadius: '10rem',
    padding: '.5rem',
    border: `.25rem solid ${Colors.sidebarBackgroundColor}`
  },
  chevronLeft: {
    background: Colors.white,
    height: '2rem',
    width: '2rem',
    borderRadius: '10rem',
    padding: '.5rem',
    border: `.25rem solid ${Colors.sidebarBackgroundColor}`
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.black50,
    zIndex: 102
  },
  itemIcon: {
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '100%',
    width: '100%'
  },
  itemIconOpen: {
    marginLeft: '1.25rem',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
    height: '100%',
    width: '100%'
  },
  activeBadge: {
    display: 'block',
    backgroundColor: Colors.white,
    position: 'absolute',
    top: '28%',
    bottom: '28%',
    borderRadius: '10rem',
    left: '-.27rem',
    width: '.5rem'
  },
  tooltip: {
    position: 'relative',
    zIndex: 99999
  },
  adminCheckboxContainer: {
    position: 'fixed',
    top: 0,
    left: '.5rem'
  }
}))

function Sidebar(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const currentLocation = useLocation()
  const open = props.open

  const handleListItemClick = () => {
    if (open) {
      props.toggleDrawer()
    }
  }

  const renderLogo = () => {
    if (
      props.history &&
      (
        get(window, 'location.pathname', '').includes('coupon') ||
        get(window, 'location.pathname', '').includes('campaign')
      )
    ) {
      return <img src={CouponLogo} alt='logo' className={open ? classes.logo : classes.logoHidden} />
    }
    return <img src={logo} alt='logo' className={open ? classes.logo : classes.logoHidden} />
  }

  const renderSpacer = () => {
    if (open) {
      return <div className={classes.openSpacer} />
    }
    return <div className={classes.closedSpacer} />
  }

  const renderItemText = (text) => {
    if (!open) return null
    return (
      <ListItemText
        primary={t(text)}
        classes={{ root: classes.itemTextRoot, primary: classes.itemText }}
      />
    )
  }

  const isActivePath = (path, item) => {
    if (path.includes('coupon')) {
      if (path.includes('coupon-notifications/') && item.url.includes('coupon-notifications')) return true
      if (path.includes('coupons/organization/') && item.url.includes('coupon-organizations')) return true
      if (path.includes('coupons/add-organization') && item.url.includes('coupon-organizations')) return true
      if (
        !item.url.includes('organization') &&
        !path.includes('organization') &&
        !item.url.includes('notification') &&
        !path.includes('notification') &&
        !item.url.includes('categor') &&
        !path.includes('categor') &&
        !item.url.includes('add') &&
        !path.includes('add') &&
        item.url.includes('coupon')
      ) {
        return true
      }
      return path === item.url
    }

    return !!(path || '').substr(0, 6).includes(item.url.substr(0, 6))
  }

  const renderItems = () => {
    return props.navigationItems.map((item) => {
      const isActive = isActivePath(currentLocation.pathname || '', item)

      if (item.action === 'toggle_coupon') {
        return (
          <div className={classes.adminCheckboxContainer}>
            <Checkbox
              checked={props.sidebarCouponMode}
              onChange={props.toggleCouponMode}
            />
          </div>
        )
      }
      return (
        <Tooltip title={t(item.text)} placement='right' className={classes.tooltip}>
          <ListItem
            key={item.url}
            onClick={handleListItemClick}
            classes={{ root: classes.listItem }}
            button
            disableGutters
          >
            <Link to={item.url} className={open ? classes.link : classes.linkClosed}>
              <ListItemIcon classes={{ root: classes.icon }}>
                <img
                  src={item.icon}
                  className={open ? classes.itemIconOpen : classes.itemIcon}
                  alt='icon'
                />
              </ListItemIcon>
              {renderItemText(item.text)}
            </Link>
            {isActive && <div className={classes.activeBadge} />}
          </ListItem>
        </Tooltip>
      )
    })
  }

  const renderLogoutButtonContent = () => {
    if (open) {
      return <p className={classes.logoutText}>{t('logout')}</p>
    }
    return null
  }

  const renderChevron = () => {
    if (!open) {
      return (
        <div className={classes.chevronContainer}>
          <img src={ChevronRightIcon} className={classes.chevron} alt='right arrow' />
        </div>
      )
    }
    return (
      <div className={classes.chevronContainerOpen}>
        <img src={ChevronLeftIcon} className={classes.chevronLeft} alt='left arrow' />
      </div>
    )
  }

  return (
    <>
      {open && <div className={classes.backdrop} onClick={props.toggleDrawer} />}
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })
        }}
        open={open}
      >
        <ButtonBase onClick={props.toggleDrawer}>
          {renderLogo()}
          {renderChevron()}
        </ButtonBase>
        {renderSpacer()}
        <List disablePadding classes={{ root: classes.listRoot }}>{renderItems()}</List>
        <div className={classes.flex} />
        <Tooltip title={t('logout')} placement='right' className={classes.tooltip}>
          <ButtonBase className={classes.logoutButton} onClick={props.logout}>
            {!open && <img src={logoutIcon} alt='icon' className={classes.logoutIcon} />}
            {renderLogoutButtonContent()}
          </ButtonBase>
        </Tooltip>
      </Drawer>
    </>
  )
}


export default withRouter(Sidebar)
