import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import FeedbackTableRow from './FeedbackTableRow'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  }
}))

function FeedbackTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.spacer} />
          <FeedbackTableRow item={item} type={props.type} />
        </React.Fragment>
      ))
    }
    return null
  }

  const getHeaderColumnNames = () => {
    if (props.type === 'city_feedback') return [t('title'), t('attachment'), t('email'), t('sent_at')]
    return [t('category'), t('attachment'), t('email'), t('sent_at')]
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={getHeaderColumnNames()} />
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_items')} />
    </>
  )
}

export default FeedbackTable
