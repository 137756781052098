import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import PageHeader from '../../../Components/Common/PageHeader'
import { useStore } from '../../../Models/RootStore'
import { Title, Input, Select, Button, PageActions, PageContent, PageContainer, AccessRightsSelector } from '../../../Components'
import { ROLES } from '../../../Constants'
import { Colors, Fonts } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  }
}))

function InviteUser(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [accessRights, setAccessRights] = useState([])

  const getRoles = () => ROLES.map((role, index) => ({ id: index + 1, label: t(role), value: role }))

  const { userStore, appStore } = useStore()
  const inviteUser = () => {
    setSubmitted(true)
    userStore.inviteUser({
      email,
      role,
      accessRights: role === 'admin' ? [] : accessRights,
    })
  }

  useEffect(() => {
    if (appStore.notification && appStore.notification.type === 'success') {
      // Clear fields after success
      setEmail('')
      setRole('')
      setSubmitted(false)
      setAccessRights([])
    }
  }, [appStore.notificationUpdatedAt])

  const emailValidation = () => {
    if (submitted && !/\S+@\S+\.\S+/.test(email)) {
      return {
        error: true,
        helperText: t('invalid_email')
      }
    }
    return {}
  }

  const roleValidation = () => {
    if (submitted && !role) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
  }

  return (
    <>
      <PageHeader
        title={t('add_new_user')}
        backButtonLink='/users'
      />
      <PageContainer>
        <PageContent>
          <Title title={t('user_details')} description={t('create_user_description')} />
          <Input label={t('email')} value={email} onChange={setEmail} {...emailValidation()} />
          <Title title={t('account_details')} description={t('create_user_account_description')} type='subtitle' spacer={10} />
          <Select options={getRoles()} label={t('role')} value={role} onChange={setRole} {...roleValidation()} />
          {(!role || role === 'admin') ? null : (
            <AccessRightsSelector
              accessRights={accessRights}
              setAccessRights={setAccessRights}
            />
          )}
        </PageContent>
        <PageActions>
          <p className={classes.text}>{t('create_user_action_description')}</p>
          <Button text={t('send_invite')} onClick={inviteUser} margin />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(InviteUser)
