import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import ReactSelect from 'react-select'

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '1rem'
  },
  select: {
    fontFamily: Fonts.content,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.5rem',
    zIndex: 99,
    backgroundColor: Colors.inputGrey
  },
  selectTop: {
    fontFamily: Fonts.content,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.5rem',
    zIndex: 101,
    backgroundColor: Colors.inputGrey
  },
  label: {
    fontSize: '1.125rem'
  }
}))

export default function AutocompleteSelect(props) {
  const classes = useStyles()

  const getCurrentValue = () => props.options.find(({ value }) => value === props.value)
  const handleOnChange = (item) => props.onChange(item.value)

  const getStyles = () => {
    return {
      input: styles => ({
        ...styles,
        paddingTop: '.75rem',
        paddingBottom: '.75rem',
        fontSize: '1.125rem',
        fontWeight: 300
      }),
      control: () => ({
        backgroundColor: Colors.inputGrey,
        display: 'flex',
        flexDirectiion: 'row',
        fontSize: '1.125rem',
        border: `1px solid ${Colors.lightBorder}`,
        paddingLeft: '.25rem',
        color: Colors.black,
        borderRadius: '.25rem',
        fontWeight: 300,
        ':hover': {
          border: `1px solid ${Colors.black}`
        },
        ':active': {
          border: `1px solid ${Colors.black}`,
          boxShadow: `inset 0 0 0 1px ${Colors.black}`
        }
      })
    }
  }

  return (
    <div className={classes.container}>
      {props.label && <div className={classes.label}>{props.label}</div>}
      <ReactSelect
        className={props.top ? classes.selectTop : classes.select}
        onChange={handleOnChange}
        placeholder={props.placeholder}
        value={getCurrentValue()}
        name={props.name || 'select'}
        options={props.options}
        styles={getStyles()}
        isSearchable
      />
    </div>
  )
}
