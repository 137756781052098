import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, PageContainer, PageContent, FeedbackInfo } from '../../../Components'

const FaultReport = () => {
  const { influenceStore } = useStore()
  const { faultReportId }: any = useParams()

  useEffect(() => {
    influenceStore.getFaultReport(faultReportId)
  }, [])

  const { t } = useTranslation()

  const renderContent = () => {
    if (influenceStore.faultReport) {
      return <FeedbackInfo item={influenceStore.faultReport} type='fault_report' />
    }
    return null
  }

  return (
    <>
      <PageHeader
        title={`${t('fault_report')} #${faultReportId}`}
        backButtonLink='/feedbacks'
      />
      <PageContainer>
        <PageContent size='medium'>
          {renderContent()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default observer(FaultReport)
