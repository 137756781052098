import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { omit } from 'lodash'
import { validateCoupon } from '../Utils/validation'
import moment from 'moment'

export default class CouponStore {
  rootStore
  coupons = []
  categories = []
  organizations = []
  coupon = null
  couponAnalytics = null
  category = null
  organization = null
  notifications = []
  datahubOrganizations = []
  loading = null
  createOk = false
  copyFields = null
  couponViews = null
  mainEntities = []

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setCopyFields = (couponFields) => {
    this.copyFields = {
      ...couponFields,
      copySetAt: Date.now()
    }
  }

  async getCoupons() {
    this.loading = true
    const response: any = await Api.getCoupons()
    this.loading = false
    if (response.ok) {
      this.coupons = response.data
    }
  }

  async getOrganizations() {
    this.loading = true
    const response: any = await Api.getCouponOrganizations()
    this.loading = false
    if (response.ok) {
      this.organizations = response.data
    }
  }

  async createCategory(data) {
    this.createOk = false
    this.loading = true
    const response: any = await Api.createCouponCategory(data)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'category_created')
    this.getCategories()
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async createOrganization(organization) {
    this.createOk = false
    this.loading = true

    const organizationData = omit(organization, ['newPhoto', 'removePhoto', 'rawPhoto'])

    // Upload photo
    if (organization.newPhoto && !organization.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(organization.newPhoto)
      if (photoResponse.ok) {
        organizationData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (organization.removePhoto) {
      // Remove photo
      organizationData.photo = null
    }

    if (organization.rawPhoto) {
      organizationData.photo = organization.rawPhoto
    }

    const response: any = await Api.createCouponOrganization(organizationData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'organization_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async getOrganization(id) {
    this.loading = true
    const response: any = await Api.getCouponOrganization(id)
    this.loading = false
    if (response.ok) {
      this.organization = response.data
    }
  }

  async getCategories() {
    this.loading = true
    const response: any = await Api.getCouponCategories()
    this.loading = false
    if (response.ok) {
      this.categories = response.data
    }
  }

  async getCategory(id) {
    this.loading = true
    const response: any = await Api.getCouponCategory(id)
    this.loading = false
    if (response.ok) {
      this.category = response.data
    }
  }

  async getCoupon(id) {
    this.loading = true
    const response: any = await Api.getCoupon(id)
    this.loading = false
    if (response.ok) {
      this.coupon = response.data
    }
  }

  async getCouponAnalytics(id) {
    this.loading = true
    const response: any = await Api.getCouponAnalytics(id)
    this.loading = false
    if (response.ok) {
      this.couponAnalytics = response.data
    }
  }

  async createCoupon(coupon) {
    this.createOk = false

    const validationError = validateCoupon(coupon)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    const couponData = omit(coupon, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (coupon.newPhoto && !coupon.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(coupon.newPhoto)
      if (photoResponse.ok) {
        couponData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (coupon.removePhoto) {
      // Remove photo
      couponData.photo = null
    }

    this.loading = true
    const response: any = await Api.createCoupon(couponData)
    this.loading = false
    if (response.ok) {
      this.coupon = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'coupon_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async updateCoupon(id, coupon) {
    this.loading = true

    // Validation
    if (Object.keys(coupon).length > 1 &&!coupon.categoryId) {
      const validationError = validateCoupon(coupon)
      if (validationError) {
        this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
        return
      }
    }

    const couponData = omit(coupon, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (coupon.newPhoto && !coupon.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(coupon.newPhoto)
      if (photoResponse.ok) {
        couponData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (coupon.removePhoto) {
      // Remove photo
      couponData.photo = null
    }

    const response: any = await Api.updateCoupon(id, couponData)
    this.loading = false
    if (response.ok) {
      this.coupon = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async deleteCategory(id) {
    this.loading = true
    const response: any = await Api.updateCouponCategory(id, { active: false })
    this.loading = false
    if (response.ok) {
      this.categories = this.categories.filter(cat => {
        return cat.id !== id
      })
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'category_deleted')
  }

  async updateCategory(id, category) {
    this.loading = true
    const response: any = await Api.updateCouponCategory(id, category)
    this.loading = false
    if (response.ok) {
      this.category = response.data
      this.categories = this.categories.map(cat => {
        if (cat.id === id) return response.data
        return cat
      })
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async updateOrganization(id, organization) {
    this.loading = true

    const organizationData = omit(organization, ['newPhoto', 'removePhoto', 'rawPhoto'])

    // Upload photo
    if (organization.newPhoto && !organization.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(organization.newPhoto)
      if (photoResponse.ok) {
        organizationData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (organization.removePhoto) {
      // Remove photo
      organizationData.photo = null
    }

    if (organization.rawPhoto) {
      organizationData.photo = organization.rawPhoto
    }

    const response: any = await Api.updateCouponOrganization(id, organizationData)
    this.loading = false
    if (response.ok) {
      this.organization = response.data
      this.getCoupons()
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
  }

  async getNotifications() {
    this.loading = true
    const response: any = await Api.getCouponNotifications()
    this.loading = false
    if (response.ok) {
      this.notifications = response.data
    }
  }

  async getCouponViews(couponId) {
    this.couponViews = null
    this.loading = true
    const response: any = await Api.getCouponViewsAnalytics(couponId)
    this.loading = false
    if (response.ok) {
      this.couponViews = response.data.data.couponViews.count
    }
  }

  async getMainEntities() {
    this.loading = true
    const response: any = await Api.getMainEntities()
    this.loading = false
    if (response.ok) {
      this.mainEntities = response.data
    }
  }

  async getDatahubOrganizations() {
    this.loading = true
    const response: any = await Api.getDatahubOrganizations()
    this.loading = false
    if (response.ok) {
      this.datahubOrganizations = response.data
    }
  }

  async exportCouponUseCsv(couponId) {
    const filename = `coupon-use-export-${moment().format('YYYY-MM-DD[T]HH-mm-ss')}.xlsx`
    Api.exportCouponUsesCsv(couponId, filename)
  }

  async exportOrganizationCouponUseCsv(organizationIds) {
    const filename = `coupon-uses-export-${moment().format('YYYY-MM-DD[T]HH-mm-ss')}.xlsx`
    Api.exportOrganizationCouponUsesCsv(organizationIds, filename)
  }

  async massEditOrganizations(organizationIds, fields) {
    this.loading = true
    const response: any = await Api.massEditOrganizations(organizationIds, fields)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.organizations = response.data
    }
  }
}
