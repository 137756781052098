import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import Sidebar from './Sidebar'
import Header from './Header'
import UINotification from '../../Components/Common/UINotification'
import { useStore } from '../../Models/RootStore'
import { AvailableRoutes } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  }
}))

function AppLayout(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => setOpen(!open)

  const { appStore, sessionStore } = useStore()
  const { notification, clearNotification } = appStore

  const logout = () => sessionStore.logout()

  const toggleCouponMode = () => appStore.toggleSidebarCouponMode()

  return (
    <div className={classes.root}>
      <Sidebar
        open={open}
        toggleDrawer={toggleDrawer}
        logout={logout}
        navigationItems={AvailableRoutes(get(sessionStore, 'user', null), appStore.sidebarCouponMode)}
        toggleCouponMode={toggleCouponMode}
        sidebarCouponMode={appStore.sidebarCouponMode}
      />
      <main className={classes.content} id='app-content'>
        <Header drawerOpen={open} />
        {props.children}
      </main>
      <UINotification notification={notification} clearNotification={clearNotification} />
    </div>
  )
}

export default observer(AppLayout)
