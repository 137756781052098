import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { useTranslation } from 'react-i18next'
import AnalyticsTableHeader from './AnalyticsTableHeader'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { APP_SCREEN_MAPPINGS } from '../../Constants'

const useStyles = makeStyles((theme) =>({
  container: {
    padding: '1rem',
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: '.3125rem'
  },
  title: {
    fontWeight: 600,
    color: Colors.boxTitle,
    fontSize: '1.5rem'
  },
  subTitle: {
    color: Colors.boxTitle,
    fontSize: '.875rem',
    marginBottom: '1rem'
  },
  root: {

  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  },
  cell: {

  },
  firstCell: {

  },
  diffPositive: {
    color: '#1A683B'
  },
  diffNegative: {
    color: '#EB5E57'
  },
  oddRow: {
    backgroundColor: Colors.inputGrey,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e1eaf1',
    },
  },
  evenRow: {
    '&:hover': {
      backgroundColor: '#e1eaf1',
    },
    cursor: 'pointer'
  },
  noCursor: {
    cursor: 'default'
  }
}))

function ScreenAnalyticsTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getItems = () => {
    if (!props.items) return []

    const flatGroups = []
    for (const group of APP_SCREEN_MAPPINGS) {
      for (const screen of group.screens) {
        flatGroups.push({
          group: group.group,
          groupName: group.groupName,
          screen: screen.screen,
          name: screen.screenName
        })
      }
    }

    const prevItems = getPreviousItems()
    return props.items.map(item => {
      const groupMatch = flatGroups.find(f => f.screen === item.screenName)
      if (!groupMatch) return null


      let diffPreviousSingle = 0
      // Find match
      try {

        const match = prevItems.find(prev => prev.screenName === item.screenName)
        if (match) diffPreviousSingle = (item.count / match.count) - 1
      } catch (err) {
        console.log(err)
      }


      return {
        ...item,
        ...groupMatch,
        screenAvgDuration: item.avgDuration,
        diffPreviousSingle: diffPreviousSingle
      }
    }).filter(val => !!val)
  }


  const getPreviousItems = () => {
    if (!props.previousItems) return []

    const flatGroups = []
    for (const group of APP_SCREEN_MAPPINGS) {
      for (const screen of group.screens) {
        flatGroups.push({
          group: group.group,
          groupName: group.groupName,
          screen: screen.screen,
          name: screen.screenName
        })
      }
    }

    return props.previousItems.map(item => {
      const groupMatch = flatGroups.find(f => f.screen === item.screenName)
      if (!groupMatch) return null
      return {
        ...item,
        ...groupMatch
      }
    }).filter(val => !!val)
  }

  const getGroupedItems = () => {
    const items = getItems()
    const previousItems = getPreviousItems()

    const groupedItems = APP_SCREEN_MAPPINGS.map(group => {

      const count = items.filter(i => i.group === group.group).reduce((partialSum, a) => partialSum + (a.count || 0), 0)
      const groupAvgDuration =  items.filter(i => i.group === group.group).reduce((partialSum, a) => partialSum + (((a.count * a.avgDuration) / count) || 0), 0)

      const previousCount = previousItems.filter(i => i.group === group.group).reduce((partialSum, a) => partialSum + (a.count || 0), 0)

      let diffPrevious = 0
      try {
        diffPrevious = (count / previousCount) - 1
      } catch (err) {
        console.log(err)
      }

      return {
        name: group.groupName,
        group: group.group,
        count,
        diffPrevious,
        avgDuration: groupAvgDuration
      }
    }).sort((a, b) => b.count - a.count)

    const countSum = groupedItems.reduce((partialSum, a) => partialSum + (a.count || 0), 0)
    return groupedItems.map(group => {
      return {
        ...group,
        countRatio: countSum ? (group.count / countSum) : 0
      }
    })
  }

  const getGroupItems = () => {
    const items = getItems()

    let results = items.filter(item => item.group === props.activeGroup).map(item => {

      // Find active users count
      let active28DayUsers = 0
      const match = props.activeUsers.find(i => i.screenName === item.screenName)
      if (match) {
        active28DayUsers = match ? (match.active28DayUsers || 0) : 0
      }

      return {
        ...item,
        active28DayUsers
      }
    })

    let specificScreenItemResults = []

    // Get view group specific items
    if (props.customEvents) {
      let analyticsGroups = []
      try {
        analyticsGroups = APP_SCREEN_MAPPINGS.find(item => item.group === props.activeGroup).analyticsGroups || []
      } catch (err) {
        console.log(err)
      }

      specificScreenItemResults = props.customEvents.filter(item => {
        // Include only relevant analytics group types for activeGroup
        return item.type && analyticsGroups.includes(item.type)
      }).map(item => {
        return {
          // Prefix by type
          name: `${t(`analytics_group_${item.type}`)}: ${item.title}`,
          count: item.count,
          active28DayUsers: item.active28DayUsers
        }
      })
    }

    return [
      ...results,
      ...specificScreenItemResults
    ]
  }

  const formatRatio = (ratio) => {
    if (!ratio) return null
    return `${Math.round(ratio * 10000) / 100} %`
  }

  const handleClick = (item) => {
    if (!props.activeGroup) {
      props.setActiveGroup(item.group)
    } else {
      props.setActiveGroup(null)
    }
  }

  const renderDiff = (diff) => {
    if (!diff || diff < -1) return ''
    if (diff > 0) {
      return (
        <span className={classes.diffPositive}>
          {`+${formatRatio(diff)}`}
        </span>
      )
    }
    // < 0
    return (
      <span className={classes.diffNegative}>
        {`${formatRatio(diff)}`}
      </span>
    )
  }

  const renderRow = (item, index) => {
    const avgDurationFormatted = get(item, 'avgDuration') ? `${Math.round(get(item, 'avgDuration', 0))}s` : ''
    return (
      <TableRow onClick={() => handleClick(item)} className={index % 2 ? classes.evenRow : classes.oddRow}>
        <TableCell className={[classes.cell, classes.firstCell].join(' ')}>
          <span>{`${item.name}`}</span>
        </TableCell>
        <TableCell className={classes.cell}>
          <span>{formatRatio(get(item, 'countRatio'))}</span>
        </TableCell>
        <TableCell className={classes.cell}>
          <span>{avgDurationFormatted}</span>
        </TableCell>
        <TableCell  className={[classes.cell].join(' ')}>
          <span>{get(item, 'count')}</span>
        </TableCell>
        <TableCell className={classes.cell}>
          <span>{renderDiff(get(item, 'diffPrevious'))}</span>
        </TableCell>
      </TableRow>
    )
  }

  // Render single screen items
  const renderGroupRow = (item, index) => {
    const screenAvgDurationFormatted = get(item, 'screenAvgDuration') ? `${Math.round(get(item, 'screenAvgDuration', 0))}s` : ''

    return (
      <TableRow onClick={() => null} className={[index % 2 ? classes.evenRow : classes.oddRow.includes, classes.noCursor].join(' ')}>
        <TableCell className={[classes.cell, classes.firstCell].join(' ')}>
          <span>{`${item.name}`}</span>
        </TableCell>
        <TableCell  className={[classes.cell].join(' ')}>
          <span>{get(item, 'count')}</span>
        </TableCell>
        <TableCell className={classes.cell}>
          <span>{renderDiff(get(item, 'diffPreviousSingle'))}</span>
        </TableCell>
        <TableCell className={classes.cell}>
          <span>{get(item, 'active28DayUsers')}</span>
        </TableCell>
        <TableCell className={classes.cell}>
          <span>{screenAvgDurationFormatted}</span>
        </TableCell>


      </TableRow>
    )
  }

  const renderRows = () => {
    const items = !props.activeGroup ? getGroupedItems() : getGroupItems()

    if (items) {
      return items.map((item, index) => (
        <React.Fragment key={item.id}>
          {!props.activeGroup ? renderRow(item, index) : renderGroupRow(item, index)}
        </React.Fragment>
      ))
    }
    return null
  }

  const getHeaderColumnNames = () => {
    if (!props.activeGroup) {
      return [
        t('content_section_name'),
        t('share_of_usage'),
        t('avg_view_time'),
        t('view_count'),
        t('diff_to_28d'),
      ]
    }
    return [
      t('content_section_name'),
      t('view_count'),
      t('diff_to_28d'),
      t('users'),
      t('avg_view_time')
    ]
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t('content_section_usage_shares')}</div>
      <div className={classes.subTitle}>{t('by_percentage_and_time')}</div>
      <Table classes={{ root: classes.root }}>
        <AnalyticsTableHeader
          columnNames={getHeaderColumnNames()}
        />
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </div>
  )
}

export default ScreenAnalyticsTable
