import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import UserCard from './UserCard'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  }
})

function UserGrid (props) {
  const classes = useStyles()

  const renderUsers = () => {
    return props.users.map((user) => <UserCard key={user.id} user={user} onClick={props.onClick} />)
  }

  return (
    <div className={classes.container}>
      {renderUsers()}
    </div>
  )
}

export default UserGrid
