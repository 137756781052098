import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class InfluenceStore {
  rootStore
  cityFeedbacks = []
  faultReports = []
  cityFeedback = null
  faultReport = null

  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getCityFeedbacks() {
    this.loading = true
    const response: any = await Api.getCityFeedbacks()
    this.loading = false
    if (response.ok) {
      this.cityFeedbacks = response.data
    }
  }

  async getCityFeedback(id) {
    this.loading = true
    const response: any = await Api.getCityFeedback(id)
    this.loading = false
    if (response.ok) {
      this.cityFeedback = response.data
    }
  }

  async getFaultReports() {
    this.loading = true
    const response: any = await Api.getFaultReports()
    this.loading = false
    if (response.ok) {
      this.faultReports = response.data
    }
  }

  async getFaultReport(id) {
    this.loading = true
    const response: any = await Api.getFaultReport(id)
    this.loading = false
    if (response.ok) {
      this.faultReport = response.data
    }
  }
}
