import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: '0.25rem'
  },
  rightIcon: {
    marginLeft: '0.25rem'
  },
  centerIcon: {
    margin: 0
  },
  baseButton: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '3.125rem',
    borderRadius: 5,
    alignItems: 'center',
    textTransform: 'none'
  },
  baseButtonText: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 700,
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
    whiteSpace: 'nowrap'
  },

  // Button variants
  defaultButton: {
    backgroundColor: Colors.primaryButtonColor,
    '&:hover': {
      backgroundColor: Colors.primaryButtonHoverColor
    },
  },
  defaultButtonText: {
    color: Colors.white
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.secondaryButtonColor}`,
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${Colors.secondaryButtonHoverColor}`,
      boxShadow: `inset 0 0 0 1px ${Colors.secondaryButtonHoverColor}`
    }
  },
  secondaryButtonText: {
    color: Colors.secondaryButtonColor,
    fontWeight: 400
  },
  excelVariantButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.excelGreen}`,
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${Colors.excelGreen}`,
      boxShadow: `inset 0 0 0 1px ${Colors.excelGreen}`
    }
  },
  excelVariantButtonText: {
    color: Colors.excelGreen,
    fontWeight: 400,
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem'
  },
  secondaryVariantButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.primaryButtonColor}`,
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${Colors.primaryButtonHoverColor}`,
      boxShadow: `inset 0 0 0 1px ${Colors.primaryButtonHoverColor}`
    }
  },
  secondaryVariantButtonText: {
    color: Colors.primaryButtonColor,
    fontWeight: 400,
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem'
  },
  highButton: {
    height: '3.75rem',
  },
  smallButton: {
    padding: '0.75rem',
    height: '2.25rem',
  },
  smallButtonText: {
    fontSize: '0.75rem',
  },
  disabledButton: {
    opacity: 0.4
  },
  marginButton: {
    margin: '1rem 0'
  },
  fullWidthButton: {
    width: '100%',
    boxSizing: 'border-box'
  },
  // Loading icon variants
  colorWhite: {
    color: 'white'
  },
  colorBlack: {
    color: 'black'
  }
}))

export default function BaseButton(props) {
  const classes = useStyles()

  const buttonClasses = [classes.baseButton]
  const buttonTextClasses = [classes.baseButtonText]
  let loadingClass = classes.colorWhite
  let loadingSize = 22

  // Add button base style
  if (props.secondary) {
    loadingClass = classes.colorBlack
    if (props.variant) {
      buttonClasses.push(classes.secondaryVariantButton)
      buttonTextClasses.push(classes.secondaryVariantButtonText)
    } else if (props.excelGreen) {
      buttonClasses.push(classes.excelVariantButton)
      buttonTextClasses.push(classes.excelVariantButtonText)
    } else {
      buttonClasses.push(classes.secondaryButton)
      buttonTextClasses.push(classes.secondaryButtonText)
    }
  } else {
    buttonClasses.push(classes.defaultButton)
    buttonTextClasses.push(classes.defaultButtonText)
  }

  if (props.high) {
    buttonClasses.push(classes.highButton)
  }

  // Add size
  if (props.small) {
    loadingSize = 18
    buttonClasses.push(classes.smallButton)
    buttonTextClasses.push(classes.smallButtonText)
  }

  // Add width
  if (props.fullWidth) {
    buttonClasses.push(classes.fullWidthButton)
  }

  // Add margin
  if (props.margin) {
    buttonClasses.push(classes.marginButton)
  }

  // Add additional classes
  if (props.buttonStyle) {
    buttonClasses.push(props.buttonStyle)
  }
  if (props.textStyle) {
    buttonTextClasses.push(props.textStyle)
  }

  const renderContent = () => {
    if (props.loading) return <CircularProgress className={loadingClass} size={loadingSize} />

    if (props.icon) return  <img src={props.icon} className={classes.centerIcon} alt='icon' />
    return (
      <>
        {props.leftIcon && <img src={props.leftIcon} className={classes.leftIcon} alt='icon' />}
        <p className={buttonTextClasses.join(' ')}>
          {props.text}
        </p>
        {props.rightIcon && <img src={props.rightIcon} className={classes.rightIcon} alt='icon' />}
      </>
    )
  }

  return (
    <Button
      className={buttonClasses.join(' ')}
      onClick={props.onClick}
      disabled={props.disabled}
      classes={{ disabled: classes.disabledButton }}
    >
      {renderContent()}
    </Button>
  )
}
