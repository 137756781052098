import { makeAutoObservable } from 'mobx'
import omit from 'lodash/omit'
import { Api } from '../Services'
import { validateTest } from '../Utils/validation'
import { RootStore } from './RootStore';

export interface Question {
  id: string;
  type:
    | "select"
    | "multiselect"
    | "text"
    | "year_select"
    | "postal_code"
    | "number_select";
  titleFi: string;
  titleEn: string;
  contentFi: string;
  contentEn: string;
  answersFi: string[];
  answersEn: string[];
  isOptional: boolean;
  otherEnabled: boolean;
  points?: Array<number | string>;
  inputRows?: null | number;
}

type UserType = 'citizen' | 'visitor' | 'business_attendee'

export interface Group {
  id: string
  titleFi?: string;
  titleEn?: string;
  contentFi?: string;
  contentEn?: string;
  questions: Question[];
}

export interface Test {
  id?: number;
  validFrom: Date | string | null;
  validUntil: Date | string | null;
  titleFi: string;
  titleEn: string;
  contentFi: string;
  contentEn: string;
  groups: Group[];
  userLang: string | null;
  userTypes: UserType[] | null;
  categoryId: number | null;
  photo: string | File | null;
  video: string | null;
  active: boolean;
  draft: boolean;
  creatorId?: number;
  updaterId?: number;
  notificationSentAt: string | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  totalAnswers?: number
  isOpen?: boolean
  newPhoto?: File
}

export interface Answer {
  id: number;
  deviceId: string;
  testId: number;
  questionId: string | null;
  answerIndex: number | null;
  textAnswer: string | null;
  createdAt: string;
  updatedAt: string;
  device?: Device
}

export interface Device {
  id: string;
  usageType: string | null;
  locale: string;
  os: string;
  index: number;
}

export interface TestWithAnswersAndDevices {
  test: Test;
  answers: Answer[];
  devices: Device[];
}

export interface Category {
  id: number;
  titleFi: string;
  titleEn: string;
  creatorId: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface Result {
  totalScore: number;
  descriptionFi: string;
  descriptionEn: string;
}

interface AnswerResults {
  [testId: number]: {
    [userId: number]: Result
  }
}

export default class TestStore {
  rootStore: RootStore
  tests: Test[] = []
  test: Test | null = null
  testFull: TestWithAnswersAndDevices | null = null
  testAnswers: Answer[] | null = null
  testAnswerResults: AnswerResults = {}
  testUserAnswers: Answer[] | null = null
  testViews: number | null = null
  loading = false
  createOk = false
  testCategories: Category[] = []
  testCategory: Category | null = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateTests = (tests: Test[]) => { this.tests = tests }
  setTest = (test: Test) => { this.test = test }
  setTestFull = (testFull: TestWithAnswersAndDevices | null) => { this.testFull = testFull }
  updateTestUserAnswers = (testUserAnswers: Answer[] | null ) => { this.testUserAnswers = testUserAnswers }
  updateTestResults = (testId: number, userId: number, result: Result) => {

    this.testAnswerResults = {
      ...this.testAnswerResults,
      [testId]: {
        ...(this.testAnswerResults[testId] || {}),
        [userId]: result
      }
    }
  }
  updateLoading = (loading) => { this.loading = loading }

  async getTests() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getTests()
      if (response?.ok) {
        const tests = response.data
        this.updateTests(tests)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)

  }

  async getTest(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getTest(id)
      if (response?.ok) {
        const test = response.data
        this.setTest(test)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getTestFull(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getTestFull(id)
      if (response?.ok) {
        const test = response.data
        this.setTestFull(test)
      }
    } catch (e) {
      console.log(e)
    }

    this.updateLoading(false)
  }

  async getTestUserAnswers(testId, userId) {
    let answers: Answer[] = []

    // Check if we already have the data
    if (this.testFull?.test?.id === testId) {
      answers = this.testFull?.answers || []
    } else {
      // Fetch new data
      const response: any = await Api.getTestFull(testId)
      if (response.ok) {
        answers = response.data.answers
      }
    }
    const testUserAnswers = answers.filter(ans => ans.device?.index === userId)
    this.updateTestUserAnswers(testUserAnswers)
  }

  async removeUserAnswers(testId, userId) {
    let devices: Device[] = []

    if (this.testFull?.test.id === testId) {
      devices = this.testFull?.devices || []
    } else {
      const response: any = await Api.getTestFull(testId)
      if (response.ok) {
        devices = response.data.devices;
      }
    }
    const userDevice = devices.find(({ index }) => index === userId)
    if (!userDevice) {
      console.log('NO USER DEVICE')
      return
    }

    const response = await Api.deleteUserAnswers(testId, userDevice?.id)
    if (response.ok) {
      this.updateTestUserAnswers(null)
    }
    return true
  }

  async getTestResults(testId: number, userId: number) {
    let devices: Device[] = []

    if (this.testFull?.test.id === testId) {
      devices = this.testFull.devices
    } else {
      const response: any = await Api.getTestFull(testId)
      if (response.ok) {
        devices = response.data.devices;
      }
    }

    const userDevice = devices.find(({ index }) => index === userId)
    if (!userDevice) {
      console.log('NO USER DEVICE')
      return
    }

    const results: any = await Api.getTestAnswerResults(testId, userDevice?.id);
    if (results.ok) {
      this.updateTestResults(testId, userId, results.data)
    }
  }

  async getTestAnswers(id) {
    this.loading = true
    const response: any = await Api.getTestAnswers(id)
    this.loading = false
    if (response.ok) {
      this.testAnswers = response.data
    }
  }

  async updateTest(id, test) {
    const validationError = validateTest(test)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true
    const  testData = omit(test, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (test.newPhoto && !test.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(test.newPhoto)
      if (photoResponse.ok) {
        testData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (test.removePhoto) {
      // Remove photo
      testData.photo = null
    }

    const response: any = await Api.updateTest(id, testData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.test = response.data
      const newTests = this.tests.map(p => {
        if (p.id === id) return response.data
        return p
      })
      this.updateTests(newTests)
    }
  }

  async createTest(test) {
    this.createOk = false

    const validationError = validateTest(test)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true

    const testData = omit(test, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (test.newPhoto) {
      const photoResponse: any = await Api.uploadMedia(test.newPhoto)
      if (photoResponse.ok) {
        testData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    }


    const response: any = await Api.createTest(testData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'test_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async exportTest(id) {
    Api.getTestExport(id)
  }

  async getTestViews(testId) {
    this.testViews = null
    this.loading = true
    const response: any = await Api.getTestViewsAnalytics(testId)
    this.loading = false
    if (response.ok) {
      this.testViews = response.data.data.testViews.count
    }
  }

  async getTestCategories() {
    this.loading = true
    const response = await Api.getTestCategories()
    this.loading = false
    if (response.ok) {
      this.testCategories = response.data
    }
  }

  async getTestCategory(categoryId) {
    this.loading = true
    const response = await Api.getTestCategory(categoryId)
    this.loading = false
    if (response.ok) {
      this.testCategory = response.data
    }
  }

  async createTestCategory(testCategory) {
    this.createOk = false

    this.loading = true
    const response: any = await Api.createTestCategory(testCategory)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'test_category_created')
    if (response.ok) {
      this.getTestCategories()
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async updateTestCategory(categoryId, testCategory) {
    this.loading = true
    const response: any = await Api.updateTestCategory(categoryId, testCategory)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.testCategory = response.data
      this.getTestCategories()
    }
  }

  async deleteTestCategory(categoryId) {
    this.loading = true
    const response: any = await Api.deleteTestCategory(categoryId)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'test_category_deleted')
    if (response.ok) {
      this.testCategory = null
      this.getTestCategories()
    }
  }
}
