import React from 'react'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { Colors, Fonts } from '../../Utils/theme'
import CheckIcon from '../../Assets/Icons/check-minimal.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: '.125rem',
    border: `1px solid ${Colors.lightBorder}`,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightGrey
  },
  smallContainer: {
    marginLeft: '.125rem',
    border: `1px solid ${Colors.lightBorder}`,
    height: '1.1255rem',
    width: '1.125rem',
    borderRadius: '.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white
  },
  check: {
    height: '1rem',
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  smallCheck: {
    height: '.75rem',
    maxHeight: '.75rem',
    maxWidth: '.75rem'
  },
  label: {
    fontWeight: 300,
    color: Colors.content,
    fontFamily: Fonts.content,
    fontSize: '1.25rem',
    paddingLeft: '.325rem'
  }
}))

export default function CustomCheckbox(props) {
  const classes = useStyles()

  const getClasses = () => {
    if (props.classes) {
      return { label: classes.label, ...props.classes }
    }
    return { label: classes.label }
  }

  const renderUnchecked = () => {
    return <div className={props.small ? classes.smallContainer : classes.container} />
  }
  const renderChecked = () => {
    return (
      <div className={props.small ? classes.smallContainer : classes.container}>
        <img src={CheckIcon} className={props.small ? classes.smallCheck : classes.check} alt='x' />
      </div>
    )
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checkedIcon={renderChecked()}
          icon={renderUnchecked()}
          checked={props.checked}
          onChange={props.onChange}
        />
      }
      label={props.label}
      classes={getClasses()}
    />
  )
}
