export const convertQuestionPointsToNumber = (test) => ({
  ...test,
  groups: test.groups.map(g => ({
    ...g,
    questions: g.questions.map(q => {
      if (q.points) {
        return {
          ...q,
          points: q.points.map(p => {
            const num = Number(p)
            if (!isNaN(num)) {
              return num
            }
            return 0
          })
        }
      }
      return q
    })
  }))
})