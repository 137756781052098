import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class LogStore {
  rootStore
  loginLog = []
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getMyLoginLog() {
    this.loading = true
    const response: any = await Api.getMyLoginLog({ take: 20 })
    this.loading = false
    if (response.ok) {
      this.loginLog = response.data
    }
  }
}
