import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import moment from 'moment';
import { Colors } from '../../Utils/theme';
import { Answer, Group, Question, Result } from '../../Models/TestStore';
import { Button, Dialog } from '..';

type TestUserAnswerResultsProps = {
  totalAnswers: number;
  totalViews: number;
  userAnswers: Answer[];
  groups: Group[];
  result?: Result
  remove: () => void
};
const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '1rem',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerSpacer: {
    width: '2.5rem',
  },
  smallBox: {
    border: `1px solid ${Colors.black50}`,
    padding: '2rem',
    textAlign: 'center',
    flex: 1,
    borderRadius: '.3125rem',
  },
  value: {
    fontSize: '3rem',
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '1.125rem',
  },
  questionContainer: {
    borderRadius: '.3125rem',
    border: `1px solid ${Colors.black50}`,
    padding: '1.75rem',
    marginTop: '2.5rem',
  },
  groupNum: {
    fontWeight: 700,
    fontSize: '1.125rem',
  },
  questionNum: {
    fontWeight: 300,
    fontSize: '1.125rem',
  },
  questionTitle: {
    marginTop: '.25rem',
    fontWeight: 700,
    fontSize: '1.25rem',
    color: Colors.brandColor2,
  },
  questionContent: {
    marginTop: '.25rem',
    marginBottom: '.25rem',
    fontWeight: 400,
    color: Colors.black50,
  },
  questionInfo: {
    padding: '.25rem 0',
    color: Colors.black50,
  },
  textAnswerContainer: {
    borderRadius: '.3125rem',
    marginTop: '1rem',
    backgroundColor: Colors.lightGrey,
    padding: '1.25rem 1.75rem',
  },
  ansDate: {
    color: Colors.black70,
    fontWeight: 300,
  },
  newLine: {
    marginBottom: '1rem',
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: Colors.white,
    backgroundColor: Colors.black,
    borderRadius: '2rem',
    minHeight: '2rem',
    minWidth: '2rem',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: Colors.black50,
    backgroundColor: Colors.white,
    borderRadius: '2rem',
    minHeight: '2rem',
    minWidth: '2rem',
  },
  optionText: {
    marginLeft: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    borderLeft: `1px solid ${Colors.black20}`,
  },
  optionRowsContainer: {
    paddingTop: '1rem',
  },
}));

function TestUserAnswerResults({
  totalAnswers = 0,
  totalViews = 0,
  userAnswers = [],
  groups = [],
  result,
  remove
}: TestUserAnswerResultsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [confirmDelete, setConfirmDelete] = useState(false)

  const renderWithNewLines = (text) => {
    return !text
      ? text
      : text.split('\n').map((line, index) => (
          <div key={index} className={classes.newLine}>
            {line}
          </div>
        ));
  };

  const renderResult = () => {
    if (!result) return null
    return (
      <div className={classes.questionContainer}>
        <div className={classes.groupNum}>
          {t('score')}: {result.totalScore}
        </div>
        <div className={classes.questionContent}>
          {result.descriptionFi}
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className={classes.headerRow}>
        <div className={classes.smallBox}>
          <div className={classes.value}>{totalAnswers}</div>
          <div className={classes.subtitle}>{t('num_answred_users')}</div>
        </div>
        <div className={classes.headerSpacer} />
        <div className={classes.smallBox}>
          <div className={classes.value}>{totalViews}</div>
          <div className={classes.subtitle}>{t('num_views')}</div>
        </div>
        <div className={classes.headerSpacer} />
        <div className={classes.smallBox}>
          <div className={classes.value}>-</div>
          <div className={classes.subtitle}>{t('conversion')}</div>
        </div>
        <div className={classes.headerSpacer} />
        <div className={classes.smallBox} style={{ opacity: 0 }}>
          <div className={classes.value}>-</div>
          <div className={classes.subtitle}>{t('num_activations')}</div>
        </div>
        <div>
        <Button text={t("delete_test_answer")} onClick={() => setConfirmDelete(true)} />
        </div>
      </div>
    );
  };

  const renderTextAnswer = (question) => {
    const match = userAnswers.find((ans) => {
      return ans.questionId === question.id;
    });
    if (match) {
      return (
        <div className={classes.textAnswerContainer}>
          {renderWithNewLines(match.textAnswer)}
          <div className={classes.ansDate}>
            {moment(match.createdAt).format('DD.MM.YYYY')}
          </div>
        </div>
      );
    }
    return null
  };

  const charIndex = (number) =>
    number < 26 ? String.fromCharCode(number + 65) : number;

  const renderSelectAnswer = (question) => {
    const selectedAnswers = userAnswers
      .filter((ans) => {
        return ans.questionId === question.id;
      })
      .map((ans) => ans.answerIndex);

    return (
      <div className={classes.optionRowsContainer}>
        {(question.answersFi || []).map((_, index) => {
          const optionText =
            question.answersFi[index] || question.answersEn[index];
          const isActive = selectedAnswers.includes(index);
          return (
            <div className={classes.optionRow}>
              <div className={isActive ? classes.optionActive : classes.option}>
                {charIndex(index)}
              </div>
              <div className={classes.optionText}>{optionText}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAnswer = (question: Question) => {

    if (!question) return null;

    if (question.type === 'text') {
      return renderTextAnswer(question);
    }

    if (question.type === 'select' || question.type === 'multiselect') {
      return renderSelectAnswer(question);
    }
    return null;
  };

  const renderGroups = () => {
    return groups.map((group, index) => (
      <div className={classes.questionContainer}>
      <div className={classes.groupNum}>
        {t('group')} {index + 1}.
      </div>
      <div className={classes.questionTitle}>
        {group.titleFi || group.titleEn}
      </div>
      <div className={classes.questionContent}>
        {renderWithNewLines(
          group.contentFi || group.contentEn || ''
        )}
      </div>
      {renderQuestions(group.questions || [])}
      </div>
    ))
  }

  const renderQuestions = (questions: Question[]) => {
    return questions.map((question, index) => {
      return (
        <div className={classes.questionContainer}>
          <div className={classes.questionNum}>
            {t('question')} {index + 1}.
          </div>
          <div className={classes.questionTitle}>
            {get(question, 'titleFi') || get(question, 'titleEn')}
          </div>
          <div className={classes.questionContent}>
            {renderWithNewLines(
              get(question, 'contentFi') || get(question, 'contentEn')
            )}
          </div>

          <div className={classes.questionInfo}>
            {t('question_type')}: {t(`answer_type_${get(question, 'type')}`)}
          </div>
          <div className={classes.questionInfo}>
            {!question.isOptional
              ? t('mandatory_question')
              : t('is_optional_question')}
          </div>
          {renderAnswer(question)}
        </div>
      );
    });
  };

  const handleDelete = () => {
    remove()
  }

  return (
    <div className={classes.container}>
      {renderHeader()}
      {renderResult()}
      {renderGroups()}
      <Dialog
            open={confirmDelete}
            handleClose={() => setConfirmDelete(false)}
            handleOk={handleDelete}
            title={t('confirm_test_answer_title')}
            description={t('confirm_test_answer_description')}
            okText={t('yes')}
          />
    </div>
  );
}

export default TestUserAnswerResults;
