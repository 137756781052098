import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ACCESS_RIGHTS } from '../../Constants'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles({
  container: {

  },
  title: {
    marginTop: '1rem',
    marginBottom: '.5rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: Colors.heading
  },
  boxesContainer: {
    padding: '1rem .25rem'
  }
})

function AccessRightsSelector (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const toggleAccessRight = (value) => {
    const checked = (props.accessRights || []).find(v => v === value)
    if (checked) {
      props.setAccessRights((props.accessRights || []).filter(ar => ar !== value))
    } else {
      props.setAccessRights([...(props.accessRights || []), value])
    }
  }

  const renderCheckBoxes = () => ACCESS_RIGHTS.map(value => {
    const checked = (props.accessRights || []).find(v => v === value)
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => toggleAccessRight(value)}
              name={t('access_rights')}
            />
          }
          label={t(`access_right_${value}`)}
        />
      </div>

    )
  })

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t('access_rights')}</div>
      <p>{t('access_rights_description')}</p>
      <div className={classes.boxesContainer}>
        {renderCheckBoxes()}
      </div>
    </div>
  )
}

export default AccessRightsSelector
