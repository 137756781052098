import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  PageActions,
  PageContainer,
  PageContent,
  PageHeader,
  TestForm,
} from '../../../Components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../Models/RootStore';
import { useParams } from 'react-router-dom';
import { Test as TestModel } from '../../../Models/TestStore';
import TestActions from '../../../Components/Test/TestActions';
import moment from 'moment';
import TestResult from '../../../Components/Test/TestResult';
import UserAnswers from '../../../Components/Common/UserAnswers';
import { convertQuestionPointsToNumber } from './convertQuestionPointsToNumber'

const Test = () => {
  const { t } = useTranslation();
  const { testStore } = useStore();
  const { testId }: { testId: string } = useParams();
  const [test, setTest] = useState<TestModel>();
  const [alertVisible, setAlertVisible] = useState(false);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    testStore.getTest(testId);
    testStore.getTestAnswers(testId);
    testStore.getTestFull(testId);
    testStore.getTestViews(testId);
    // testStore.getTestCategories()
  }, [testId]);

  useEffect(() => {
    if (testStore.test) {
      setTest(testStore.test);
    }
  }, [testStore.test]);

  const toggleAlert = (state: boolean) => setAlertVisible(state);

  const selectTab = (_, value) => setTab(value);

  const handleSave = () => {
    const testToUpdate = { ...test };
    if (!test?.validFrom) {
      testToUpdate.validFrom = moment().startOf('day').toISOString();
    } else {
      testToUpdate.validFrom = moment(test.validFrom).seconds(0).toISOString();
    }

    if (test?.validUntil) {
      testToUpdate.validUntil = moment(test.validUntil)
        .seconds(0)
        .toISOString();
    }

    if (test?.photo instanceof File) {
      testToUpdate.newPhoto = test.photo;
    }
    testStore.updateTest(testId, convertQuestionPointsToNumber(testToUpdate));
  };

  const toggleDelete = () => {
    testStore.updateTest(testId, {
      active: !testStore.test.active,
    });
    toggleAlert(false);
  };

  const renderContent = () => {
    if (!tab) {
      // Analytics
      const noDataAvailable =
        !testStore.test || !testStore.testAnswers || !testStore.testFull;
      if (noDataAvailable) return null;

      return (
        <PageContent size="medium">
          <TestResult
            testFull={testStore.testFull}
            answers={testStore.testAnswers}
            totalAnswers={testStore.test.totalAnswers || 0}
            views={testStore.testViews}
          />
        </PageContent>
      );
    }
    if (tab === 1) {
      if (!testStore.testFull) return null;

      return (
        <PageContent size="medium">
          <UserAnswers
            id={testStore.testFull.test.id}
            devices={testStore.testFull.devices}
            answers={testStore.testFull.answers}
            path="tests"
          />
        </PageContent>
      );
    }

    if (!test) return null;
    return (
      <>
        <PageContent disabled={!testStore.test.active} size="small">
          <TestForm test={test} onChange={setTest} categories={[]} />
        </PageContent>
        <PageActions>
          {test ? (
            <TestActions
              test={test}
              onChange={setTest}
              onCancel={() => window.location.reload()}
              onSave={handleSave}
              toggleActive={toggleDelete}
            />
          ) : null}
        </PageActions>
      </>
    );
  };

  const getTitle = () => {
    if (tab === 0 || tab === 1) {
      return testStore.test.titleFi || testStore.test.titleEn;
    }
    return t('test_details');
  };

  const getSubtitle = () => {
    if (tab === 0 || tab === 1) {
      const start = testStore.test.validFrom;
      const end = testStore.test.validUntil;
      if (start || end) {
        return `${start ? moment(start).format('D.M.YYYY') : ''}-${
          end ? moment(end).format('D.M.YYYY') : ''
        }`;
      }
    }
    return null;
  };

  const getExcelButtonAction = () => {
    if (tab === 0) {
      return () => testStore.exportTest(testId);
    }
    return null;
  };

  if (!test) return null;

  return (
    <>
      <PageHeader
        title={getTitle()}
        subtitle={getSubtitle()}
        backButtonLink="/tests"
        onExcelButtonPress={getExcelButtonAction()}
        tabs={[
          { id: 0, label: t('test_analytics') },
          { id: 1, label: t('test_user_answers') },
          { id: 2, label: t('edit_test') },
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>{renderContent()}</PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={() => toggleAlert(false)}
        handleOk={toggleDelete}
        title={
          testStore.test.active
            ? t('delete_test_title')
            : t('activate_test_title')
        }
        description={
          testStore.test.active
            ? t('delete_test_description')
            : t('activate_test_description')
        }
      />
    </>
  );
};

export default observer(Test);
