import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Utils/theme'
import Chart from 'react-apexcharts'
import uniq from 'lodash/uniq'
const NUM_VERSIONS = 10

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  spacer: {
    width: '2rem'
  },
  box: {
    borderRadius: '.3125rem',
    flex: 1,
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1.5rem 1rem .25rem',
    display: 'flex',
    flexDirection: 'column',
  },
  rightBox: {
    borderRadius: '.3125rem',
    flex: 1,
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1.5rem 0 0 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    fontSize: '1.5rem',
    color: Colors.boxTitle,
    lineHeight: 1,
    marginBottom: '.25rem'
  },
  subTitle: {
    color: Colors.boxTitle,
    lineHeight: 1,
    fontSize: '.875rem',
    marginBottom: '.75rem'
  },
  subTitle2: {
    color: Colors.boxTitle,
    lineHeight: 1,
    fontSize: '.875rem',
    marginBottom: '-.25rem'
  },
  borderHackContainer: {
    position: 'relative',
    marginLeft: '-1rem',
    marginRight: '-1rem'
  },
  borderHack: {
    left: 0,
    right: 0,
    width: '100%',
    height: 1,
    top: -36,
    zIndex: 1,
    position: 'absolute',
    backgroundColor: `${Colors.lightBorder}`
  },
  borderHack2: {
    left: 0,
    right: 0,
    width: '100%',
    height: 1,
    top: -48,
    zIndex: 1,
    position: 'absolute',
    backgroundColor: `${Colors.lightBorder}`
  }
}))

function UsageStatisticsSummary(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getRolesChartOptions = () => {
    return {
      chart: {
        type: 'donut',
        animations: {
          enabled: false
        }
      },
      legend: {
        position: 'bottom',
      },
      grid: {
        padding: {
          bottom: 20
        },
      },
      dataLabels: {
        enabled: false,

        style: {
          colors: ['rgba(0,0,0,0)'],
          fontWeight: 300,
          backgroundColor: 'red'
        },
        background: {
          enabled: true,
          foreColor: 'rgba(255,255,255,0)',
          borderWidth: 0
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '66%'
          }
        }
      },
      colors: [
        Colors.chartBlue,
        Colors.chartRed,
        Colors.chartYellow
      ],
      labels: [t('citizens'), t('visitors'), t('business_attendees')]
    }
  }


  const renderRolesChart = () => {
    const series = [
      props.activeUserTypes.citizen,
      props.activeUserTypes.visitor,
      props.activeUserTypes.businessAttendee,

    ]
    return (
      <Chart
        options={getRolesChartOptions()}
        series={series}
        type='donut'
        //width="500"
        height="330"
      />
    )
  }

  const getAppVersionChartOptions = () => {
    return {
      chart: {
        type: 'bar',
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      colors: [
        Colors.chartBlue,
        Colors.chartRed,
        Colors.chartYellow
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '36%',
          colors: {
            backgroundBarColors: [Colors.inputGrey]
          }
        }
      },
      dataLabels: {
        enabled: false,

        textAnchor: 'start',
        formatter: function(val, opt) {
            return val
        },
        style: {
          colors: ['#fff'],
          fontWeight: 300,
          backgroundColor: 'red'
        },
        background: {
          enabled: true,
          foreColor: '#333',
          borderWidth: 0
        },
        offsetX: 10000
      },
      xaxis: {
        categories: props.appVersions.slice(0, NUM_VERSIONS).map(item => item.version),
        axisBorder: {
          show: false // According to layout
        },
        axisTicks: {
          show: false // According to layout
        }
      },
      grid: {
        show: false // According to layout
      }
    }
  }

  const renderAppVersionsChart = () => {
    const series = [{
      name: 'Aktiivisia käyttäjiä',
      data: props.appVersions.slice(0, NUM_VERSIONS).map(item => item.count)
    }]

    return (
      <Chart
        options={getAppVersionChartOptions()}
        series={series}
        type='bar'
        height="320"
        //width="530"
      />
    )
  }

  const getAgeGroups = () => {
    return uniq(props.demographics.map(({ ageGroup }) => ageGroup)).sort().filter(val => val !== 'unknown')
  }

  const getDemographicsSeries = () => {
    const maleData = []
    const femaleData = []


    const ageGroups = getAgeGroups()
    for (const ageGroup of ageGroups) {
      const maleRow = props.demographics.find(row => row.gender === 'male' && row.ageGroup === ageGroup)
      const males = maleRow ? (maleRow.count || 0) : 0
      maleData.push(males)
      const femaleRow = props.demographics.find(row => row.gender === 'female' && row.ageGroup === ageGroup)
      const females = femaleRow ? (femaleRow.count || 0) : 0
      femaleData.push(females)
    }

    return [
      {
        name: 'Naiset',
        data: femaleData
      },
      {
        name: 'Miehet',
        data: maleData
      }
    ]
  }

  const getDemographicsChartOptions = () => {
    const ageGroups = getAgeGroups()

    return {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      legend: {
        enabled: false,
        position: 'bottom',
        height: 40,
        offsetY: 18
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',
          colors: {
            backgroundBarColors: [Colors.inputGrey]
          }
        }
      },
      colors: [
        Colors.chartRed,
        Colors.chartBlue,
        Colors.chartYellow
      ],
      xaxis: {
        categories: ageGroups,
        axisBorder: {
          show: false // According to layout
        },
        axisTicks: {
          show: false // According to layout
        },
        labels: {
          formatter: function (val) {
            return val
          }
        }
      },
      grid: {
        show: false // According to layout
      },
      dataLabels: {
        enabled: false,

        style: {
          colors: ['rgba(0,0,0,0)'],
          fontWeight: 300,
          backgroundColor: 'red'
        },
        background: {
          enabled: true,
          foreColor: 'rgba(255,255,255,0)',
          borderWidth: 0
        }
      },
    }
  }

  const renderDemographicsChart = () => {
    return (
      <Chart
        options={getDemographicsChartOptions()}
        series={getDemographicsSeries()}
        type='bar'
        height="310"
        //width="530"
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.title}>{t('user_roles')}</div>
        <div className={classes.subTitle}>{t('of_all_users')}</div>
        {!!props.activeUserTypes && renderRolesChart()}
        <div className={classes.borderHackContainer}>
          <div className={classes.borderHack} />
        </div>
      </div>
      <div className={classes.spacer} />
      <div className={classes.box}>
        <div className={classes.title}>{t('age_groups_and_genders')}</div>
        <div className={classes.subTitle2}>{t('demographics_disclaimer')}</div>
        {!!props.demographics && renderDemographicsChart()}
        <div className={classes.borderHackContainer}>
          <div className={classes.borderHack2} />
        </div>
      </div>
      <div className={classes.spacer} />
      <div className={classes.rightBox}>
        <div className={classes.title}>{t('app_versions')}</div>
        <div className={classes.subTitle2}>{t('android_and_ios')}</div>
        {!!props.appVersions && renderAppVersionsChart()}
      </div>
    </div>
  )
}

export default UsageStatisticsSummary

