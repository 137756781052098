import { makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { useStore } from '../../../Models/RootStore'
import { PollCategoryTable, Title, Input, Button, Dialog } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import { observer } from 'mobx-react'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    flex: 5,
  },
  sidebar: {
    borderLeft: `1px solid ${Colors.black20}`,
    paddingLeft: '2.5rem',
    marginLeft: '2.5rem',
    marginTop: '-2rem',
    paddingTop: '2rem',
    flex: 3,
    minHeight: '100vh'
  },
  buttonsContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    width: '2rem'
  }
}))

const PollCategories = () => {
  const classes = useStyles()
  const { pollStore, userStore } = useStore()
  const { t } = useTranslation()

  const [editId, setEditId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')

  useEffect(() => {
    userStore.getMyOrganizationUsers()
    pollStore.getPolls()
    pollStore.getPollCategories()
  }, [])

  const handleCancel = () => {
    setTitleFi('')
    setTitleEn('')
    setEditId(null)
  }

  const handleDelete = (id) => {
    if (deleteId) {
      pollStore.deletePollCategory(id)
      setDeleteId(null)
    } else {
      setDeleteId(id)
    }
  }

  const handleSave = () => {
    if (editId) {
      // Edit existing
      pollStore.updatePollCategory(editId, {
        titleFi,
        titleEn
      })
    } else {
      // Create new
      pollStore.createPollCategory({
        titleFi,
        titleEn
      })
    }
    setTitleFi('')
    setTitleEn('')
    setEditId(null)
  }

  const handleEdit = (category) => {
    setEditId(category.id)
    setTitleFi(category.titleFi || '')
    setTitleEn(category.titleEn || '')
  }

  const getUserName = (user) => {
    if (user.firstName || user.lastName) return `${user.firstName} ${user.lastName}`
    const namePart = (user.email || '').split('@')[0]
    // Handle dots and dashes
    return namePart.split('.').map(str => capitalize(str)).join(' ').split('-').map(str => capitalize(str)).join('-')
  }

  const getCategories = () => {
    return pollStore.pollCategories.map(item => {
      const numPolls = pollStore.polls.filter(poll => poll.categoryId === item.id).length
      const creatorMatch = userStore.users.find(user => user.id === item.creatorId)
      const creator = creatorMatch ? getUserName(creatorMatch) : ''
      return {
        ...item,
        creator,
        numPolls,
        createdAt: moment(item.createdAt).format('DD.MM.YYYY HH:mm'),
        title: item.titleFi || item.titleEn,
        onEdit: () => handleEdit(item),
        onDelete: () => handleDelete(item.id)
      }
    })
  }

  const renderSidebar = () => {
    return (
      <>
        <Title
          title={editId ? t('edit_category') : t('add_new_category')}
        />
        <Input
          label={t('category_name_fi')}
          value={titleFi}
          onChange={setTitleFi}
        />

        <Input
          label={t('category_name_en')}
          value={titleEn}
          onChange={setTitleEn}
        />
        <div className={classes.buttonsContainer}>
          <Button
            text={editId ? t('save_category') : t('create_category')}
            onClick={handleSave}
            disabled={!titleFi || !titleEn}
            fullWidth
          />
          <div className={classes.spacer} />
          <Button
            text={t('cancel')}
            onClick={handleCancel}
            secondary
            fullWidth
          />
        </div>
      </>
    )
  }

  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={!!deleteId}
        handleClose={() => setDeleteId(null)}
        handleOk={() => handleDelete(deleteId)}
        title={t('delete_poll_category')}
        description={t('delete_poll_category_description')}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <PollCategoryTable
          items={getCategories()}
        />
      </div>
      <div className={classes.sidebar}>
        {renderSidebar()}
      </div>
      {renderDeleteDialog()}
    </div>
  )
}

export default observer(PollCategories)
