import { makeStyles } from '@material-ui/core'
import LeftIcon from '../../Assets/Icons/mini-left.svg'
import RightIcon from '../../Assets/Icons/mini-right.svg'
import moment from 'moment'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { Input } from '../../Components'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '24rem'
  },
  inputSpacer: {
    display: 'block',
    width: '2rem'
  },
  button: {
    userSelect: 'none',
    border: '1px solid rgba(0,0,0,.2)',
    backgroundColor: Colors.inputGrey,
    height: '3.125rem',
    width: '3.125rem',
    cursor: 'pointer',
    borderRadius: '.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateRange: {
    margin: '0 .625rem',
    border: '1px solid rgba(0,0,0,.2)',
    backgroundColor: Colors.inputGrey,
    height: '3.125rem',
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
    borderRadius: '.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.125rem',
    fontWeight: 300
  },
  buttonDisabled: {
    cursor: 'default'
  },
  icon: {
    opacity: 1
  },
  iconDisabled: {
    opacity: 0.5
  }
}))

function AnalyticsDateRangeSelector(props) {
  const classes = useStyles()
  const { t } = useTranslation()


  const formatDateRange = () => {
    const dateFormat = 'DD.MM.YYYY'
    if (props.startDate && props.endDate) {
      return `${moment(props.startDate).format(dateFormat)} - ${moment(props.endDate).format(dateFormat)}`
    }
    return null
  }

  const nextEnabled = () => props.endDate !== moment().format('YYYY-MM-DD')

  const handleNext = () => {
    if (nextEnabled()) {
      props.onNext()
    }
  }

  return (
    <div className={classes.container}>
      <Input
        type='date'
        label={t('date_picker_start')}
        value={props.startDate}
        onChange={props.setStartDate}
      />
      <div className={classes.inputSpacer} />
      <Input
        type='date'
        label={t('date_picker_end')}
        value={props.endDate}
        onChange={props.setEndDate}
      />
    </div>
  )

  /*
  return (
    <div className={classes.container}>
      <div className={classes.button} onClick={props.onPrevious}>
        <img src={LeftIcon} className={classes.icon} alt='previous' />
      </div>
      <div className={classes.dateRange}>
        <span>{formatDateRange()}</span>
      </div>
      <div
        className={[classes.button, nextEnabled() ? '' : classes.buttonDisabled].join(' ')}
        onClick={handleNext}
      >
        <img
          src={RightIcon}
          className={[classes.icon, nextEnabled() ? '' : classes.iconDisabled].join(' ')}
          alt='next'
        />
      </div>

    </div>
  )
  */
}

export default AnalyticsDateRangeSelector

