import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { useTranslation } from 'react-i18next'
import { TableHeader, DataMessage } from '..'
import CouponCategoryTableRow from './CouponCategoryTableRow'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  }
}))

function CouponCategoryTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.spacer} />
          <CouponCategoryTableRow
            item={item}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[
          t('category'),
          t('num_coupons'),
          t('num_organizations'),
          t('created_at'),
          t('actions')
        ]} />
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_items')} />
    </>
  )
}

export default CouponCategoryTable
