import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { get } from 'lodash'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, PageContainer, PageContent, PageActions, CouponCampaignForm, CouponCampaignActions, CouponCampaignAnalytics } from '../../../Components'
import { validateCouponCampaign } from '../../../Utils/couponCampaignValidation'
import { useParams } from 'react-router'
import { Api } from '../../../Services'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import linkifyHtml from 'linkify-html'

const CouponCampaign = (props) => {
  const { couponStore, couponCampaignStore, appStore } = useStore()
  const { couponCampaignId }: any = useParams()

  useEffect(() => {
    couponStore.getOrganizations()
    couponCampaignStore.getCouponCampaign(Number(couponCampaignId))
    couponCampaignStore.getCouponCampaignAnalytics(Number(couponCampaignId))
  }, [])

  useEffect(() => {
    if (couponCampaignStore.createOk) {
      //props.history.push('/campaigns')
    }
  }, [couponCampaignStore.createOk])

  useEffect(() => {
    setNameFi(get(couponCampaignStore.couponCampaign, 'nameFi', null))
    setNameEn(get(couponCampaignStore.couponCampaign, 'nameEn', null))
    setNumRequiredCodes(get(couponCampaignStore.couponCampaign, 'numRequiredCodes', null))
    setCampaignType(get(couponCampaignStore.couponCampaign, 'campaignType', null))
    setLotteryLink(get(couponCampaignStore.couponCampaign, 'lotteryLink', null))
    setMobileUserType(get(couponCampaignStore.couponCampaign, 'mobileUserType', null))
    setTitleFi(get(couponCampaignStore.couponCampaign, 'titleFi', null))
    setTitleEn(get(couponCampaignStore.couponCampaign, 'titleEn', null))

    setPrizeTitleFi(get(couponCampaignStore.couponCampaign, 'prizeTitleFi', null))
    setPrizeTitleEn(get(couponCampaignStore.couponCampaign, 'prizeTitleEn', null))
    setPrizeContentFi(get(couponCampaignStore.couponCampaign, 'prizeContentFi', null))
    setPrizeContentEn(get(couponCampaignStore.couponCampaign, 'prizeContentEn', null))

    setPhoto(get(couponCampaignStore.couponCampaign, 'photo', null))

    // htmlFi
    const newHtmlFi = get(couponCampaignStore.couponCampaign, 'htmlFi', null)
    if (newHtmlFi) {
      const { contentBlocks, entityMap } = htmlToDraft(newHtmlFi)
      setHtmlFi(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
    }

    // htmlEn
    const newHtmlEn = get(couponCampaignStore.couponCampaign, 'htmlEn', null)
    if (newHtmlEn) {
      const { contentBlocks, entityMap } = htmlToDraft(newHtmlEn)
      setHtmlEn(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
    }

    setNotificationTitleFi(get(couponCampaignStore.couponCampaign, 'notificationTitleFi', null))
    setNotificationTitleEn(get(couponCampaignStore.couponCampaign, 'notificationTitleEn', null))

    // notificationHtmlFi
    const newNotificationHtmlFi = get(couponCampaignStore.couponCampaign, 'notificationHtmlFi', null)
    if (newNotificationHtmlFi) {
      const { contentBlocks, entityMap } = htmlToDraft(newNotificationHtmlFi)
      setNotificationHtmlFi(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
    }

    // notificationHtmlEn
    const newNotificationHtmlEn = get(couponCampaignStore.couponCampaign, 'notificationHtmlEn', null)
    if (newNotificationHtmlEn) {
      const { contentBlocks, entityMap } = htmlToDraft(newNotificationHtmlEn)
      setNotificationHtmlEn(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
    }

    const newStartDate = get(couponCampaignStore.couponCampaign, 'startDate', null)
    if (newStartDate) {
      setStartDate(moment(newStartDate).format('YYYY-MM-DD'))
      setStartTime(moment(newStartDate).format('HH:mm'))
    }

    const newEndDate = get(couponCampaignStore.couponCampaign, 'endDate', null)
    if (newEndDate) {
      setEndDate(moment(newEndDate).format('YYYY-MM-DD'))
      setEndTime(moment(newEndDate).format('HH:mm'))
    }

    if (couponCampaignStore.couponCampaign) {
      // Check if all english fields are empty
      let newDisableEnglish = true
      const allKeys = Object.keys(couponCampaignStore.couponCampaign)
      for (const key of allKeys) {
        if (key.slice(-2) === 'En') {
          if (!!couponCampaignStore.couponCampaign[key]) {
            newDisableEnglish = false
          }
        }
      }
      setDisableEnglish(newDisableEnglish)
    }

    setOrganizationIds(get(couponCampaignStore.couponCampaign, 'organizationIds', []))
    setActive(!!get(couponCampaignStore.couponCampaign, 'active', null))

  }, [couponCampaignStore.couponCampaign])

  const [nameFi, setNameFi] = useState(null)
  const [nameEn, setNameEn] = useState(null)
  const [numRequiredCodes, setNumRequiredCodes] = useState(null)
  const [campaignType, setCampaignType] = useState('physical')
  const [lotteryLink, setLotteryLink] = useState('')
  const [mobileUserType, setMobileUserType] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [htmlFi, setHtmlFi] = useState(null)
  const [htmlEn, setHtmlEn] = useState(null)

  const [notificationTitleFi, setNotificationTitleFi] = useState(null)
  const [notificationTitleEn, setNotificationTitleEn] = useState(null)
  const [notificationHtmlFi, setNotificationHtmlFi] = useState(null)
  const [notificationHtmlEn, setNotificationHtmlEn] = useState(null)

  const [prizeTitleFi, setPrizeTitleFi] = useState(null)
  const [prizeTitleEn, setPrizeTitleEn] = useState(null)
  const [prizeContentFi, setPrizeContentFi] = useState(null)
  const [prizeContentEn, setPrizeContentEn] = useState(null)

  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)

  const [endDate, setEndDate] = useState(null)
  const [endTime, setEndTime] = useState(null)

  const [disableEnglish, setDisableEnglish] = useState(false)

  const [draft, setDraft] = useState(false)

  const [organizationIds, setOrganizationIds] = useState([])

  const [submitted, setSubmitted] = useState(false)
  const [active, setActive] = useState(false)

  const [tab, setTab] = useState(0)

  const selectTab = (evt, value) => setTab(value)

  const { t } = useTranslation()

  const toggleDisableEnglish = () => setDisableEnglish(!disableEnglish)

  const handleSetStartDate = (val) => setStartDate(val)
  const handleSetStartTime = (val) => setStartTime(val)
  const handleSetEndDate = (val) => setEndDate(val)
  const handleSetEndTime = (val) => setEndTime(val)

  const handleSetNewPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setRemovePhoto(false)
    }
  }

  const handleRemovePhoto = (photo) => {
    setNewPhoto(null)
    setPhoto(null)
    setRemovePhoto(true)
  }

  const toggleActive = () => {
    couponCampaignStore.updateCouponCampaign(
      Number(couponCampaignId),
      {
        active: !get(couponCampaignStore.couponCampaign, 'active', null)
      }
    )
  }

  const updateCouponCampaign = () => {
    setSubmitted(true)

    let newStartDate = null
    if (!draft && startDate) {
      newStartDate = moment(startDate).startOf('day').format()
      if (startTime) {
        const timeMoment = moment(startTime)
        newStartDate = moment(newStartDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    let newEndDate = null
    if (endDate) {
      newEndDate = moment(endDate).startOf('day').format()
      if (endTime) {
        const timeMoment = moment(endTime)
        newEndDate = moment(newEndDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    const newHtmlFi = htmlFi ? linkifyHtml(draftToHtml(convertToRaw(htmlFi.getCurrentContent()))) : null
    const newHtmlEn = htmlEn ? linkifyHtml(draftToHtml(convertToRaw(htmlEn.getCurrentContent()))) : null
    const newNotificationHtmlFi = notificationHtmlFi ? linkifyHtml(draftToHtml(convertToRaw(notificationHtmlFi.getCurrentContent()))) : null
    const newNotificationHtmlEn = notificationHtmlEn ? linkifyHtml(draftToHtml(convertToRaw(notificationHtmlEn.getCurrentContent()))) : null

    const couponCampaign = {
      nameFi,
      nameEn,
      numRequiredCodes,
      campaignType,
      lotteryLink: (lotteryLink && lotteryLink.trim().length) ? lotteryLink.trim() : null,
      // mobileUserType, // DISABLED FOR NOW
      newPhoto,
      removePhoto,
      titleFi,
      titleEn,
      htmlFi: newHtmlFi,
      htmlEn: newHtmlEn,
      notificationTitleFi,
      notificationTitleEn,
      notificationHtmlFi: newNotificationHtmlFi,
      notificationHtmlEn: newNotificationHtmlEn,
      prizeTitleFi,
      prizeTitleEn,
      prizeContentFi,
      prizeContentEn,
      startDate: newStartDate ? newStartDate.toISOString() : null,
      endDate: newEndDate ? newEndDate.toISOString() : null,
      organizationIds,
      draft
    }

    // TODO: Remove english fields if english is disabled
    if (disableEnglish) {
      const allKeys = Object.keys(couponCampaign)
      for (const key of allKeys) {
        if (key.slice(-2) === 'En') {
          couponCampaign[key] = null
        }
      }
    }

    // Validation
    if (!draft && ((!startDate !== !startTime) || (!endDate !== !endTime))) {
      return appStore.setError('fill_all_required_fields')
    }
    const validationError = validateCouponCampaign(couponCampaign, disableEnglish)
    if (validationError) {
      return appStore.setError(validationError)
    }

    couponCampaignStore.updateCouponCampaign(Number(couponCampaignId), couponCampaign)
  }

  const renderAnalytics = () => {
    if (
      Number(couponCampaignId) !== get(couponCampaignStore.couponCampaignAnalytics, 'couponCampaignId', '')
    ) {
      return null
    }

    return (
      <PageContainer>
        <PageContent disabled={!active} size='medium'>
          <CouponCampaignAnalytics
            analytics={couponCampaignStore.couponCampaignAnalytics}
            lotteryLinkOpensCount={couponCampaignStore.lotteryLinkOpensCount}
          />
        </PageContent>
      </PageContainer>
    )
  }

  const renderEditForm = () => {
    return (
      <PageContainer>
        <PageContent disabled={!active} size='medium'>
          <CouponCampaignForm
            nameFi={nameFi}
            setNameFi={setNameFi}
            nameEn={nameEn}
            setNameEn={setNameEn}
            numRequiredCodes={numRequiredCodes}
            setNumRequiredCodes={setNumRequiredCodes}
            campaignType={campaignType}
            setCampaignType={setCampaignType}
            lotteryLink={lotteryLink}
            setLotteryLink={setLotteryLink}
            mobileUserType={mobileUserType}
            setMobileUserType={setMobileUserType}
            photo={photo}
            setPhoto={handleSetNewPhoto}
            removePhoto={handleRemovePhoto}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            htmlFi={htmlFi}
            setHtmlFi={setHtmlFi}
            htmlEn={htmlEn}
            setHtmlEn={setHtmlEn}
            notificationTitleFi={notificationTitleFi}
            setNotificationTitleFi={setNotificationTitleFi}
            notificationTitleEn={notificationTitleEn}
            setNotificationTitleEn={setNotificationTitleEn}
            notificationHtmlFi={notificationHtmlFi}
            setNotificationHtmlFi={setNotificationHtmlFi}
            notificationHtmlEn={notificationHtmlEn}
            setNotificationHtmlEn={setNotificationHtmlEn}
            prizeTitleFi={prizeTitleFi}
            setPrizeTitleFi={setPrizeTitleFi}
            prizeTitleEn={prizeTitleEn}
            setPrizeTitleEn={setPrizeTitleEn}
            prizeContentFi={prizeContentFi}
            setPrizeContentFi={setPrizeContentFi}
            prizeContentEn={prizeContentEn}
            setPrizeContentEn={setPrizeContentEn}
            organizationIds={organizationIds}
            setOrganizationIds={setOrganizationIds}
            organizations={couponStore.organizations}
            submitted={submitted}
            disableEnglish={disableEnglish}
            qrCodeDownloadLink={couponCampaignId ? Api.generateQrCodeDownloadLink(couponCampaignId) : null}
            key={couponStore.couponCampaign ? couponStore.couponCampaign.id : 0}
            type='edit'
          />
        </PageContent>
        <PageActions>
          <CouponCampaignActions
            startDate={startDate}
            setStartDate={handleSetStartDate}
            startTime={startTime}
            setStartTime={handleSetStartTime}
            endDate={endDate}
            setEndDate={handleSetEndDate}
            endTime={endTime}
            setEndTime={handleSetEndTime}
            draft={draft}
            setDraft={setDraft}
            disableEnglish={disableEnglish}
            toggleDisableEnglish={toggleDisableEnglish}
            onSave={updateCouponCampaign}
            onCancel={() => window.location.reload()}
            active={active}
            toggleActive={toggleActive}
            key={couponStore.couponCampaign ? couponStore.couponCampaign.id : 0}
          />
        </PageActions>
      </PageContainer>
    )
  }

  return (
    <>
      <PageHeader
        title={t('edit_coupon_campaign')}
        tabs={[
          { id: 0, label: t('coupon_campaign_details') },
          { id: 1, label: t('coupon_campaign_analytics') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
        backButtonLink='/campaigns'
      />
      {tab === 1 ? renderAnalytics() : renderEditForm()}
    </>
  )
}

export default observer(CouponCampaign)
