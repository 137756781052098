import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.5rem'
  },
  innerContainer: {
    width: '100%',
    position: 'relative'
  },
  formControl: {
    width: '100%'
  },
  textFieldRoot: {
    width: '100%'
  },
  select: {
    width: '100%',
    height: '3.75rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.125rem',
    fontFamily: Fonts.content,
    fontWeight: 300,
    color: Colors.black,
    backgroundColor: Colors.inputGrey,
    borderRadius: 5,
    padding: '0 1rem',
    '&:focus': {
      backgroundColor: Colors.white
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  label: {
    fontSize: '1.125rem'
  }
}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




export default function CouponMultiSelect(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.onChange(event.target.value)
  }

  const renderValue = (selectedIds) => {
    const values = []
    for (const id of (selectedIds || [])) {
      const match = (props.options || []).find(item => item.value === id)
      if (match) values.push(match.label)
    }

    return values.join(', ')
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <FormControl  variant='outlined' className={classes.formControl}>
          <div className={classes.label}>{props.label}</div>
          <Select
            multiple
            value={props.value}
            onChange={handleChange}
            renderValue={renderValue}
            MenuProps={MenuProps}
            classes={{
              root: classes.select
            }}
            error={props.error}
            helperText={props.helperText}
          >
            {(props.options || []).map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                <Checkbox checked={(props.value || []).includes(opt.value)} />
                <ListItemText primary={opt.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}
