import React, { useState, useEffect } from 'react'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, PollResult, PollActions, PageContainer, PageContent, PageActions, Dialog } from '../../../Components'
import PollForm from '../../../Components/Poll/PollForm'
import UserAnswers from '../../../Components/Common/UserAnswers'

const Poll = () => {
  const { pollStore } = useStore()
  const { pollId }: any = useParams()

  useEffect(() => {
    pollStore.getPoll(pollId)
    pollStore.getPollAnswers(pollId)
    pollStore.getPollFull(pollId)
    pollStore.getPollViews(pollId)
    pollStore.getPollCategories()
  }, [])

  useEffect(() => {
    if (pollStore.poll) {
      setTitleFi(get(pollStore.poll, 'titleFi', ''))
      setTitleEn(get(pollStore.poll, 'titleEn', ''))
      setContentFi(get(pollStore.poll, 'contentFi', ''))
      setContentEn(get(pollStore.poll, 'contentEn', ''))

      // Handle media
      if (get(pollStore.poll, 'video')) {
        setVideo(get(pollStore.poll, 'video'))
        setMediaType('video')
      } else if (get(pollStore.poll, 'photo')) {
        setPhoto(get(pollStore.poll, 'photo'))
        setMediaType('photo')
      }

      setQuestions(get(pollStore.poll, 'questions', []))
      setDraft(get(pollStore.poll, 'draft', false))
      setCategoryId(get(pollStore.poll, 'categoryId', null))

      const newStartDate = get(pollStore.poll, 'validFrom', null)
      if (newStartDate) {
        setStartDate(moment(newStartDate).format('YYYY-MM-DD'))
        setStartTime(moment(newStartDate).format('HH:mm'))
      }

      const newEndDate = get(pollStore.poll, 'validUntil', null)
      if (newEndDate) {
        setEndDate(moment(newEndDate).format('YYYY-MM-DD'))
        setEndTime(moment(newEndDate).format('HH:mm'))
      }

    }
  }, [pollStore.poll])

  const [tab, setTab] = useState(0)

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')

  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)

  const [endDate, setEndDate] = useState(null)
  const [endTime, setEndTime] = useState(null)

  const [questions, setQuestions] = useState([])
  const [alertVisible, setAlertVisible] = useState(false)
  const [draft, setDraft] = useState(false)
  const [userLang, setUserLang] = useState(null)
  const [userTypes, setUserTypes] = useState(null)
  const [categoryId, setCategoryId] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [video, setVideo] = useState(null)
  const [mediaType, setMediaType] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)


  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setVideo(null)
      setMediaType('photo')
      setRemovePhoto(false)
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setVideo(null)
    setMediaType(null)
    setNewPhoto(null)
    setRemovePhoto(true)
  }

  const handleDeleteVideo = () => {
    setMediaType(null)
    setVideo(null)
    setPhoto(null)
    setRemovePhoto(true)
  }

  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const selectTab = (evt, value) => setTab(value)

  const handleSetStartDate = (val) => setStartDate(val)
  const handleSetStartTime = (val) => setStartTime(val)
  const handleSetEndDate = (val) => setEndDate(val)
  const handleSetEndTime = (val) => setEndTime(val)

  const { t } = useTranslation()

  const updatePoll = () => {

    let newStartDate: any = null
    if (!draft && startDate) {
      newStartDate = moment(startDate).startOf('day').format()
      if (startTime) {
        const timeMoment = moment('2000-01-01 ' + startTime)
        newStartDate = moment(newStartDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    let newEndDate: any = null
    if (endDate) {
      newEndDate = moment(endDate).startOf('day').format()
      if (endTime) {
        const timeMoment = moment('2000-01-01 ' + endTime)
        newEndDate = moment(newEndDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    pollStore.updatePoll(pollId, {
      titleFi,
      titleEn,
      contentFi,
      contentEn,
      validFrom: newStartDate ? moment(newStartDate).toISOString() : newStartDate,
      validUntil: newEndDate ? moment(newEndDate).toISOString() : newEndDate,
      questions,
      draft,
      userLang: userLang || null,
      userTypes: userTypes || null,
      categoryId,
      newPhoto,
      removePhoto,
      video
    })
  }

  const toggleDelete = () => {
    pollStore.updatePoll(pollId, {
      active: !pollStore.poll.active
    })
    closeAlert()
  }

  const renderContent = () => {
    if (tab === 0) {
      // Analytics
      if (!pollStore.poll || !pollStore.pollAnswers || !pollStore.pollFull) return null
      return (
        <PageContent size='medium'>
          <PollResult
            poll={pollStore.poll}
            pollFull={pollStore.pollFull}
            pollAnswers={pollStore.pollAnswers}
            pollViews={pollStore.pollViews}
          />
        </PageContent>
      )
    }
    if (tab === 1) {
      if (!pollStore.pollFull) {
        return null
      }
      return (
        <PageContent size='medium'>
          <UserAnswers
            id={pollStore.pollFull.poll.id}
            devices={pollStore.pollFull.devices}
            answers={pollStore.pollFull.answers}
            path="polls"
          />
        </PageContent>
      )
    }
    return (
      <>
        <PageContent disabled={!get(pollStore.poll, 'active')} size='small'>
          <PollForm
            video={video}
            setVideo={setVideo}
            handleDeleteVideo={handleDeleteVideo}
            photo={photo}
            handleSetPhoto={handleSetPhoto}
            handleRemovePhoto={handleRemovePhoto}
            mediaType={mediaType}
            setMediaType={setMediaType}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            contentFi={contentFi}
            setContentFi={setContentFi}
            contentEn={contentEn}
            setContentEn={setContentEn}
            questions={questions}
            setQuestions={setQuestions}
            userLang={userLang}
            setUserLang={setUserLang}
            userTypes={userTypes}
            setUserTypes={setUserTypes}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            categories={pollStore.pollCategories}
            submitted
          />
        </PageContent>
        <PageActions>
          <PollActions
            startDate={startDate}
            setStartDate={handleSetStartDate}
            startTime={startTime}
            setStartTime={handleSetStartTime}
            endDate={endDate}
            setEndDate={handleSetEndDate}
            endTime={endTime}
            setEndTime={handleSetEndTime}
            draft={draft}
            setDraft={setDraft}
            onSave={updatePoll}
            onCancel={() => window.location.reload()}
            active={get(pollStore, 'poll.active')}
            toggleActive={toggleDelete}
            key={pollStore.poll ? pollStore.poll.id : 0}
          />
        </PageActions>
      </>
    )
  }

  const getTitle = () => {
    if (tab === 0 || tab === 1) {
      return get(pollStore.poll, 'titleFi') || get(pollStore.poll, 'titleEn')
    }
    return t('poll_details')
  }

  const getSubtitle = () => {
    if (tab === 0 || tab === 1) {
      const start = get(pollStore.poll, 'validFrom')
      const end = get(pollStore.poll, 'validUntil')
      if (start || end) {
        return `${start ? moment(start).format('D.M.YYYY') : ''}-${end ? moment(end).format('D.M.YYYY') : ''}`
      }
    }
    return null
  }

  const getExcelButtonAction = () => {
    if (tab === 0) {
      return () => pollStore.exportPoll(pollId)
    }
    return null
  }

  return (
    <>
      <PageHeader
        title={getTitle()}
        subtitle={getSubtitle()}
        backButtonLink='/polls'
        onExcelButtonPress={getExcelButtonAction()}
        tabs={[
          { id: 0, label: t('poll_analytics') },
          { id: 1, label: t('poll_user_answers') },
          { id: 2, label: t('edit_poll') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        {renderContent()}
      </PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={get(pollStore.poll, 'active') ? t('delete_poll_title') : t('activate_poll_title')}
        description={get(pollStore.poll, 'active') ? t('delete_poll_description') : t('activate_poll_description')}
      />
    </>
  )
}

export default observer(Poll)
