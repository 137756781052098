import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class AnalyticsStore {
  rootStore
  analytics = null
  involvementAnalytics = null
  loading = null

  // Analytics v2
  activeUsers = null
  screenViews = null
  screenViewsWithMobileUserTypes = null
  previousScreenViews = null
  screenViewActiveUsers = null
  appVersions = null
  fullDimensionalScreenViews = null
  demographics28d = null
  feedbackSums = null
  // custom events (city announcements, coupon views etc.)
  customEvents = null

  activeDeviceDistribution = {}

  bundledNotificationViews = []

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getAnalytics() {
    this.loading = true
    const response = await Api.getAnalytics()
    if (response.ok) {
      this.analytics = response.data
    }
    this.loading = false
  }

  async getInvolvementAnalytics() {
    this.loading = true
    const response = await Api.getInvolvementAnalytics()
    if (response.ok) {
      this.involvementAnalytics = response.data
    }
    this.loading = false
  }

  async getActiveUsers(startDate, endDate) {
    this.loading = true
    const response: any = await Api.getActiveUsersAnalytics(startDate, endDate)
    if (response.ok) {
      this.activeUsers = response.data.data.activeUsers
    }
    this.loading = false
  }

  async getUsageSummary(startDate, endDate) {
    this.loading = true
    const response: any = await Api.getUsageSummaryAnalytics(startDate, endDate)
    if (response.ok) {
      const { screenViews, screenViewsWithMobileUserTypes, previousScreenViews, screenViewActiveUsers, appVersions } = response.data.data

      this.screenViews = screenViews
      this.screenViewsWithMobileUserTypes = screenViewsWithMobileUserTypes
      this.previousScreenViews = previousScreenViews
      this.screenViewActiveUsers = screenViewActiveUsers
      this.appVersions = appVersions
    }
    this.loading = false
  }

  async getFullDimensionalScreenViews(startDate, endDate) {
    this.loading = true
    const response: any = await Api.getFullDimensionalScreenViewsAnalytics(startDate, endDate)
    if (response.ok) {
      this.fullDimensionalScreenViews = response.data.data.fullDimensionalScreenViews
    }
    this.loading = false
  }

  async getDemographics28d(date) {
    this.loading = true
    const response: any = await Api.getDemographics28d(date)
    if (response.ok) {
      this.demographics28d = response.data.data.demographics28d
    }
    this.loading = false
  }

  async getFeedbackSums(startDate, endDate) {
    this.loading = true
    const response: any = await Api.getFeedbackSumsAnalytics(startDate, endDate)
    if (response.ok) {
      this.feedbackSums = response.data
    }
    this.loading = false
  }

  async getCustomEvents(startDate, endDate) {
    this.loading = true
    const response: any = await Api.getCustomEvents(startDate, endDate)
    if (response.ok) {
      this.customEvents = response.data.data.customEvents
    }
    this.loading = false
  }

  async getActiveDeviceDistribution() {
    this.loading = true
    const response = await Api.getDeviceYearMonthDistributions()
    if (response.ok) {
      this.activeDeviceDistribution = response.data
    }

    this.loading = false
  }

  async getBundledNotificationViews(pollIds: number) {
    this.loading = true
    const response = await Api.getBundledNotificationViews(pollIds)
    if (response.ok) {
      this.bundledNotificationViews = response.data
    }
    this.loading = false
  }
}
