import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, DataActions, CouponCategoryTable, PageContainer, PageContent, CouponCategoryForm } from '../../../Components'
import { UserSortOptions } from '../../../Constants'
import { makeStyles } from '@material-ui/core'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  innerLeft: {
    flex: 5
  },
  innerRight: {
    flex: 3
  },
  innerRightOffset: {
    position: 'relative',
    paddingLeft: '6rem',
    marginTop: '-5rem'
  },
  hr: {
    position: 'absolute',
    left: '3rem',
    top: '-1.75rem',
    width: 1,
    minHeight: 'calc(100vh - 4rem)',
    backgroundColor: Colors.lightBorder
  }
}))

function CouponCategories(props) {
  const { t } = useTranslation()
  const { couponStore } = useStore()
  const classes = useStyles()

  useEffect(() => {
    couponStore.getCategories()
  }, [])

  const [couponCategoryEditId, setCouponCategoryEditId] = useState(null)

  const handeDeleteCouponCategory = (id) => {
    couponStore.deleteCategory(id)
  }

  const handleSaveCouponCategory = (category) => {
    if (couponCategoryEditId) {
      // Edit existing
      couponStore.updateCategory(couponCategoryEditId, category)
    } else {
      // Create new
      couponStore.createCategory(category)
    }
  }

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  /* Categories */
  const getCategories = () => {
    let categories = couponStore.categories
    if (searchValue) {
      categories = categories.filter(org => {
        const { titleFi, titleEn } = org
        const targets = [titleFi, titleEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      categories = orderBy(categories, 'createdAt', 'desc')
    } else {
      categories = orderBy(categories, 'titleFi', 'asc')
    }
    return categories
  }

  const renderTable = () => {
    return (
      <div className={classes.innerContainer}>
        <div className={classes.innerLeft}>
          <CouponCategoryTable
            items={getCategories()}
            onEdit={setCouponCategoryEditId}
            onDelete={handeDeleteCouponCategory}
          />
        </div>
        <div className={classes.innerRight}>
          <div className={classes.innerRightOffset}>
            <div className={classes.hr} />
            <CouponCategoryForm
              categories={couponStore.categories}
              editId={couponCategoryEditId}
              setEditId={setCouponCategoryEditId}
              onSave={handleSaveCouponCategory}
            />
          </div>

        </div>
      </div>
    )
  }

  const renderDataActions = () => {
    return (
      <div className={classes.innerContainer}>
        <div className={classes.innerLeft}>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
        </div>
        <div className={classes.innerRight} />
      </div>
    )
  }

  return (
    <>
      <PageHeader
        title={t('categories')}
      />
      <PageContainer>
        <PageContent>
          {renderDataActions()}
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(CouponCategories))
