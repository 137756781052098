import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class UserStore {
  rootStore
  users = []
  userInvites = []
  user = null
  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getMyOrganizationUsers() {
    this.loading = true
    const response: any = await Api.getMyOrganizationUsers()
    if (response.ok) {
      this.users = response.data
    }
    this.loading = false
  }

  async getOrganizationsUsers(id) {
    this.loading = true
    this.loading = false
  }

  async getUser(id) {
    this.loading = true
    this.user = null
    const response: any = await Api.getMyOrganizationUser(id)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.loading = false
    if (response.ok) {
      this.user = response.data
    }
  }

  async updateUser(id, data) {
    this.loading = true
    const response: any = await Api.updateUser(id, data)
    if (response.ok) {
      this.user = response.data
    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    this.loading = false
  }

  async getUserInvites() {
    this.loading = true
    const response: any = await Api.getUserInvites()

    this.loading = false
    if (response.ok) {
      this.userInvites = response.data
    }
  }

  async inviteUser(data) {
    this.loading = true
    const response: any = await Api.inviteUser(data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'invite_sent')
    this.loading = false
    if (response.ok) {
      // Reload invites
      this.getUserInvites()
    }
  }

  async verifyUserInvite(data) {
    this.loading = true
    const response: any = await Api.verifyUserInvite(data)
    if (response.ok) {
      // TODO: Redirect to login and display some message?

    }
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.loading = false
  }

  async deactivateUser(id) {
    this.loading = true
    const response: any = await Api.deactivateUser(id)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'user_deactivated')
    this.loading = false
  }
}
