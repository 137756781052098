import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import linkifyHtml from 'linkify-html'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, PageContainer, PageContent, PageActions, CouponCampaignForm, CouponCampaignActions } from '../../../Components'
import { validateCouponCampaign } from '../../../Utils/couponCampaignValidation'

const CreateCouponCampaign = (props) => {
  const { couponStore, couponCampaignStore, appStore } = useStore()

  useEffect(() => {
    couponStore.getOrganizations()
  }, [])

  useEffect(() => {
    if (couponCampaignStore.createOk) {
      props.history.push('/campaigns')
    }
  }, [couponCampaignStore.createOk])

  const [nameFi, setNameFi] = useState(null)
  const [nameEn, setNameEn] = useState(null)
  const [numRequiredCodes, setNumRequiredCodes] = useState(null)
  const [campaignType, setCampaignType] = useState('no_prize') // WAS: physical
  const [lotteryLink, setLotteryLink] = useState('')
  const [mobileUserType, setMobileUserType] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [htmlFi, setHtmlFi] = useState(null)
  const [htmlEn, setHtmlEn] = useState(null)

  const [notificationTitleFi, setNotificationTitleFi] = useState(null)
  const [notificationTitleEn, setNotificationTitleEn] = useState(null)
  const [notificationHtmlFi, setNotificationHtmlFi] = useState(null)
  const [notificationHtmlEn, setNotificationHtmlEn] = useState(null)

  const [prizeTitleFi, setPrizeTitleFi] = useState(null)
  const [prizeTitleEn, setPrizeTitleEn] = useState(null)
  const [prizeContentFi, setPrizeContentFi] = useState(null)
  const [prizeContentEn, setPrizeContentEn] = useState(null)

  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)

  const [endDate, setEndDate] = useState(null)
  const [endTime, setEndTime] = useState(null)

  const [disableEnglish, setDisableEnglish] = useState(false)

  const [draft, setDraft] = useState(false)

  const [organizationIds, setOrganizationIds] = useState([])

  const [submitted, setSubmitted] = useState(false)

  const { t } = useTranslation()

  const toggleDisableEnglish = () => setDisableEnglish(!disableEnglish)

  const handleSetStartDate = (val) => setStartDate(val)
  const handleSetStartTime = (val) => setStartTime(val)
  const handleSetEndDate = (val) => setEndDate(val)
  const handleSetEndTime = (val) => setEndTime(val)

  const handleSetNewPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
    }
  }

  const handleRemovePhoto = (photo) => {
    setNewPhoto(null)
    setPhoto(null)
  }

  const createCouponCampaign = () => {
    setSubmitted(true)

    let newStartDate = null
    if (!draft && startDate) {
      newStartDate = moment(startDate).startOf('day').format()
      if (startTime) {
        const timeMoment = moment(startTime, 'HH:mm')
        newStartDate = moment(newStartDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    let newEndDate = null
    if (endDate) {
      newEndDate = moment(endDate).endOf('day').format()
      if (endTime) {
        const timeMoment = moment(endTime, 'HH:mm')
        newEndDate = moment(newEndDate).hours(timeMoment.hours()).minutes(timeMoment.minutes())
      }
    }

    const newHtmlFi = htmlFi ? linkifyHtml(draftToHtml(convertToRaw(htmlFi.getCurrentContent()))) : null
    const newHtmlEn = htmlEn ? linkifyHtml(draftToHtml(convertToRaw(htmlEn.getCurrentContent()))) : null
    const newNotificationHtmlFi = notificationHtmlFi ? linkifyHtml(draftToHtml(convertToRaw(notificationHtmlFi.getCurrentContent()))) : null
    const newNotificationHtmlEn = notificationHtmlEn ? linkifyHtml(draftToHtml(convertToRaw(notificationHtmlEn.getCurrentContent()))) : null

    const couponCampaign = {
      nameFi,
      nameEn,
      numRequiredCodes,
      campaignType,
      lotteryLink: (lotteryLink && lotteryLink.trim().length) ? lotteryLink.trim() : null,
      mobileUserType,
      newPhoto,
      titleFi,
      titleEn,
      htmlFi: newHtmlFi,
      htmlEn: newHtmlEn,
      notificationTitleFi,
      notificationTitleEn,
      notificationHtmlFi: newNotificationHtmlFi,
      notificationHtmlEn: newNotificationHtmlEn,
      prizeTitleFi,
      prizeTitleEn,
      prizeContentFi,
      prizeContentEn,
      startDate: newStartDate ? newStartDate.toISOString() : null,
      endDate: newEndDate ? newEndDate.toISOString() : null,
      organizationIds,
      draft
    }

    // TODO: Remove english fields if english is disabled
    if (disableEnglish) {
      const allKeys = Object.keys(couponCampaign)
      for (const key of allKeys) {
        if (key.slice(-2) === 'En') {
          couponCampaign[key] = null
        }
      }
    }

    // Validation
    if (!draft && ((!startDate !== !startTime) || (!endDate !== !endTime))) {
      return appStore.setError('fill_all_required_fields')
    }
    const validationError = validateCouponCampaign(couponCampaign, disableEnglish)
    if (validationError) {
      return appStore.setError(validationError)
    }

    couponCampaignStore.createCouponCampaign(couponCampaign)
  }

  return (
    <>
      <PageHeader
        title={t('add_new_coupon_campaign')}
        backButtonLink='/campaigns'
      />
      <PageContainer>
        <PageContent size='medium'>
          <CouponCampaignForm
            nameFi={nameFi}
            setNameFi={setNameFi}
            nameEn={nameEn}
            setNameEn={setNameEn}
            numRequiredCodes={numRequiredCodes}
            setNumRequiredCodes={setNumRequiredCodes}
            lotteryLink={lotteryLink}
            setLotteryLink={setLotteryLink}
            mobileUserType={mobileUserType}
            setMobileUserType={setMobileUserType}
            photo={photo}
            setPhoto={handleSetNewPhoto}
            removePhoto={handleRemovePhoto}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            htmlFi={htmlFi}
            setHtmlFi={setHtmlFi}
            htmlEn={htmlEn}
            setHtmlEn={setHtmlEn}
            notificationTitleFi={notificationTitleFi}
            setNotificationTitleFi={setNotificationTitleFi}
            notificationTitleEn={notificationTitleEn}
            setNotificationTitleEn={setNotificationTitleEn}
            notificationHtmlFi={notificationHtmlFi}
            setNotificationHtmlFi={setNotificationHtmlFi}
            notificationHtmlEn={notificationHtmlEn}
            setNotificationHtmlEn={setNotificationHtmlEn}
            prizeTitleFi={prizeTitleFi}
            setPrizeTitleFi={setPrizeTitleFi}
            prizeTitleEn={prizeTitleEn}
            setPrizeTitleEn={setPrizeTitleEn}
            prizeContentFi={prizeContentFi}
            setPrizeContentFi={setPrizeContentFi}
            prizeContentEn={prizeContentEn}
            setPrizeContentEn={setPrizeContentEn}
            organizationIds={organizationIds}
            setOrganizationIds={setOrganizationIds}
            organizations={couponStore.organizations}
            disableEnglish={disableEnglish}
            submitted={submitted}
            type='create'
          />
        </PageContent>
        <PageActions>
          <CouponCampaignActions
            startDate={startDate}
            setStartDate={handleSetStartDate}
            startTime={startTime}
            setStartTime={handleSetStartTime}
            endDate={endDate}
            setEndDate={handleSetEndDate}
            endTime={endTime}
            setEndTime={handleSetEndTime}
            draft={draft}
            setDraft={setDraft}
            disableEnglish={disableEnglish}
            toggleDisableEnglish={toggleDisableEnglish}
            onSave={createCouponCampaign}
            onCancel={() => window.location.reload()}
            submitted={submitted}
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCouponCampaign)
