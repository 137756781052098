import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { Title, CouponForm, PageHeader, Button, PageContainer, PageContent, PageActions, CouponSummary } from '../../../Components'

const CreateCoupon = (props) => {
  const { couponStore } = useStore()

  useEffect(() => {
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getMainEntities() // Load jana main entities

    if (
      couponStore.copyFields &&
      couponStore.copyFields.copySetAt &&
      couponStore.copyFields.copySetAt > Date.now() - 1000
    ) {
      setTitleFi(couponStore.copyFields.titleFi)
      setTitleEn(couponStore.copyFields.titleEn)
      setContentFi(couponStore.copyFields.contentFi)
      setContentEn(couponStore.copyFields.contentEn)
      setUseTimes(couponStore.copyFields.useTimes)
      setCategoryIds(couponStore.copyFields.categoryIds)
      setValidFrom(couponStore.copyFields.validFrom)
      setValidUntil(couponStore.copyFields.validUntil)
      setOrganizationId(couponStore.copyFields.organizationId)
    }

  }, [])

  useEffect(() => {
    if (couponStore.createOk) {
      props.history.push('/coupons')
    }
  }, [couponStore.createOk])

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)
  const [useTimes, setUseTimes] = useState(0)

  const [validFrom, setValidFrom] = useState(moment().format('YYYY-MM-DD'))
  const [validUntil, setValidUntil] = useState(moment().add(31, 'days').endOf('year').format('YYYY-MM-DD'))
  const [organizationId, setOrganizationId] = useState(null)
  const [categoryIds, setCategoryIds] = useState([])
  const [mainEntityId, setMainEntityId] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)

  const [submitted, setSubmitted] = useState(false)

  const { t } = useTranslation()

  const handleSetValidFrom = (val) => setValidFrom(val)

  const handleSetValidUntil = (val) => setValidUntil(val)

  const handleSetMainEntityId = (id) => {
    setMainEntityId(id)

    if (validFrom === moment().format('YYYY-MM-DD') && validUntil === moment().add(31, 'days').endOf('year').format('YYYY-MM-DD')) {
      const mainEntity = couponStore.mainEntities.find(item => item.id === id)
      const start = get(mainEntity, 'fields.event_start_date')
      const end = get(mainEntity, 'fields.event_end_date')
      // Set times from event dates
      if (start) {
        handleSetValidFrom(moment(start).format('YYYY-MM-DD'))
      }
      if (end) {
        handleSetValidUntil(moment(end).format('YYYY-MM-DD'))
      }
    }
  }

  const handleSetNewPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
    }
  }

  const handleRemovePhoto = (photo) => {
    setNewPhoto(null)
    setPhoto(null)
  }

  const createCoupon = () => {
    setSubmitted(true)

    couponStore.createCoupon({
      titleFi,
      titleEn,
      contentFi,
      contentEn,
      useTimes,
      validFrom: validFrom ? moment(validFrom).startOf('day').toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).endOf('day').toISOString() : validUntil,
      categoryId: categoryIds.length ? categoryIds[0] : null,
      categoryIds,
      organizationId,
      mainEntityId,
      newPhoto
    })
  }

  return (
    <>
      <PageHeader
        title={t('add_new_coupon')}
        backButtonLink='/coupons'
      />
      <PageContainer>
        <PageContent size='small'>
          <Title title={t('coupon_basic_details')} />
          <CouponForm
            mainEntities={couponStore.mainEntities}
            setMainEntityId={handleSetMainEntityId}
            mainEntityId={mainEntityId}
            validFrom={validFrom}
            setValidFrom={handleSetValidFrom}
            validUntil={validUntil}
            setValidUntil={handleSetValidUntil}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            contentEn={contentEn}
            setContentEn={setContentEn}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            contentFi={contentFi}
            setContentFi={setContentFi}
            organizations={couponStore.organizations}
            categories={couponStore.categories}
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
            categoryIds={categoryIds}
            setCategoryIds={setCategoryIds}
            useTimes={useTimes}
            setUseTimes={setUseTimes}
            photo={photo}
            setPhoto={handleSetNewPhoto}
            removePhoto={handleRemovePhoto}
            submitted={submitted}
            mode='create'
          />
        </PageContent>
        <PageActions>
          <Button
            text={t('create_coupon')}
            onClick={createCoupon}
            margin
            fullWidth
          />
          <CouponSummary
            titleFi={titleFi}
            titleEn={titleEn}
            contentFi={contentFi}
            contentEn={contentEn}
            useTimes={useTimes}
            validFrom={validFrom}
            validUntil={validUntil}
            organizationId={organizationId}
            organizations={couponStore.organizations}
            categoryIds={categoryIds}
            categories={couponStore.categories}
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCoupon)
