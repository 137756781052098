import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const PageContainer = (props) => {
  const classes = useStyles()

  useEffect(() => {
    // Scroll to top on mount
    document.getElementById('app-content').scrollTop = 0
  }, [])

  return (
    <div className={classes.container}>
      {props.children}
    </div>
  )
}

export default PageContainer
