import React, { useState } from 'react'
import { EditorState } from 'draft-js'
import { makeStyles } from '@material-ui/core'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg'
import { Colors, Fonts } from '../../Utils/theme'

export const CUSTOM_TOOLBAR_SETTINGS = {
  options: ['inline', 'blockType', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline']
  },
  list: {
    options: ['ordered', 'unordered']
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3'],
  }
}

const useStyles = makeStyles((theme) => ({
  editor: {
    backgroundColor: Colors.inputGrey,
    padding: ' 0 1rem 1rem',
    border: `1px solid ${Colors.lightBorder}`,
    minHeight: '14rem',
    borderBottomLeftRadius: '.1875rem',
    borderBottomRightRadius: '.1875rem',
    marginBottom: '1.5rem',
    ':hover': {
      backgroundColor: Colors.white,
      border: `1px solid ${Colors.black}`,
    }
  },
  editorError: {
    border: `1px solid ${Colors.red}`,
  },
  editorFocused: {
    backgroundColor: Colors.white,
    border: `2px solid ${Colors.black}`,
    borderTopLeftRadius: '.1875rem',
    borderTopRightRadius: '.1875rem',
  },
  inputLabel: {
    fontFamily: Fonts.content,
    color: Colors.content,
    fontSize: '1.125rem',
    marginBottom: '.25rem'
  },
  validationHelperText: {
    color: Colors.red,
    marginTop: '-1.25rem',
    marginBottom: '1rem',
    paddingLeft: '.875rem',
    fontSize: '.775rem'
  }
}))

export default function EditorInput(props) {
  const [focus, setFocus] = useState(false)

  const editor = React.useRef(null)
  const classes = useStyles()

  const renderValidationError = () => {
    if (props.error && props.helperText) {
      return <div className={classes.validationHelperText}>{props.helperText}</div>
    }
  }

  const getEditorStyles = () => {
    const activeClasses = [classes.editor]
    if (props.error) {
      activeClasses.push(classes.editorError)
    }
    if (focus) {
      activeClasses.push(classes.editorFocused)
    }
    return activeClasses.join(' ')
  }

  return (
    <div>
      {!!props.label && <div className={classes.inputLabel}>{props.label}</div>}
      <Editor
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        ref={editor}
        placeholder={props.placeholder}
        editorState={props.value || EditorState.createEmpty()}
        onEditorStateChange={props.onChange}
        toolbar={CUSTOM_TOOLBAR_SETTINGS}
        editorClassName={getEditorStyles()}
        stripPastedStyles
      />
      {renderValidationError()}
    </div>
  )
}
