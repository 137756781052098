import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from '../../../Models/RootStore';
import { PageHeader, PageContainer, PageContent } from '../../../Components';
import TestUserAnswerResults from '../../../Components/Test/TestUserAnswerResults';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
}));

const TestUserAnswer = () => {
  const classes = useStyles();
  const { testStore } = useStore();
  const { testId, userId }: any = useParams();
  const history = useHistory();

  useEffect(() => {
    testStore.getTest(testId);
    // Note: device indexes are zero indexed
    testStore.getTestUserAnswers(Number(testId), (Number(userId) || 0) - 1);
    testStore.getTestResults(Number(testId), Number(userId || 0) -1)
  }, []);

  const onRemove = async () => {
    await testStore.removeUserAnswers(Number(testId), Number(userId || 0) -1)
    history.push('/tests')
  }

  const renderContent = () => {
    return (
      <div className={classes.container}>
        <TestUserAnswerResults
          result={testStore.testAnswerResults[testId]?.[Number(userId || 0) -1]}
          totalAnswers={testStore.test.totalAnswers}
          userAnswers={testStore.testUserAnswers || []}
          totalViews={testStore.test.totalViews}
          groups={testStore.test.groups}
          remove={onRemove}
        />
      </div>
    );
  };

  const getTitle = () =>
    testStore.test?.titleFi || testStore.test?.titleEn || null;

  return (
    <>
      <PageHeader title={getTitle()} backButtonLink={`/tests/${testId}`} />
      <PageContainer>
        <PageContent size="medium">{renderContent()}</PageContent>
      </PageContainer>
    </>
  );
};

export default observer(TestUserAnswer);
