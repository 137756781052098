import React, { useState } from 'react'
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react'
import { GOOGLE_MAPS_API_KEY, CITY_LOCATION } from '../../Constants'

const CustomMap = (props) => {
  const [activeMarker, setActiveMarker] = useState({})
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState({})

  const onMarkerClick = (p, marker, e) => {
    setSelectedPlace(p)
    setActiveMarker(marker)
    setShowInfoWindow(true)
  }

  const onMapClicked = (p) => {
    if (showInfoWindow) {
      setShowInfoWindow(false)
      setActiveMarker({})
    }
  }

  const renderPoints = () => {
    if (!props.points) return null
    return props.points.map(point => {
      return (
        <Marker
          title={point.title}
          name={point.title}
          position={{ lat: point.lat, lng: point.lng }}
          onClick={onMarkerClick}
        />
      )
    })
  }

  return (
    <Map
      google={props.google}
      initialCenter={{
          lat: CITY_LOCATION.latitude,
          lng: CITY_LOCATION.longitude
      }}
      containerStyle={{
        position: 'static',
        width: '100%',
        height: '100%'

      }}
      style={{ width: '100%', height: '60vh', position: 'static' }}
      zoom={12}
      onClick={onMapClicked}
    >
      {renderPoints()}
      <InfoWindow
        marker={activeMarker}
        visible={showInfoWindow}
      >
        <div>
          <p>{selectedPlace.name}</p>
        </div>
        </InfoWindow>
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY
})(CustomMap)
