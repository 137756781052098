import { makeAutoObservable } from 'mobx'
import omit from 'lodash/omit'
import { Api } from '../Services'
import { validatePoll } from '../Utils/validation'

export default class PollStore {
  rootStore

  polls = []
  poll = null
  pollFull: any = null
  pollAnswers = null
  pollUserAnswers = null
  pollViews = null
  loading = null
  createOk = false
  pollCategories = []
  pollCategory = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updatePolls = (polls) => { this.polls = polls }
  setPoll = (poll) => { this.poll = poll }
  setPollFull = (pollFull) => { this.pollFull = pollFull }
  updatePollUserAnswers = (pollUserAnswers) => { this.pollUserAnswers = pollUserAnswers }
  updateLoading = (loading) => { this.loading = loading }

  async getPolls() {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPolls()
      if (response?.ok) {
        const polls = response.data
        this.updatePolls(polls)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)

  }

  async getPoll(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPoll(id)
      if (response?.ok) {
        const poll = response.data
        this.setPoll(poll)
      }
    } catch (e) {
      console.log(e)
    }
    this.updateLoading(false)
  }

  async getPollFull(id) {
    this.updateLoading(true)
    try {
      const response: any = await Api.getPollFull(id)
      if (response?.ok) {
        const poll = response.data
        this.setPollFull(poll)
      }
    } catch (e) {
      console.log(e)
    }

    this.updateLoading(false)
  }

  async getPollUserAnswers(pollId, userId) {
    let answers: any = []

    // Check if we already have the data
    if (this.pollFull && this.pollFull.poll.id === pollId) {
      answers = this.pollFull.answers
    } else {
      // Fetch new data
      const response: any = await Api.getPollFull(pollId)
      if (response.ok) {
        answers = response.data.answers
      }
    }
    const pollUserAnswers = answers.filter(ans => ans.device && ans.device.index === userId)
    this.updatePollUserAnswers(pollUserAnswers)
  }

  async getPollAnswers(id) {
    this.loading = true
    const response: any = await Api.getPollAnswers(id)
    this.loading = false
    if (response.ok) {
      this.pollAnswers = response.data
    }
  }

  async updatePoll(id, poll) {
    const validationError = validatePoll(poll)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true
    const pollData = omit(poll, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (poll.newPhoto && !poll.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(poll.newPhoto)
      if (photoResponse.ok) {
        pollData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (poll.removePhoto) {
      // Remove photo
      pollData.photo = null
    }

    const response: any = await Api.updatePoll(id, pollData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.poll = response.data
      const newPolls = this.polls.map(p => {
        if (p.id === id) return response.data
        return p
      })
      this.updatePolls(newPolls)
    }
  }

  async createPoll(poll) {
    this.createOk = false

    const validationError = validatePoll(poll)
    if (validationError) {
      this.rootStore.appStore.setErrorOrSuccess({ ok: false }, validationError, false)
      return
    }

    this.loading = true

    const pollData = omit(poll, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (poll.newPhoto) {
      const photoResponse: any = await Api.uploadMedia(poll.newPhoto)
      if (photoResponse.ok) {
        pollData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    }


    const response: any = await Api.createPoll(pollData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_created')
    if (response.ok) {
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async exportPoll(id) {
    Api.getPollExport(id)
  }

  async getPollViews(pollId) {
    this.pollViews = null
    this.loading = true
    const response: any = await Api.getPollViewsAnalytics(pollId)
    this.loading = false
    if (response.ok) {
      this.pollViews = response.data.data.pollViews.count
    }
  }

  async getPollCategories() {
    this.loading = true
    const response = await Api.getPollCategories()
    this.loading = false
    if (response.ok) {
      this.pollCategories = response.data
    }
  }

  async getPollCategory(categoryId) {
    this.loading = true
    const response = await Api.getPollCategory(categoryId)
    this.loading = false
    if (response.ok) {
      this.pollCategory = response.data
    }
  }

  async createPollCategory(pollCategory) {
    this.createOk = false

    this.loading = true
    const response: any = await Api.createPollCategory(pollCategory)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_category_created')
    if (response.ok) {
      this.getPollCategories()
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async updatePollCategory(categoryId, pollCategory) {
    this.loading = true
    const response: any = await Api.updatePollCategory(categoryId, pollCategory)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_saved')
    if (response.ok) {
      this.pollCategory = response.data
      this.getPollCategories()
    }
  }

  async deletePollCategory(categoryId) {
    this.loading = true
    const response: any = await Api.deletePollCategory(categoryId)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'poll_category_deleted')
    if (response.ok) {
      this.pollCategory = null
      this.getPollCategories()
    }
  }
}
