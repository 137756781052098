import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  spacer: {
    width: '2rem'
  },
  box: {
    borderRadius: '.3125rem',
    flex: 1,
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1.5rem 1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '1.125rem',
    color: Colors.boxTitle,
    lineHeight: 1,
    marginBottom: '.75rem'
  },
  value: {
    textAlign: 'center',
    fontSize: '3rem',
    color: Colors.brandColor2,
    fontWeight: 700,
    lineHeight: 1
  }
}))

function CouponUseSummary(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const spacePadInteger = (number) => {
    const result = `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    if (result === 'undefined') return '-'
    return result
  }

  const formatRatio = (ratio) => {
    return `${Math.round(ratio * 1000) / 10} %`
  }

  const renderBoxes = () => {
    const data = [
      {
        title: t('coupon_view_count'),
        value: spacePadInteger(props.couponViews)
      },
      {
        title: t('coupon_use_counts'),
        value: spacePadInteger(props.couponUses)
      },
      {
        title: t('coupon_conversion'),
        value: (props.couponViews && props.couponUses) ? formatRatio(props.couponUses / props.couponViews) : '-'
      }
    ]

    return data.map((row, index) => {
      return (
        <>
          {!!index && (<div className={classes.spacer} />)}
          <div className={classes.box}>
            <div className={classes.title}>
              {row.title}
            </div>
            <div className={classes.value}>
              {row.value}
            </div>
          </div>
        </>
      )
    })
  }

  return (
    <div className={classes.container}>
      {renderBoxes()}
    </div>
  )
}

export default CouponUseSummary

