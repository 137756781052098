import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: '1.5rem',
    opacity: 0.8
  },
  select: {
    width: '100%',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1.125rem',
    color: Colors.black,
    backgroundColor: Colors.white,
    border: '2px solid #ccc',
    borderRadius: 5,
    padding: '0 0 0 .75rem',
    '&:focus': {
      backgroundColor: Colors.white
    }
  },
  selectSmall: {
    height: '3.125rem'
  },
  whiteBackground: {
    backgroundColor: Colors.white
  },
  item: {
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.125rem',
    color: Colors.black
  },
  inputLabel: {
    fontFamily: Fonts.content,
    color: Colors.content,
    fontSize: '1.125rem',
    marginBottom: '.25rem'
  },
  disabledLabel: {
    opacity: 0.5
  },
  input: {
    borderWidth: 0
  },
  disabledInput: {
    backgroundColor: Colors.inputGrey,
    opacity: '0.5 !important',
  },
  disabledSelectContainer: {
    opacity: 0.5
  }
}))

export default function MiniSelect(props) {
  const classes = useStyles()

  const onChange = (evt) => {
    props.onChange(evt.target.value)
  }

  const renderHelperText = () => {
    if (props.helperText) {
      return <FormHelperText>{props.helperText}</FormHelperText>
    }
    return null
  }

  const getInputStyle = () => {
    const activeClasses = [classes.select]
    if (props.whiteBackground) activeClasses.push(classes.whiteBackground)
    if (props.small) activeClasses.push(classes.selectSmall)
    //if (props.disabled) activeClasses.push(classes.disabledInput)
    return activeClasses.join(' ')
  }

  const getSelectStyle = () => {
    const activeClasses = []
    if (props.disabled) {
      activeClasses.push(classes.disabledInput)
    }
    return !activeClasses.length ? '' : activeClasses.join(' ')
  }

  const getLabelStyle = () => {
    const activeClasses = [classes.inputLabel]
    if (props.disabled) {
      activeClasses.push(classes.disabledLabel)
    }
    return activeClasses.join(' ')
  }

  return (
    <div>
      <FormControl variant='outlined' className={classes.container}>
        {!!props.label && <div className={getLabelStyle()}>{props.label}</div>}
        <Select
          labelId='select-label'
          id='select-outlined'
          value={props.value || 0}
          onChange={onChange}
          classes={{
            root: getInputStyle(),
            select: getSelectStyle(),
            input: classes.input
          }}
          disabled={props.disabled}
          error={props.error}
        >
          {props.options.map((option) => (
            <MenuItem key={option.value} value={option.value} classes={{ root: classes.item }}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {renderHelperText()}
      </FormControl>
    </div>
  )
}
