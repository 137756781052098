import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog } from '../../Components'
import closeIcon from '../../Assets/Icons/close.svg'
import AddIcon from '../../Assets/Icons/add-dark.svg'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '2rem'
  },
  addButton: {
    width: '12rem'
  },
  addButtonCompact: {
    height: '2.5rem',
    width: '8.125rem'
  },
  changeButtonText: {
    fontSize: '1rem',
    fontWeight: 400
  },
  photoContainer: {
    padding: '.5rem',
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: '0.3125rem',
    position: 'relative'
  },
  photoInnerContainer: {
    width: '100%',
    overflow: 'hidden',
    margin: 0,
    paddingTop: '56.25%',
    position: 'relative',
  },
  photo: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  emptyPhoto: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: Colors.white
  },
  removePhoto: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    bottom: '1.5rem',
    right: '1rem',
    backgroundColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.black
    }
  },
  closeIcon: {
    height: '1.5rem',
    width: 'auto',
    maxHeight: '1.5rem',
    maxWidth: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    flex: 2
  },
  rightColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    paddingLeft: '1.125rem'
  },
  description: {
    textAlign: 'center',
    paddingBottom: '2rem'
  }
}))

export default function LargeImageUpload(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [alertVisible, setAlertVisible] = useState(false)

  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const removePhoto = () => {
    setAlertVisible(false)
    props.removePhoto()
  }

  const { getInputProps, open } = useDropzone({
    accept: 'image/*',
    multiple: false,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    onDrop: props.onFileUpload
  })

  const renderPhoto = () => {
    if (props.photo) {
      return (
        <div className={classes.photoInnerContainer}>
          <div
            style={{ backgroundImage: `url(${props.photo}` }}
            className={classes.photo}
          />
        </div>
      )
    }
    return (
      <div className={classes.photoInnerContainer}>
        <div className={classes.emptyPhoto}>
          {renderAddPhotoButton()}
        </div>
      </div>
    )
  }

  const renderAddPhotoButton = () => {
    return (
      <Button
        text={t('add_photo')}
        onClick={open}
        disabled={props.disabled}
        buttonStyle={classes.addButton}
        rightIcon={AddIcon}
        secondary
      />
    )
  }

  const renderChangePhotoButton = () => {
    if (props.photo) {
      return (
        <Button
          text={t('change_photo')}
          onClick={open}
          disabled={props.disabled}
          buttonStyle={classes.addButton}
          buttonTextStyle={classes.changeButtonText}
          secondary
        />
      )
    }
    return null
  }

  const renderRemovePhoto = () => {
    if (props.photo && props.removePhoto && !props.disabled) {
      return (
        <>
          <IconButton
            onClick={openAlert}
            className={classes.removePhoto}
            disabled={props.disabled}
          >
            <img
              src={closeIcon}
              className={classes.closeIcon}
              alt='icon'
            />
          </IconButton>
          <Dialog
            open={alertVisible}
            handleClose={closeAlert}
            handleOk={removePhoto}
            title={t('delete_photo')}
            description={t('delete_photo_description')}
          />
        </>
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.leftColumn}>
          <input {...getInputProps()} />
          <div className={classes.photoContainer}>
            {renderPhoto()}
            {renderRemovePhoto()}
          </div>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.description}>
            {t('upload_large_image_description_1')}
            <br /><br />
            {t('upload_large_image_description_2')}
          </div>
          {renderChangePhotoButton()}
        </div>
      </div>
    </div>
  )
}
