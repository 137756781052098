import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input, Button } from '../../../Components'
import settings_fi from '../../../Assets/Images/settings_fi.png'
import settings_fi_delete from '../../../Assets/Images/settings_fi_delete.png'
import settings_en from '../../../Assets/Images/settings_en.png'
import settings_en_delete from '../../../Assets/Images/settings_en_delete.png'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '41.25rem'
  },
  backLink: {
    textAlign: 'center',
    display: 'block',
    marginTop: '2rem',
    fontSize: '1.25rem',
    fontWeight: 300
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    display: 'inline-block'
  },
  image: {
    maxWidth: '100%',
    maxHeight: 500,
    minHeight: 200,
    resizeMode: 'contain',
    marginRight: '10px'
  },
  textContainer: {
    marginBottom: '10px',
    marginTop: '10px'
  }
}))

const DeleteAccountInfo = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Title
        title={t('delete_account_info_title')}
        description={t('delete_account_info_description')}
        type='main'
      />
      <div className={classes.textContainer}>
        <p>
          {t('delete_account_info_paragraph_fi')}
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img
          src={settings_fi}
          className={classes.image}
          alt='delete settings'
        />
        <img
          src={settings_fi_delete}
          className={classes.image}
          alt='delete account'
        />
      </div>
      <div className={classes.textContainer}>
        <p>
          {t('delete_account_info_paragraph_en')}
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img
          src={settings_en}
          className={classes.image}
          alt='delete settings'
        />
        <img
          src={settings_en_delete}
          className={classes.image}
          alt='delete account'
        />
      </div>
      <div className={classes.textContainer}>
        <p>
          {t('delete_not_working')}
        </p>
        <a href = "mailto: mobile-app-support@hiondigital.com">mobile-app-support@hiondigital.com</a>
      </div>
    </div>
  );
  
}

export default DeleteAccountInfo
