import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input, Button } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '41.25rem'
  },
  description: {
    marginBottom: '3rem'
  },
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    textAlign: 'center',
    marginTop: '1.5rem'
  },
  link: {
    fontWeight: 300,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

const Login = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore } = useStore()

  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)

  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter' && email && password) {
      login()
    }
  }
  const login = () => {
    sessionStore.login(email, `${password}`)
    // Clear password
    setPassword('')
  }

  return (
    <div className={classes.root}>
      <Title
        title={t('login')}
        description={t('login_description')}
        type='main'
        descriptionStyle={classes.description}
      />
      <Input label={t('email')} value={email} onChange={setEmail} autoFocus />
      <Input
        type='password'
        label={t('password')}
        value={password}
        onChange={setPassword}
        handleKeyPress={handleKeyPress}
      />
      <Button text={t('login_short')} onClick={login} margin fullWidth />
      <p className={classes.text}>
        {t('forgot_password')} {t('request_new')}
        <Link to='/forgot-password' className={classes.link}>{t('from_here')}</Link>.
      </p>
    </div>
  )
}

export default observer(Login)
