import moment from 'moment'

export const mergeNotificationAnalytics = (notifications, activeDeviceDistribution, bundledNotificationViews) => {
  if (!notifications) return notifications

  return notifications.map(notification => {

    const monthKey = moment(notification.publishedAt || notification.createdAt).format('YYYYMM')
    let receiverCount = null
    if (activeDeviceDistribution[monthKey]) {
      receiverCount = activeDeviceDistribution[monthKey]
    }
    let viewCount = null
    const viewMatch = (bundledNotificationViews || []).find(item => item.id === notification.id)
    if (viewMatch) {
      viewCount = viewMatch.count
    }
    return {
      ...notification,
      receiverCount,
      viewCount
    }
  })
}
