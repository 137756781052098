import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Input, Button, RadioSelect, Checkbox } from '../../Components'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: Fonts.heading,
    fontWeight: 400,
    fontSize: '1.5rem',
    padding: 0,
    margin: 0,
    marginBottom: '1rem'
  },
  boxContainer: {
    border: `1px solid ${Colors.lightBorder}`,
    padding: '.75rem 1.5rem 0',
    marginBottom: '1rem',
    borderRadius: '.1875rem'
  },
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row',
  },
  dateTimeLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    paddingLeft: '2rem',
    paddingRight: '.25rem',
    fontSize: '1.125rem',
    minWidth: '4.85rem',
    fontWeight: 300
  },
  dateTimeInput: {
    flex: 1
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  footerSpacer: {
    width: '2rem'
  }
}))

const CouponCampaignActions = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [validForeverSelected, setValidForverSelected] = useState(!props.endDate)
  const [mountedAt] = useState(Date.now())

  useEffect(() => {
    if ((Date.now() - mountedAt) < 1000) {
      if (props.endDate) {
        setValidForverSelected(false)
      } else {
        setValidForverSelected(true)
      }
    }
  }, [props.endDate])


  const handleSetStartDate = (value) => {
    props.setStartDate(value)
  }

  const handleSetStartTime = (value) => {
    props.setStartTime(value)
  }

  const handleSetEndDate = (value) => {
    props.setEndDate(value)
  }

  const handleSetEndTime = (value) => {
    props.setEndTime(value)
  }

  const handleSetStartType = (value) => {
    if (value === getStartTypeValue()) return

    if (value === 'draft') {
      props.setDraft(true)
    } else {
      props.setDraft(false)
      props.setStartDate(null)
      props.setStartTime(null)
    }
  }

  const handleSetEndType = (value) => {
    if (value === getEndTypeValue()) return

    if (value === 'valid_until_further_notice') {
      setValidForverSelected(true)
      props.setEndDate(null)
      props.setEndTime(null)
    } else if (value === 'time') {
      setValidForverSelected(false)
    }
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const getStartTypeOptions = () => [
    {
      value: 'draft',
      label: t('campaign_save_as_draft')
    },
    {
      value: 'time',
      label: t('at_the_selected_time')
    }
  ]

  const getStartTypeValue = () => {
    if (props.draft) return 'draft'
    return 'time'
  }

  const getEndTypeOptions = () => [
    {
      value: 'valid_until_further_notice',
      label: t('valid_until_further_notice')
    },
    {
      value: 'time',
      label: t('ends_on_time')
    }
  ]

  const getEndTypeValue = () => {
    if (validForeverSelected) return 'valid_until_further_notice'
    return 'time'
  }

  const renderStartInput = () => {
    if (getStartTypeValue() === 'time') {
      return (
        <>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>
              {t('date_short')}
            </div>
            <div className={classes.dateTimeInput}>
              <Input
                value={props.startDate}
                onChange={handleSetStartDate}
                type='date'
                {...validationMessage(props.startDate)}
              />
            </div>
          </div>
          <div className={classes.dateTimeContainer}>
          <div className={classes.dateTimeLabel}>
              {t('time_short')}
            </div>
            <div className={classes.dateTimeInput}>
              <Input
                value={props.startTime}
                onChange={handleSetStartTime}
                type='time'
                {...validationMessage(props.startTime)}
              />
            </div>
          </div>
        </>
      )
    }
    return null
  }

  const renderEndInput = () => {
    if (getEndTypeValue() === 'time') {
      return (
        <>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>
              {t('date_short')}
            </div>
            <div className={classes.dateTimeInput}>
              <Input
                value={props.endDate}
                onChange={handleSetEndDate}
                type='date'
                {...validationMessage(props.endDate)}
              />
            </div>
          </div>
          <div className={classes.dateTimeContainer}>
            <div className={classes.dateTimeLabel}>
              {t('time_short')}
            </div>
            <div className={classes.dateTimeInput}>
              <Input
                value={props.endTime}
                onChange={handleSetEndTime}
                type='time'
                {...validationMessage(props.endTime)}
              />
            </div>
          </div>
        </>
      )
    }
    return null
  }

  const renderActivateButton = () => {
    if (props.toggleActive) {
      if (props.active) {
        return (
          <Button
            text={t('deactivate_campaign')}
            onClick={props.toggleActive}
            secondary
            fullWidth
          />
        )
      } else {
        return (
          <Button
            text={t('activate_campaign')}
            onClick={props.toggleActive}
            secondary
            fullWidth
          />
        )
      }
    }
  }

  return (
    <div>
      <h3 className={classes.title}>{t('campaign_starts_at')}</h3>
      <div className={classes.boxContainer}>
        <RadioSelect
          value={getStartTypeValue()}
          options={getStartTypeOptions()}
          onChange={handleSetStartType}
        />
        {renderStartInput()}
      </div>
      <h3 className={classes.title}>{t('campaign_ends_at')}</h3>
      <div className={classes.boxContainer}>
        <RadioSelect
          value={getEndTypeValue()}
          options={getEndTypeOptions()}
          onChange={handleSetEndType}
        />
        {renderEndInput()}
      </div>

      <Checkbox
        label={t('do_not_use_language_versions')}
        checked={props.disableEnglish}
        onChange={props.toggleDisableEnglish}
      />

      <div className={classes.footerContainer}>
        <Button
          text={t('save')}
          onClick={props.onSave}
          margin
          fullWidth
        />
        <div className={classes.footerSpacer} />
        <Button
          text={t('cancel')}
          onClick={props.onCancel}
          margin
          secondary
          variant
        />
      </div>
      {renderActivateButton()}
    </div>
  )
}

export default CouponCampaignActions
