import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles({
  outerContainer: {
    borderBottom: 'none',
    margin: 0,
    padding: 0
  },
  container: {
    borderBottom: 'none',
    margin: 0,
    padding: 0
  },
  cell: {
    height: 40,
    padding: '0 1rem',
    backgroundColor: Colors.white,
    color: Colors.black,
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }
})

function AnalyticsTableHeader (props) {
  const classes = useStyles()

  return (
    <TableHead className={classes.outerContainer}>
      <TableRow className={classes.container}>
        {props.columnNames.map((columnName, index) => {
          let className = classes.cell
          if (index === 0) className = `${classes.cell} ${classes.firstCell}`
          else if (index === props.columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`
          return <TableCell key={index} className={className}>{columnName}</TableCell>
        })}
      </TableRow>
    </TableHead>
  )
}

export default AnalyticsTableHeader
