import { omit } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class CouponStore {
  rootStore
  couponCampaigns = []
  couponCampaign = null
  couponCampaignAnalytics = null
  lotteryLinkOpensCount = null

  loading = null
  createOk = false

  copyFields = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getCouponCampaigns() {
    this.loading = true
    const response: any = await Api.getCouponCampaigns()
    this.loading = false
    if (response.ok) {
      this.couponCampaigns = response.data
    }
  }

  async getCouponCampaignAnalytics(couponCampaignId) {
    this.loading = true

    const response: any = await Api.getCouponCampaignAnalytics(couponCampaignId)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)

    if (response.ok) {
      this.couponCampaignAnalytics = response.data
    } else {
      this.couponCampaignAnalytics = null
    }

    // Load lottery link open counts from analytics2
    const analytics2Response: any = await Api.getCouponCampaignLotteryLinkOpenedCountAnalytics(couponCampaignId)
    this.rootStore.appStore.setErrorOrSuccess(analytics2Response, true, false)
    if (analytics2Response.ok) {
      this.lotteryLinkOpensCount = analytics2Response.data.data.lotteryLinkOpens.count
    } else {
      this.lotteryLinkOpensCount = null
    }

    this.loading = false
  }

  async createCouponCampaign(campaign) {
    this.loading = true

    const campaignData = omit(campaign, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (campaign.newPhoto && !campaign.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(campaign.newPhoto)
      if (photoResponse.ok) {
        campaignData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (campaign.removePhoto) {
      // Remove photo
      campaignData.photo = null
    }

    const response: any = await Api.createCouponCampaign(campaignData)

    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'coupon_campaign_created')
    if (response.ok) {
      this.couponCampaign = response.data
      this.createOk = true
      setTimeout(() => {
        this.createOk = false
      }, 500)
    }
  }

  async getCouponCampaign(couponCampaignId) {
    this.loading = true
    const response: any = await Api.getCouponCampaign(couponCampaignId)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    this.loading = false

    if (response.ok) {
      this.couponCampaign = response.data
    }
  }

  async updateCouponCampaign(couponCampaignId, campaign) {
    this.loading = true

    const campaignData = omit(campaign, ['newPhoto', 'removePhoto'])

    // Upload photo
    if (campaign.newPhoto && !campaign.removePhoto) {
      const photoResponse: any = await Api.uploadMedia(campaign.newPhoto)
      if (photoResponse.ok) {
        campaignData.photo = photoResponse.data.url
      } else {
        // Fail with error message
        this.rootStore.appStore.setErrorOrSuccess(photoResponse, true, false)
        this.loading = false
        return
      }
    } else if (campaign.removePhoto) {
      // Remove photo
      campaignData.photo = null
    }


    const response: any = await Api.updateCouponCampaign(couponCampaignId, campaignData)
    this.loading = false
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')

    if (response.ok) {
      this.couponCampaign = response.data
    }
  }
}
