import i18n from '../Localization'
import users from '../Assets/Icons/user.svg'
import analytics from '../Assets/Icons/analytics.svg'
import test from '../Assets/Icons/test.svg'
import settings from '../Assets/Icons/settings.svg'
import notifications from '../Assets/Icons/notifications.svg'
import feedback from '../Assets/Icons/feedback.svg'
import poll from '../Assets/Icons/poll.svg'
import CampaignIcon from '../Assets/Icons/campaign.svg'
import CompanyIcon from '../Assets/Icons/company.svg'
import CategoryIcon from '../Assets/Icons/category.svg'
import OfferIcon from '../Assets/Icons/offer.svg'
import AddCouponIcon from '../Assets/Icons/add-coupon.svg'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAaQ9pftyJpUQ3C9tx2T-7XyBWsop4rRUc'

export const CITY_LOCATION = {
  latitude: 61.49911,
  longitude: 23.78712,
  radius: 30000
}

export enum Role {
  Admin = 'admin',
  User = 'user'
}
export const ROLES = ['admin', 'user']

export const ACCESS_RIGHTS = ['coupon', 'notification', 'poll', 'influence', 'analytics']

export const AvailableRoutes = (user, sidebarCouponMode) => {
  if (!user || !user.role) return []
  const { role, accessRights } = user

  const accessCheck = (accessRight, data) => {
    if (role === 'admin') return data
    if (accessRights && accessRights.includes(accessRight)) {
      return data
    }
    return null
  }

  const isGeniemAdmin = role === 'admin' && user && user.email.includes('@geniem.com')

  // If coupon user
  if ((isGeniemAdmin && sidebarCouponMode) || (role === 'user' && accessRights && accessRights.includes('coupon'))) {
    return [
      !isGeniemAdmin ? null : { action: 'toggle_coupon', url: '#' },
      { url: '/coupons/add', icon: AddCouponIcon, text: 'add_coupon' },
      { url: '/coupons', icon: OfferIcon, text: 'coupons' },
      { url: '/campaigns', icon: CampaignIcon, text: 'coupon_campaigns' },
      { url: '/coupon-organizations', icon: CompanyIcon, text: 'organizations' },
      { url: '/coupon-categories', icon: CategoryIcon, text: 'categories' },
      accessCheck('analytics', { url: '/analytics', icon: analytics, text: 'analytics' }),
      { url: '/coupon-notifications', icon: notifications, text: 'notifications' },
      { url: '/settings', icon: settings, text: 'settings' }
    ].filter(item => !!item)
  }

  return [
    !isGeniemAdmin ? null : { action: 'toggle_coupon', url: '#' },
    accessCheck('poll', { url: '/polls', icon: poll, text: 'polls' }),
    accessCheck('test', { url: '/tests', icon: test, text: 'tests' }),
    accessCheck('notification', { url: '/notifications', icon: notifications, text: 'notifications' }),
    accessCheck('influence', { url: '/feedbacks', icon: feedback, text: 'feedbacks' }),
    accessCheck('analytics', { url: '/analytics', icon: analytics, text: 'analytics' }),
    accessCheck('admin', { url: '/users', icon: users, text: 'users' }),
    { url: '/settings', icon: settings, text: 'settings' }
  ].filter(item => !!item)
}

export const UserSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const CouponSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' },
  { id: 3, label: i18n.t('sort_by_popularity'), value: 'popularity' }
]

export const OrganizationSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const APP_RELEASE_DATES = [
  {
     date: '2019-02-18',
     name: '1.0.0'
  },
  {
     date: '2019-02-23',
     name: '1.0.1'
  },
  {
     date: '2019-04-18',
     name: '1.1.0'
  },
  {
     date: '2019-05-31',
     name: '2.0.0'
  },
  {
     date: '2019-06-28',
     name: '2.1.0'
  },
  {
     date: '2019-08-09',
     name: '2.2.0'
  },
  {
     date: '2019-10-11',
     name: '2.3.0'
  },
  {
     date: '2019-11-04',
     name: '2.3.1'
  },
  {
     date: '2019-12-22',
     name: '2.4.0'
  },
  {
     date: '2020-01-27',
     name: '2.4.1'
  },
  {
     date: '2020-02-05',
     name: '2.4.2'
  },
  {
     date: '2020-02-21',
     name: '2.5.0'
  },
  {
     date: '2020-06-09',
     name: '3.0.0'
  },
  {
     date: '2020-06-23',
     name: '3.0.1'
  },
  {
     date: '2020-09-22',
     name: '3.0.2'
  },
  {
     date: '2020-11-20',
     name: '4.0.0'
  },
  {
     date: '2020-11-27',
     name: '4.0.1'
  },
  {
     date: '2020-12-08',
     name: '4.0.2'
  },
  {
     date: '2021-01-13',
     name: '4.1.0'
  },
  {
     date: '2021-01-22',
     name: '4.1.1'
  },
  {
     date: '2021-02-05',
     name: '4.2.0'
  },
  {
     date: '2021-02-08',
     name: '4.2.1'
  },
  {
     date: '2021-02-09',
     name: '4.2.2'
  },
  {
     date: '2021-03-19',
     name: '4.3.0'
  },
  {
     date: '2021-03-23',
     name: '4.3.1'
  },
  {
     date: '2021-05-31',
     name: '5.0.0'
  },
  {
     date: '2021-06-04',
     name: '6.0.0'
  },
  {
     date: '2021-06-16',
     name: '6.0.1'
  },
  {
     date: '2021-09-14',
     name: '6.1.0'
  },
  {
     date: '2021-10-08',
     name: '7.0.0'
  },
  {
     date: '2021-10-15',
     name: '7.1.0'
  },
  {
     date: '2021-12-14',
     name: '8.0.0'
  },
  {
     date: '2021-12-16',
     name: '8.0.1'
  },
  {
     date: '2022-01-25',
     name: '8.1.0'
  },
  {
    date: '2022-05-11',
    name: '9.0.0'
  },
  {
    date: '2022-05-14',
    name: '9.0.1'
  },
  {
    date: '2022-08-02',
    name: '9.1.0'
  },
  {
    date: '2022-08-03',
    name: '9.2.0'
  },
  {
    date: '2022-08-30',
    name: '9.3.0'
  },
  {
    date: '2022-09-17',
    name: '9.3.1'
  },
  {
    date: '2022-10-11',
    name: '9.4.0'
  }
]

/**
 * This is for Screen level analytics. Any new screens added to App should be mapped here.
 * If you add new analyticsGroups, remember to also add fi translation  "analytics_group_*"  for it
 */
export const APP_SCREEN_MAPPINGS = [
   {
     group: 'move',
     analyticsGroups: [
       'bus_stop',
       'bus_line'
     ],
     groupName: 'Joukkoliikenne',
     screens: [
        {
          screen: 'NearbyScreen',
          screenName: 'Lähelläsi'
        },
        {
          screen: 'RouteScreen',
          screenName: 'Reittinäkymä'
        },
        {
          screen: 'LissuScreen',
          screenName: 'Liikenteenseuranta'
        },
        {
          screen: 'RouteGuideScreen',
          screenName: 'Hae reittiä'
        },
        {
          screen: 'StopScreen',
          screenName: 'Pysäkki'
        },
        {
          screen: 'SearchScreen',
          screenName: 'Linjat ja pysäkit'
        },
        {
          screen: 'LineStopsScreen',
          screenName: 'Linjan pysäkit'
        },
        {
          screen: 'LineScreen',
          screenName: 'Linjat'
        }
      ]
   },
   {
     group: 'influence',
     analyticsGroups: ['poll'],
     groupName: 'Osallistu ja vaikuta',
     screens: [
        {
          screen: 'PollsScreen',
          screenName: 'Kyselyt',
        },
        {
          screen: 'PollResultsScreen',
          screenName: 'Kyselyn tulokset'
        },
        {
          screen: 'FeedbackScreen',
          screenName: 'Palaute'
        },
        {
          screen: 'FaultReportScreen',
          screenName: 'Vikailmoitukset'
        },
        {
          screen: 'InfluenceInfoScreen',
          screenName: 'Ohjeet'
        }
      ]
   },
   {
     group: 'enjoy',
     analyticsGroups: [
       'visit_event',
       'event',
       'sight',
       'visit_tampere_item',
       'choose_responsibly_item',
       'culture_route_group',
       'culture_route_item',
       'shop_online_item'
     ],
     groupName: 'Tapahtumat ja nähtävyydet',
     screens: [
        {
          screen: 'VisitEventsScreen',
          screenName: 'Menovinkit'
        },
        {
          screen: 'SightScreen',
          screenName: 'Nähtävyys'
        },
        {
          screen: 'MenoEventsScreen',
          screenName: 'Menovinkit'
        },
        {
          screen: 'DymeEventsListScreen',
          screenName: 'Tapahtumat'
        },
        {
          screen: 'DymeEventScreen',
          screenName: 'DymeEventScreen'
        },
        {
          screen: 'DymeEventsScreen',
          screenName: 'Tapahtumat'
        },
        {
          screen: 'SeasonScreen',
          screenName: 'Visit Tampere'
        },
        {
          screen: 'Summer2020Screen',
          screenName: 'Visit Tampere'
        },
        {
          screen: 'SightsListScreen',
          screenName: 'Tampere kahdessa tunnissa'
        },
        {
          screen: 'CultureRoutesScreen',
          screenName: 'Kulttuuriraitit'
        },
        {
          screen: 'CultureRoutesListScreen',
          screenName: 'Kulttuuriraitit'
        },
        {
          screen: 'VisitTampereScreen',
          screenName: 'Visit Tampere'
        },
        {
          screen: 'ChooseResponsiblyScreen',
          screenName: 'Valitse vastuullisemmin'
        },
        {
          screen: 'ShopOnlineScreen',
          screenName: 'Osta verkosta'
        },
        {
          screen: 'VisitEventScreen',
          screenName: 'Tapahtumat'
        },
        {
          screen: 'EventsListScreen',
          screenName: 'Tapahtumat'
        },
        {
          screen: 'EventsFilterScreen',
          screenName: 'Suodata tapahtumia'
        },
        {
          screen: 'SingleEventScreen',
          screenName: 'Tapahtuma'
        },
        {
          screen: 'CultureRouteListScreen',
          screenName: 'Kulttuuriraitit'
        },
        {
          screen: 'ConferenceEventScreen',
          screenName: 'Konferenssi tapahtuma'
        }
      ]
   },
   {
     group: 'pass',
     analyticsGroups: ['coupon'],
     groupName: 'Tampere tunnetuksi passi',
     screens: [
        {
          screen: 'NearbyCouponsScreen',
          screenName: 'Lähelläsi'
        },
        {
          screen: 'CouponsScreen',
          screenName: 'Tarjoukset'
        },
        {
          screen: 'CouponScreen',
          screenName: 'Tarjous'
        },
        {
          screen: 'CouponFavoritesScreen',
          screenName: 'Suosikit'
        },
        {
          screen: 'SingleCouponScreen',
          screenName: 'Tarjous'
        },
        {
          screen: 'CouponsMapScreen',
          screenName: 'Näytä kartalla'
        },
        {
          screen: 'CouponsInfoScreen',
          screenName: 'Mikä passi?'
        },
        {
          screen: 'ScanCouponCampaignCodeScreen',
          screenName: 'Kampajakoodin skannaus'
        },
        {
          screen: 'RedeemCouponCampaignPrizeScreen',
          screenName: 'Lunasta palkinto'
        }
      ]
   },
   {
     group: 'library',
     groupName: 'Pirkanmaan kirjastokortti',
     screens: [
        {
          screen: 'LibraryCardScreen',
          screenName: 'Kirjastokortti'
        },
        {
          screen: 'LibraryLoginScreen',
          screenName: 'Kirjastokortti - Kirjautuminen'
        },
        {
          screen: 'LibraryInstructionsScreen',
          screenName: 'Ohjeet'
        }
      ]
   },
   {
     group: 'recycle',
     analyticsGroups: ['recycle_point'],
     groupName: 'Pirkanmaan kierrätyspisteet',
     screens: [
        {
          screen: 'RecyclesMapScreen',
          screenName: 'Näytä kartalla'
        },
        {
          screen: 'NearbyRecyclesScreen',
          screenName: 'Lähelläsi'
        },
        {
          screen: 'RecyclesScreen',
          screenName: 'Kierrätyspisteet'
        },
        {
          screen: 'AllRecyclesScreen',
          screenName: 'Kaikki kierrätyspisteet'
        },
        {
          screen: 'RecycleScreen',
          screenName: 'Kierrätyspiste'
        },
        {
          screen: 'RecycleFilterScreen',
          screenName: 'Kierrätyspisteiden suodatus'
        }
      ]
   },
   {
     group: 'co2',
     groupName: 'Liikkumisen hiilijalanjälkesi',
     screens: [
        {
          screen: 'Co2CalculatorScreen',
          screenName: 'CO2-laskuri'
        },
        {
          screen: 'Co2IntroScreen',
          screenName: 'CO2-Aloitusnäkymä'
        },
        {
          screen: 'Co2InstructionScreen',
          screenName: 'Ohje'
        },
        {
          screen: 'Co2EditTripScreen',
          screenName: 'Muokkaa matkaa'
        },
        {
          screen: 'Co2PollScreen',
          screenName: 'Yksittäinen kysely'
        },
        {
          screen: 'Co2TripsScreen',
          screenName: 'Co2TripsScreen'
        },
        {
          screen: 'Co2OffersScreen',
          screenName: 'CO2-tarjoukset'
        },
        {
          screen: 'Co2TripScreen',
          screenName: 'Yksittäinen matka'
        },
        {
          screen: 'Co2InfoScreen',
          screenName: 'Info'
        },
        {
          screen: 'Co2OfferScreen',
          screenName: 'CO2-tarjous'
        },
        {
          screen: 'Co2InstructionsScreen',
          screenName: 'Ohjeet - listanäkymä'
        }
      ]
   },
   {
     group: 'biking',
     analyticsGroups: ['scenic_route'],
     groupName: 'Pyöräily ja maisemareitit',
     screens: [
        {
          screen: 'BikeRouteSearchScreen',
          screenName: 'Hae reittiä'
        },
        {
          screen: 'ScenicRoutesScreen',
          screenName: 'Maisemareitit'
        },
        {
          screen: 'ScenicRouteScreen',
          screenName: 'Maisemareitti'
        },
        {
          screen: 'ScenicRouteMapScreen',
          screenName: 'Maisemareittikartta'
        }
      ]
   },
   {
     group: 'parking',
     groupName: 'Pysäköinti',
     screens: [
        {
          screen: 'ParkingScreen',
          screenName: 'Pysäköinti'
        },
        {
          screen: 'BikeParksScreen',
          screenName: 'Pyöräparkit TAYS'
        }
      ]
   },
   {
     group: 'sport',
     analyticsGroups: [
       'sport_item'
     ],
     groupName: 'Ulkoilureitit ja liikuntapaikat',
     screens: [
        {
          screen: 'NearbySportScreen',
          screenName: 'Lähelläsi'
        },
        {
          screen: 'SportScreen',
          screenName: 'Yksittäinen paikka'
        },
        {
          screen: 'SearchSportScreen',
          screenName: 'Etsi paikkaa'
        },
        {
          screen: 'SportMapScreen',
          screenName: 'Paikat kartalla'
        },
        {
          screen: 'SportGuideScreen',
          screenName: 'Ohjeet'
        }
      ]
   },
   {
     group: 'sauna',
     analyticsGroups: ['sauna_item'],
     groupName: 'Tampereen saunat',
     screens: [
        {
          screen: 'SaunasScreen',
          screenName: 'Saunapääkaupunki'
        },
        {
          screen: 'SaunaMapScreen',
          screenName: 'Saunat kartalla'
        },
        {
          screen: 'SaunaScreen',
          screenName: 'Sauna'
        },
        {
          screen: 'SaunaFeedbackScreen',
          screenName: 'Anna palautetta'
        }
      ]
   },
   {
     group: 'tipomobi',
     groupName: 'Tipomobi',
     screens: [
        {
          screen: 'TipomobiNavigationScreen',
          screenName: 'Navigoi'
        },
        {
          screen: 'TipomobiFeedbackScreen',
          screenName: 'Anna palautetta'
        },
        {
          screen: 'TipomobiTaxiScreen',
          screenName: 'KELA-taksi'
        },
        {
          screen: 'TipomobiInfoScreen',
          screenName: 'Tietoja'
        }
      ]
   },
   {
     group: 'services',
     analyticsGroups: ['city_service'],
     groupName: 'Kaupungin palvelut',
     screens: [
        {
          screen: 'NearbyCityServicesScreen',
          screenName: 'Lähelläsi'
        },
        {
          screen: 'CityServiceScreen',
          screenName: 'Kaupungin palvelu'
        },
        {
          screen: 'SearchCityServicesScreen',
          screenName: 'Etsi paikkaa'
        },
        {
          screen: 'CityServicesMapScreen',
          screenName: 'Paikat kartalla'
        },
        {
          screen: 'CityServicesGuideScreen',
          screenName: 'Ohjeet'
        }
      ]
   },
   {
     group: 'others',
     groupName: 'Muut palvelut',
     screens: [
        {
          screen: 'WalkRouteScreen',
          screenName: 'Kävellen'
        },
        {
          screen: 'My2050Screen',
          screenName: 'My2050'
        },
        {
          screen: 'Tre2026Screen',
          screenName: 'Tampere 2026'
        },
        {
          screen: 'TreSmartCityScreen',
          screenName: 'Tampere Smart City Week'
        }
      ]
   },
   {
     group: 'long_distance',
     groupName: 'Kaukoliikenne',
     screens: [
        {
          screen: 'BusesScreen',
          screenName: 'Bussit'
        },
        {
          screen: 'FlightsScreen',
          screenName: 'Lentoliikenne'
        },
        {
          screen: 'TrainsScreen',
          screenName: 'Junat'
        }
      ]
   },
   {
     group: 'notifications',
     analyticsGroups: [
       'city_announcement',
       'notification'
     ],
     groupName: 'Notifikaatiot',
     screens: [
        {
          screen: 'NotificationsScreen',
          screenName: 'Notifikaatiot'
        },
        {
          screen: 'MessageLogScreen',
          screenName: 'Notifikaatiot'
        },
        {
          screen: 'NotificationScreen',
          screenName: 'Notifikaatio'
        },
        {
          screen: 'SingleNotificationScreen',
          screenName: 'Notifikaatio'
        },
        {
          screen: 'MessageScreen',
          screenName: 'Notifikaatio'
        },
        {
          screen: 'CityNotificationsScreen',
          screenName: 'Kaupungin tiedotteet'
        },
        {
          screen: 'CityNotificationScreen',
          screenName: 'Kaupungin tiedote'
        }
      ]
   },
   {
     group: 'favorites',
     groupName: 'Suosikit',
     screens: [
        {
          screen: 'FavoritesScreen',
          screenName: 'Suosikit'
        },
        {
          screen: 'AddShortcutScreen',
          screenName: 'Lisää pikavalinta'
        }
      ]
   },
   {
     group: 'settings',
     groupName: 'Asetukset',
     screens: [
        {
          screen: 'SettingsScreen',
          screenName: 'Asetukset'
        },
        {
          screen: 'SportSettingsScreen',
          screenName: 'Ulkoilureitit ja liikuntapaikat'
        },
        {
          screen: 'InfoScreen',
          screenName: 'Tietoa sovelluksesta'
        },
        {
          screen: 'LanguageSelectionScreen',
          screenName: 'Kielivalinta'
        },
        {
          screen: 'CreateAccountScreen',
          screenName: 'Luo käyttäjätunnus'
        },
        {
          screen: 'LocationSettingsScreen',
          screenName: 'Sijaintiasetukset'
        },
        {
          screen: 'LibraryCardSettingsScreen',
          screenName: 'Kirjastokortin asetukset'
        },
        {
          screen: 'TermsScreen',
          screenName: 'Käyttöehdot'
        },
        {
          screen: 'EditDashboardScreen',
          screenName: 'Muokkaa pikavalintoja'
        },
        {
          screen: 'UserAccountSettingsScreen',
          screenName: 'Käyttäjätunnuksen asetukset'
        },
        {
          screen: 'LoginScreen',
          screenName: 'Kirjaudu sisään'
        },
        {
          screen: 'CreateAccountConfirmationScreen',
          screenName: 'Käyttäjätunnuksen vahvistus'
        },
        {
          screen: 'ForgotPasswordScreen',
          screenName: 'Unohditko salasanasi'
        },
        {
          screen: 'VersionHistoryScreen',
          screenName: 'Versiohistoria'
        },
        {
          screen: 'BugReportScreen',
          screenName: 'Ilmoita bugista'
        },
        {
          screen: 'NotificationSettingsScreen',
          screenName: 'Notifikaatio asetukset'
        },
        {
          screen: 'MenuOrderSettingsScreen',
          screenName: 'Valikon järjestyksen muokkaus'
        },
        {
          screen: 'Co2SettingsScreen',
          screenName: 'Hiilijalanjälkilaskurin asetukset'
        },
        {
          screen: 'AppFeedbackScreen',
          screenName: 'Sovelluspalaute'
        }
      ]
   },
   {
     group: 'weather',
     groupName: 'Sää',
     screens: [
        {
          screen: 'WeatherScreen',
          screenName: 'Sää'
        }
      ]
   },
   {
    group: 'pulse',
    groupName: 'Pulssi',
    screens: [
       {
         screen: 'TamperePulseScreen',
         screenName: 'Pulssi'
       }
     ]
  }
]

