import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Button, Input, ImageUpload, Dialog } from '../../../Components'
import { Colors } from '../../../Utils/theme'
import DeleteIcon from '../../../Assets/Icons/close-dark.svg'
import AddIcon from '../../../Assets/Icons/add-red.svg'
import { Api } from '../../../Services'

const useStyles = makeStyles((theme) => ({
  videoLinkRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  addButtonCompact: {
    marginLeft: '.5rem',
    width: '10.5rem'
  },
  addButtonTextCompact: {
    fontSize: '0.875rem'
  },
  mediaRow: {
    marginTop: '-1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  mediaContainer: {
    marginTop: '-1rem'
  },
  videoInput: {
    width: '60vw',
    minWidth: 120,
    maxWidth: 600
  },
  deleteContainer: {
    paddingTop: '1.75rem',
    marginLeft: '.5rem',
    maxHeight: '2rem'
  },
  previewTitle: {
    fontWeight: 400,
    fontSize: '1.25rem',
    marginBottom: '.25rem'
  },
  previewDescription: {
    fontSize: '1rem',
    fontWeight: 300,
    maxWidth: 700,
    marginBottom: '1rem'
  },
  error: {
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '1rem',
    color: Colors.red,
    display: 'inline-block',
    padding: '.25rem .66rem',
    borderRadius: '5px',
    border: `2px solid ${Colors.red}`
  },
  videoHtml: {
    width: 600,
    height: 360,
    '& > iframe': {
      height: '100%',
      width: '100%'
    }
  },
  spacer: {
    width: '1rem'
  }
}))

const MediaInput = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [embed, setEmbed] = useState(null)
  const [initDone, setInitDone] = useState(false)

  const [alertVisible, setAlertVisible] = useState(false)

  const closeAlert = () => setAlertVisible(false)

  const handleDeleteVideo = () => {
    if (props.video && !alertVisible) {
      setAlertVisible(true)
    } else {
      setAlertVisible(false)
      props.handleDeleteVideo()
    }
  }

  useEffect(() => {
   if (props.video && !initDone) {
    setInitDone(true)
    fetchEmbed(props.video)
   }
  }, [props.video])

  const fetchEmbed = async (video) => {
    if (video) {
      const response: any = await Api.getVideoEmbed(video)
      if (response.ok && response.data) {
        setEmbed(response.data)
      } else {
        setEmbed({ error: true })
      }
    } else {
      setEmbed(null)
    }
  }

  const handleSetVideo = (video) => {
    props.setVideo(video)
    debounce(() => fetchEmbed(video), 300)()
  }


  const videoValidationMessage = () => {
    if (props.video && embed && embed.error) {
      return {
        error: true,
        helperText: t('embed_invalid_url')
      }
    }
    return {}
  }

  const renderVideoForm = () => {
    if (!props.mediaType) {
      return (
        <>
          <div className={classes.spacer} />
          <Button
            text={t('add_video')}
            onClick={() => props.setMediaType('video')}
            buttonStyle={classes.addButtonCompact}
            buttonTextStyle={classes.addButtonTextCompact}
            rightIcon={AddIcon}
            margin
            fullWidth
            secondary
            variant
          />
        </>
      )
    }
    if (props.mediaType === 'video') {
      return (
        <>
          <div className={classes.videoLinkRow}>
            <div className={classes.videoInput}>
              <Input
                label={t('video_link')}
                value={props.video}
                onChange={handleSetVideo}
                {...videoValidationMessage()}
              />
            </div>
            <div className={classes.deleteContainer}>
              <Tooltip title={t('delete_video')}>
                <IconButton onClick={handleDeleteVideo}>
                  <img src={DeleteIcon} alt='delete' />
                </IconButton>
              </Tooltip>
              <Dialog
                open={alertVisible}
                handleClose={closeAlert}
                handleOk={handleDeleteVideo}
                title={t('delete_video')}
                description={t('delete_video_description')}
              />
            </div>
          </div>
          {(!embed || !embed.embedHtml || props.video !== embed.originalUrl) ? null : (
            <div>
              <div className={classes.previewTitle}>{t('video_preview')}</div>
              <p className={classes.previewDescription}>{t('video_preview_description')}</p>
              <div className={classes.videoHtml} dangerouslySetInnerHTML={{__html: embed.embedHtml }} />
            </div>
          )}
        </>
      )
    }
    return null
  }

  const getDescription = () => {
    if (props.mediaType === 'video') return t('content_media_video_description')

    // Default description
    return t('content_media_description')
  }
  return (
    <>
      <Title
        title={t('media')}
        description={getDescription()}
        type='subtitle'
      />
      <div className={!props.mediaType ? classes.mediaRow : classes.mediaContainer}>
        {props.mediaType === 'video' ? null : (
          <ImageUpload
            photo={props.photo}
            onFileUpload={props.handleSetPhoto}
            removePhoto={props.handleRemovePhoto}
            compact
          />
        )}
        {renderVideoForm()}
      </div>
    </>
  )
}

export default MediaInput
