import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '..';
import { Button, Title, Input } from '../';
import { v4 as uuidv4 } from 'uuid';
import { Colors } from '../../Utils/theme';
import MediaInput from '../../Containers/Authorized/Notifications/MediaInput';
import AddIcon from '../../Assets/Icons/add-red.svg';
import DeleteOutlined from '../../Assets/Icons/delete-outlined.svg';
import { Category, Test } from '../../Models/TestStore';
import LangButton from '../Common/LangButton';
import QuestionForm from '../Common/QuestionForm';

const useStyles = makeStyles((theme) => ({
  container: {},
  introContainer: {
    backgroundColor: Colors.white,
    padding: '1.5rem',
    border: `1px solid ${Colors.black50}`,
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: '0.1875rem',
  },
  groupsContainer: {
    backgroundColor: Colors.white,
    padding: '1.5rem 1.5rem 2rem',
    border: `1px solid ${Colors.black50}`,
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: '0.1875rem',
  },
  header: {
    flexDirection: 'row',
    paddingBottom: '1rem',
  },
  actionButtons: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    background: 'transparent',
  },
  actionButton: {
    margin: 0,
    padding: '.5rem 0',
    borderRadius: 5,
    background: Colors.inputGrey,
    '&:hover': {
      background: Colors.inputGrey,
    },
    width: '2.25rem',
    height: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    cursor: 'pointer',
  },
  buttonIcon: {
    maxHeight: '1rem',
    maxWidth: '1rem',
  },
  mediaContainer: {
    backgroundColor: Colors.white,
    padding: '.5rem 1.5rem 1.5rem',
    border: `1px solid ${Colors.black50}`,
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: '0.1875rem',
  },
  introHeader: {
    flexDirection: 'row',
    paddingBottom: '0.75rem',
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    color: Colors.heading,
    margin: 0,
    padding: '0 0 0.75rem',
    flex: 1,
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem',
  },
  tabActive: {
    cursor: 'pointer',
    color: Colors.black,
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  tab: {
    cursor: 'pointer',
    opacity: 0.3,
    color: Colors.black,
    fontSize: '2.25rem',
    fontWeight: 500,
    '&:hover': {
      opacity: 0.5,
    },
  },
  tabSpacer: {
    width: '3.75rem',
  },
  settingsDescription: {
    fontWeight: 300,
    fontSize: '1.125rem',
    paddingBottom: '.85rem',
  },
  settingsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkboxContainer: {
    paddingRight: '1rem',
  },
  categoryContainer: {
    maxWidth: '25rem',
  },
}));

type TestFormProps = {
  test: Test;
  categories: Category[];
  onChange: (test: Test) => void;
};

function TestForm({ test, categories, onChange }: TestFormProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const [mediaType, setMediaType] = useState<'photo' | 'video' | null>(null);
  const [langTab, setLangTab] = useState('fi');

  const [locked, setLocked] = useState(false);
  const toggleLock = () => setLocked(!locked);

  const addQuestion = (groupIndex) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: [
              ...g.questions,
              {
                id: uuidv4(),
                type: 'select',
                titleFi: '',
                titleEn: '',
                contentFi: '',
                contentEn: '',
                answersFi: ['', ''],
                answersEn: ['', ''],
                points: [0, 0],
                isOptional: false,
                otherEnabled: false,
              },
            ],
          }
        }
        return g;
      })
    });
  };

  const addGroup = () => {
    onChange({
      ...test,
      groups: [
        ...test.groups,
        {
        id: uuidv4(),
        titleFi: '',
        titleEn: '',
        contentFi: '',
        contentEn: '',
        questions: [],
      }]
    })
  }

  const validationMessage = (field) => {
    // TODO: implementation
    // if (props.submitted && !field) {
    //   return {
    //     error: true,
    //     helperText: t('required_field')
    //   }
    // }
    return {};
  };

  const toggleQuestionOptional = (groupIndex, questionIndex) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, index) => ({
              ...question,
              isOptional:
                index === questionIndex ? !question.isOptional : question.isOptional,
            })),
          }
        }
        return g;
      })
    });
  };

  const toggleQuestionOtherEnabled = (groupIndex, questionIndex) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, index) => ({
              ...question,
              otherEnabled:
                index === questionIndex
                  ? !question.otherEnabled
                  : question.otherEnabled,
            })),
          }
        }
        return g;
      })
    });
  };

  const setQuestionType = (groupIndex, questionIndex, type) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, index) => {
              if (index === questionIndex) {
                question.type = type;
      
                if (type !== 'text') {
                  question.inputRows = undefined;
                  question.isOptional = false;
                  // Add empty answers fields if not set
                  if (!question.answersFi?.length) {
                    question.answersFi = ['', ''];
                  }
                  if (!question.answersEn?.length) {
                    question.answersEn = ['', ''];
                  }
                  if (!question.points?.length) {
                    question.points = [0, 0];
                  }
                } else {
                  // Text answers are always optional
                  question.isOptional = true;
                  question.answersFi = [];
                  question.answersEn = [];
                  question.points = [];
                }
              }
              return question;
            }),
          }
        }
        return g;
      })
    });
  };

  const updateQuestion = (
    groupIndex: number,
    questionIndex: number,
    prop: string,
    lang: string,
    value: string
  ) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, index) => {
              if (index === questionIndex) {
                if (prop === 'inputRows') {
                  const val = Number(value) || null
                  question[prop] = val;
                } else {
                  question[`${prop}${capitalize(lang)}`] = value;
                }
              }
              return question;
            }),
          }
        }
        return g;
      })
    });
  };

  const updateGroup = (
    groupIndex: number,
    prop: string,
    lang: string,
    value: string
  ) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          g[`${prop}${capitalize(lang)}`] = value;
        }
        return g;
      })
    });
  };

  const deleteQuestion = (groupIndex, questionIndex) => {
    const confirm = window.confirm(t('confirm_delete_question'));
    if (confirm) {
      onChange({
        ...test,
        groups: test.groups.map((g, gIndex) => {
          if (gIndex === groupIndex) {
            return {
              ...g,
              questions: g.questions.filter((_, index) => index !== questionIndex),
            }
          }
          return g
        })
      });
    }
  };

  const deleteGroup = (groupIndex) => {
    const confirm = window.confirm(t('confirm_delete_group'));
    if (confirm) {
      onChange({
        ...test,
        groups: test.groups.filter((_, index) => index !== groupIndex)
      });
    }
  };

  const setQuestionAnswer = (groupIndex, questionIndex, answerIndex, lang, value) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, qi) => {
              if (qi === questionIndex) {
                const fieldName = `answers${capitalize(lang)}`;
                question[fieldName] = question[fieldName].map((answer, ai) => {
                  if (ai === answerIndex) {
                    return value;
                  }
                  return answer;
                });
              }
              return question;
            }),
          }
        }
        return g;
      })
    });
  };

  const deleteAnswer = (groupIndex, questionIndex, answerIndex) => {
    const confirm = window.confirm(t('confirm_delete_answer'));
    if (confirm) {
      onChange({
        ...test,
        groups: test.groups.map((g, gIndex) => {
          if (gIndex === groupIndex) {
            return {
              ...g,
              questions: g.questions.map((question, qi) => {
                if (qi === questionIndex) {
                  return {
                    ...question,
                    points: (question.points || []).filter(
                      (_, ai) => ai !== answerIndex
                    ),
                    answersFi: question.answersFi.filter(
                      (_, ai) => ai !== answerIndex
                    ),
                    answersEn: question.answersEn.filter(
                      (_, ai) => ai !== answerIndex
                    ),
                  };
                }
                return question;
              }),
            }
          }
          return g;
        })
      });
    }
  };

  const addAnswer = (groupIndex, questionIndex) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, qi) => {
              if (qi === questionIndex) {
                return {
                  ...question,
                  points: [...(question.points || []), 0],
                  answersFi: [...question.answersFi, ''],
                  answersEn: [...question.answersEn, ''],
                };
              }
              return question;
            }),
          }
        } 
        return g;
      })
    });
  };

  const moveQuestion = (groupIndex, index, direction) => {
    const groups = [...test.groups]
    const questions = [...groups[groupIndex].questions];
    const position = direction === 'up' ? -1 : 1;
    const isOutOfBounds =
      (direction === 'up' && !index) || index === groups[groupIndex].questions.length - 1;
    if (isOutOfBounds) return;

    const temp = questions[index + position];
    questions[index + position] = questions[index];
    questions[index] = temp;
    groups[groupIndex].questions = questions
    onChange({ ...test, groups });
  };

  // const getCategoryOptions = () => {
  //   return (categories || []).map((item) => {
  //     return {
  //       label:
  //         langTab === 'en'
  //           ? item.titleEn || item.titleFi
  //           : item.titleFi || item.titleEn,
  //       value: item.id,
  //     };
  //   });
  // };

  const setPoints = (
    groupIndex: number,
    questionIndex: number,
    answerIndex: number,
    value: string
  ) => {
    onChange({
      ...test,
      groups: test.groups.map((g, gIndex) => {
        if (gIndex === groupIndex) {
          return {
            ...g,
            questions: g.questions.map((question, qi) => {
              if (qi === questionIndex) {
                question.points = question.points?.map((point, ai) => {
                  if (ai === answerIndex) {
                    return value;
                  }
                  return point;
                });
              }
              return question;
            }),
          }
        }
        return g;
      })
    });
  };

  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

  const renderIntroContent = () => {
    return (
      <>
        <Input
          label={t('test_title')}
          value={test[`title${capitalize(langTab)}`]}
          onChange={(value) =>
            onChange({ ...test, [`title${capitalize(langTab)}`]: value })
          }
          {...validationMessage(test[`title${capitalize(langTab)}`])}
        />
        {/* <div className={classes.categoryContainer}>
            <Select
              label={`${t('test_category')}`}
              value={test.categoryId}
              options={getCategoryOptions()}
              onChange={(categoryId) => onChange({ ...test, categoryId })}
            />
          </div> */}
        <Input
          label={t('test_description')}
          value={test[`content${capitalize(langTab)}`]}
          onChange={(value) =>
            onChange({ ...test, [`content${capitalize(langTab)}`]: value })
          }
          multiline
          {...validationMessage(test[`title${capitalize(langTab)}`])}
        />
      </>
    );
  };

  const renderQuestions = (questions, groupIndex) => {
    return (
      <div>
        {questions.map((question, index) => {
          return (
            <QuestionForm
              question={question}
              index={index}
              totalQuestions={questions.length}
              moveQuestionUp={() => moveQuestion(groupIndex, index, 'up')}
              moveQuestionDown={() => moveQuestion(groupIndex, index, 'down')}
              deleteQuestion={() => deleteQuestion(groupIndex, index)}
              setTitle={(lang, title) =>
                updateQuestion(groupIndex, index, 'title', lang, title)
              }
              setContent={(lang, content) =>
                updateQuestion(groupIndex, index, 'content', lang, content)
              }
              setRows={(lang, rows) =>updateQuestion(groupIndex, index, 'inputRows', lang, rows)}
              setType={(type) => setQuestionType(groupIndex, index, type)}
              toggleOptional={() => toggleQuestionOptional(groupIndex, index)}
              toggleOtherEnabled={() => toggleQuestionOtherEnabled(groupIndex, index)}
              setAnswer={(answerIndex, lang, value) =>
                setQuestionAnswer(groupIndex, index, answerIndex, lang, value)
              }
              addAnswer={() => addAnswer(groupIndex, index)}
              deleteAnswer={(answerIndex) => deleteAnswer(groupIndex, index, answerIndex)}
              submitted={false}
              locked={locked}
              toggleLock={toggleLock}
              currentLang={langTab}
              setLang={setLangTab}
              points={question.points}
              setPoints={(pointIndex, value) =>
                setPoints(groupIndex, index, pointIndex, value)
              }
              allowInputRows
            />
          );
        })}
        <Button
          text={t('add_question')}
          onClick={() => addQuestion(groupIndex)}
          rightIcon={AddIcon}
          secondary
          variant
        />
      </div>
    );
  };

  const renderGroups = () => {
    return (
      <div>
        {test.groups.map((group, index) => {
          const title = group[langTab === 'en' ? 'titleEn' : 'titleFi'];
          const content = group[langTab === 'en' ? 'contentEn' : 'contentFi'];
          return (
            <div className={classes.groupsContainer} key={group.id}>
              <div className={classes.header}>
                <h3 className={classes.title}>
                  {`${t('group')} ${index + 1}.`}
                </h3>
                {renderActions(index)}
              </div>
              <Input
                label={`${t('group_title')}`}
                value={title}
                onChange={(val) => updateGroup(index, 'title', langTab, val)}
              />
              <Input
                label={`${t('group_content')}`}
                value={content}
                onChange={(val) => updateGroup(index, 'content', langTab, val)}
                multiline
              />
              {renderQuestions(group.questions, index)}
            </div>
          )
        }
        )}
        <Button
          text={t('add_group')}
          onClick={addGroup}
          rightIcon={AddIcon}
          secondary
          variant
        />
      </div>
    )
  }

  const getStatus = (lang) => {
    if (lang === 'fi') {
      if (test.titleFi && test.contentFi) return 'green';
      if (test.titleFi || test.contentFi) return 'yellow';
      return 'red';
    } else {
      if (test.titleEn && test.contentEn) return 'green';
      if (test.titleEn || test.contentEn) return 'yellow';
      return 'red';
    }
  };

  const renderLangSelect = () => {
    return (
      <div className={classes.actionButtons}>
        {['fi', 'en'].map((lang) => (
          <LangButton
            key={lang}
            lang={lang}
            active={langTab === lang}
            onClick={() => setLangTab(lang)}
            status={getStatus(lang)}
          />
        ))}
      </div>
    );
  };

  const renderActions = (index) => {
    return (
    <div className={classes.actionButtons}>
       {['fi', 'en'].map((lang) => (
          <LangButton
            key={lang}
            lang={lang}
            active={langTab === lang}
            onClick={() => setLangTab(lang)}
            status={getStatus(lang)}
          />
        ))}
    <button type="button" onClick={() => deleteGroup(index)} style={{ border: 'none'}} className={classes.actionButton}>
        <img src={DeleteOutlined} alt="delete" className={classes.buttonIcon} />
      </button>
      </div>)
  }

  const renderSelectUserTypeVisiblity = () => {
    const toggleType = (type) => {
      let types = test.userTypes ? [...test.userTypes] : [];
      if (types.includes(type)) {
        types = types.filter((val) => val !== type);
      } else {
        types.push(type);
      }
      onChange({ ...test, userTypes: !types.length ? null : types });
    };

    return (
      <div className={classes.settingsRow}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={!test.userTypes || !test.userTypes.length}
            onChange={() => onChange({ ...test, userTypes: null })}
            label={t('all')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={test.userTypes?.includes('citizen')}
            onChange={() => toggleType('citizen')}
            label={t('citizen')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={test.userTypes?.includes('visitor')}
            onChange={() => toggleType('visitor')}
            label={t('visitor')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={test.userTypes?.includes('business_attendee')}
            onChange={() => toggleType('business_attendee')}
            label={t('business_attendee')}
          />
        </div>
      </div>
    );
  };

  const renderSelectLanguageVisibility = () => {
    const isCurrentLanguage = (lang: string) => test.userLang === lang;

    return (
      <div className={classes.settingsRow}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={!test.userLang}
            onChange={() => onChange({ ...test, userLang: null })}
            label={t('all_fi_and_en')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={isCurrentLanguage('fi')}
            onChange={() =>
              onChange({
                ...test,
                userLang: isCurrentLanguage('fi') ? null : 'fi',
              })
            }
            label={t('only_fi')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={isCurrentLanguage('en')}
            onChange={() =>
              onChange({
                ...test,
                userLang: isCurrentLanguage('en') ? null : 'en',
              })
            }
            label={t('only_en')}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!tab) {
      return (
        <>
          <div className={classes.introContainer}>
            <div className={classes.introHeader}>
              <h3 className={classes.title}>{t('test_introduction')}</h3>
              {renderLangSelect()}
            </div>
            {renderIntroContent()}
          </div>
          <div className={classes.mediaContainer}>
            <MediaInput
              video={test.video}
              setVideo={(video) => onChange({ ...test, video })}
              photo={test.photo}
              handleSetPhoto={(file) => {
                onChange({ ...test, photo: file, video: null });
                setMediaType('photo');
              }}
              handleRemovePhoto={() => {
                onChange({ ...test, video: null, photo: null });
                setMediaType(null);
              }}
              handleDeleteVideo={() => {
                onChange({ ...test, video: null, photo: null });
                setMediaType(null);
              }}
              mediaType={mediaType}
              setMediaType={setMediaType}
            />
          </div>
          {renderGroups()}
        </>
      );
    }

    return (
      <div>
        <Title title={t('poll_visibility_title')} type="subtitle" />
        <p className={classes.settingsDescription}>
          {t('poll_visibility_description')}
        </p>
        <p className={classes.settingsDescription}>
          {t('poll_select_user_types')}
        </p>
        {renderSelectUserTypeVisiblity()}
        <div style={{ height: '1rem' }} />
        <Title title={t('poll_lang_versions_title')} type="subtitle" />
        <p className={classes.settingsDescription}>
          {t('poll_lang_versions_description')}
        </p>
        <p className={classes.settingsDescription}>
          {t('poll_select_user_languages')}
        </p>
        {renderSelectLanguageVisibility()}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.tabsContainer}>
        <div
          onClick={() => setTab(0)}
          className={!tab ? classes.tabActive : classes.tab}
        >
          {t('basic_info')}
        </div>
        <div className={classes.tabSpacer} />
        <div
          onClick={() => setTab(1)}
          className={tab ? classes.tabActive : classes.tab}
        >
          {t('settings')}
        </div>
      </div>
      {renderContent()}
    </div>
  );
}

export default TestForm;
