import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { Title } from '../../Components'
import { APP_RELEASE_DATES, APP_SCREEN_MAPPINGS } from '../../Constants'
import { Colors, Fonts } from '../../Utils/theme'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '.3125rem',
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1.5rem 0 .75rem 1rem',
    position: 'relative'
  },
  topContainer: {
    position: 'relative',
    marginBottom: '2rem'
  },
  title: {
    position: 'absolute',
    top: '.25rem',
    left: '1rem',
    fontWeight: 700,
    flex: 1,
    fontSize: '1.125rem',
    userSelect: 'none',
    lineHeight: 1.1,
    fontFamily: Fonts.heading
  },
  subTitle: {
    marginTop: '.125rem',
    color: Colors.boxTitle,
    fontSize: '0.75rem',
    fontWeight: 300,
    fontFamily: Fonts.content
  },
  topRightContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: '2.5rem'
  },
  horizontalSpacer: {
    width: '1.5rem'
  }
}))

function ScreenGroupUsageChart(props) {

  const { t } = useTranslation()
  const classes = useStyles()


  const getGroup = (screenName) => {
    for (const group of APP_SCREEN_MAPPINGS) {
      const match = group.screens.find(screen => screen.screen === screenName)
      if (match) return group.group
    }
    return -1
  }

  const getTitle = () => {
    for (const group of APP_SCREEN_MAPPINGS) {
      const match = group.group === props.activeGroup
      if (match) return group.groupName
    }
    return ''
  }

  const getData = () => {
    let uniqueDates = []
    for (const item of props.data) {
      if (!uniqueDates.includes(item.date)) {
        uniqueDates.push(item.date)
      }
    }
    uniqueDates = uniqueDates.sort()

    const relevantScreens = props.data.filter(item => {
      return getGroup(item.screenName) === props.activeGroup
    })


    return uniqueDates.map(date => {
      const dateScreens = relevantScreens.filter(a => a.date === date)
      return {
        date: date,
        count: dateScreens.reduce((partialSum, a) => partialSum + (a.count || 0), 0),
        citizenCount: dateScreens.reduce((partialSum, a) => partialSum + (a.citizenCount || 0), 0),
        visitorCount: dateScreens.reduce((partialSum, a) => partialSum + (a.visitorCount || 0), 0),
        businessAttendeeCount: dateScreens.reduce((partialSum, a) => partialSum + (a.businessAttendeeCount || 0), 0),
        otherCount: dateScreens.reduce((partialSum, a) => partialSum + (a.otherCount || 0), 0)

      }
    })
  }


  const getSeries = () => {
    const currentData = getData()
    return [
      {
        name: 'Asukas',
        data: currentData.map(row => {
          return row.citizenCount
        })
      },
      {
        name: 'Vierailija',
        data: currentData.map(row => {
          return row.visitorCount
        })
      },
      {
        name: 'Yrityskäyttäjä',
        data: currentData.map(row => {
          return row.businessAttendeeCount
        })
      },
      {
        name: 'Tuntematon',
        data: currentData.map(row => {
          return row.otherCount
        })
      },
      {
        name: 'Yhteensä',
        data: currentData.map(row => {
          return row.count
        })
      }
    ]
  }


  const getOptions = () => {
    return {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },

      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      legend: {
        position: 'top',
        fontFamily: Fonts.content,
        itemMargin: {
          horizontal: 16
        }
      },
      xaxis: {
        categories: getData().map(row => row.date),
        axisBorder: {
          show: false // According to layout
        },
        axisTicks: {
          show: false, // According to layout
        },
        tickAmount: 10,
        labels: {
          rotate: 0,
          formatter: function (value) {
            return moment(value).format(DATE_FORMAT);
          }
        }
      },
      grid: {
        show: false // According to layout
      },
      colors: [
         Colors.chartBlue,
         Colors.chartRed,
         '#2D662E',
         Colors.chartGrey,
         Colors.chartYellow
      ],
      annotations: {
        position: 'back',
        xaxis: APP_RELEASE_DATES.filter(item => {
          return true
          // const versionParts = item.name.split('.')
          // return (versionParts[versionParts.length - 1] === '0')
        }).map(item => {
          return {
            x: moment(item.date).format(DATE_FORMAT),
            strokeDashArray: 3,
            borderColor: '#aaa',
            label: {
              borderColor: '#aaa',
              style: {
                color: '#333',
                background: '#fff',
              },
              text: item.name,
            }
          }
        })
      }
    }
  }

  if (!props.data) return null

  return (
    <>
      <Title title={getTitle()} />
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <div className={classes.title}>
            {t('content_section_screen_views')}
            <div className={classes.subTitle}>
              {t('content_section_screen_views_per_days')}
            </div>
          </div>

          <div className={classes.topRightContainer}>

          </div>
        </div>
        <ReactApexChart
          key={`chart-`}
          options={getOptions()}
          series={getSeries()}
          type='area'
          height={350}
        />

      </div>
    </>
  )
}

export default ScreenGroupUsageChart

