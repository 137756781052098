import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, DataActions, PageContainer, PageContent, NotificationTable } from '../../../Components'
import { UserSortOptions } from '../../../Constants'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  innerLeft: {
    flex: 5
  },
  innerRight: {
    flex: 3
  }
}))

function CouponNotifications(props) {
  const { t } = useTranslation()
  const { couponStore } = useStore()
  const classes = useStyles()

  useEffect(() => {
    couponStore.getCoupons()
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getNotifications()
    couponStore.getMainEntities() // Load jana main entities
  }, [])

  const [tab, setTab] = useState(0)

  const selectTab = (evt, value) => setTab(value)

  const tabs = [
    { id: 0, label: t('active_ones') },
    { id: 1, label: t('deleted_ones') }
  ]

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  const toCreate = () => {
    props.history.push('/coupon-notifications/add')
  }

  /* Coupon notifications */
  const getNotifications = () => {
    let notifications = couponStore.notifications
    if (searchValue) {
      notifications = notifications.filter((notification) => {
        const { titleFi = '', contentFi = '', titleEn = '', contentEn = '' } = notification
        const targets = [titleFi, titleEn, contentFi, contentEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      notifications = orderBy(notifications, 'createdAt', 'desc')
    } else {
      notifications = orderBy(notifications, 'titleFi', 'asc')
    }
    // Filter
    notifications = notifications.filter(item => {
      return tab === 1 ? !item.active : item.active
    })

    // Sort deleted last
    notifications = orderBy(notifications, 'active', 'desc')

    return notifications
  }

  const renderDataActions = () => {
    return (
      <div className={classes.innerContainer}>
        <div className={classes.innerLeft}>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <PageHeader
        title={t('notifications')}
        tabs={(props.tab || props.tab === 0) ? undefined : tabs}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate}
      />
      <PageContainer>
        <PageContent>
          {renderDataActions()}
          <NotificationTable
            items={getNotifications()}
            type='coupon'
          />
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(CouponNotifications))
