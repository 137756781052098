import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import qs from 'qs'
import { useStore } from '../../../Models/RootStore'
import { withRouter, useHistory } from 'react-router-dom'
import { PageHeader, FeedbackTable, DataActions, PageContainer, PageContent } from '../../../Components'
import { UserSortOptions } from '../../../Constants'

function Feedbacks(props) {
  const { t } = useTranslation()
  const { influenceStore } = useStore()

  let initialTab = parseInt(qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab || '0', 10)
  const history = useHistory()

  useEffect(() => {
    influenceStore.getCityFeedbacks()
    influenceStore.getFaultReports()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  const [tab, setTab] = useState(initialTab)

  const selectTab = (evt, value) => {
    const params = new URLSearchParams()
    params.append('tab', value)

    history.push({search: params.toString()})
    setTab(value)
  }

  const tabs = [
    { id: 0, label: t('fault_reports') },
    { id: 1, label: t('city_feedbacks') },
  ]

  /* City Feedbacks */
  const getItems = () => {
    let items = tab === 1 ? influenceStore.cityFeedbacks : influenceStore.faultReports
    if (searchValue) {
      items = items.filter((cityFeedback) => {
        const { mainCategory = '', subCategory = '', title = '', email = '' } = cityFeedback
        const targets = [mainCategory, subCategory, title, email]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      items = orderBy(items, 'createdAt', 'desc')
    } else {
      items = orderBy(items, 'titleFi', 'asc')
    }

    // Sort deleted last
    items = orderBy(items, 'active', 'desc')

    return items
  }

  const renderTable = () => {
    return <FeedbackTable items={getItems()} type={tab === 1 ? 'city_feedback' : 'fault_report'} />
  }

  return (
    <>
      <PageHeader
        title={t('fault_reports_and_feedbacks')}
        tabs={tabs}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Feedbacks))
