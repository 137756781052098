import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flexDirection: 'column',
    flexGrow: 1,
    flex: 1,
    padding: '2rem 3rem 15rem'
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.deletedOverlayColor,
    zIndex: 5
  },
  mediumContainer: {
    maxWidth: '75rem'
  },
  smallContainer: {
    maxWidth: '50rem'
  }
}))

const PageContent = (props) => {
  const classes = useStyles()

  const renderDisabled = () => {
    if (props.disabled) {
      return <div className={classes.disabledOverlay} />
    }
    return null
  }

  const getSizeClass = () => {
    if (props.size === 'medium') return classes.mediumContainer
    if (props.size === 'small') return classes.smallContainer
    return ''
  }

  return (
    <div className={classes.container}>
      <div className={getSizeClass()}>
        {props.children}
      </div>
      {renderDisabled()}
    </div>
  )
}

export default PageContent
