import React, { useEffect, useState } from 'react';
import {
  DataActions,
  Grid,
  PageContainer,
  PageContent,
  PageHeader,
} from '../../../Components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { useStore } from '../../../Models/RootStore';
import { UserSortOptions } from '../../../Constants';
import { useHistory } from 'react-router-dom';

const Tests = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { testStore, userStore } = useStore();

  const [tab, setTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value);
  const [categoryValue, setCategoryValue] = useState('all');
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    testStore.getTests();
    // Implementation note: Not used at this time. adding categories later if needed for tests.
    // testStore.getTestCategories()
    userStore.getMyOrganizationUsers();
  }, []);

  const selectTab = (_, value) => {
    setTab(value);
  };

  const toCreate = () => {
    history.push('/tests/add');
  };

  const handleDelete = (id) => {
    if (deleteId) {
      testStore.updateTest(id, {
        active: false,
      });
      setDeleteId(null);
    } else {
      setDeleteId(id);
    }
  };

  const getTests = () => {
    let tests = testStore.tests;
    if (searchValue) {
      tests = tests.filter((test) => {
        const {
          titleFi = '',
          contentFi = '',
          titleEn = '',
          contentEn = '',
        } = test;
        const targets = [titleFi, titleEn, contentFi, contentEn];
        return targets.some((target) =>
          (target || '').toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }
    if (sortValue === 'created_at') {
      tests = orderBy(tests, 'createdAt', 'desc');
    } else {
      tests = orderBy(tests, 'titleFi', 'asc');
    }

    if (categoryValue && categoryValue !== 'all') {
      tests = tests.filter((test) => test.categoryId === categoryValue);
    }

    // Filter
    tests = tests.filter((item) => {
      // Deleted
      if (tab === 4) return !item.active;

      // Draft
      if (tab === 3) return item.draft;

      // Dont display deleted or drafts for other tabs
      if (!item.active || item.draft) return false;

      // Ended
      if (tab === 2) {
        return moment().isAfter(item.validUntil);
      }

      // Upcoming
      if (tab === 1) {
        return moment().isBefore(item.validFrom);
      }

      // Active
      if (tab === 0) {
        return (
          moment().isAfter(item.validFrom) &&
          (!item.validUntil || moment().isBefore(item.validUntil))
        );
      }

      return true;
    });

    // Sort deleted last
    tests = orderBy(tests, 'active', 'desc');

    return tests;
  };

  const renderContent = () => {
    // Implementation note: Not used at this time. adding categories later if needed for tests.
    // if (tab === 5) {
    //   // TODO: categories
    //   return (
    //     <p>Categories</p>
    //     // <PollCategories />
    //   )
    // }

    return (
      <>
        <DataActions
          searchPlaceholder={t('search_by_name')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={UserSortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
          //categoryOptions={getCategoryOptions()}
          categoryValue={categoryValue}
          onCategoryChange={setCategoryValue}
        />
        <Grid items={getTests()} onDelete={handleDelete} name="tests" />
      </>
    );
  };

  // Implementation note: Not used at this time. adding categories later if needed for tests.
  // const getCategoryOptions = () => {
  //   const categories = testStore.testCategories.map(item => ({
  //     label: item.titleFi || item.titleEn,
  //     value: item.id
  //   }))
  //   return [
  //     {
  //       label: t('all_categories'),
  //       value: 'all'
  //     },
  //     ...categories
  //   ]
  // }

  return (
    <>
      <PageHeader
        title={t('tests')}
        onCreateClick={tab !== 5 ? toCreate : null}
        tabs={[
          { id: 0, label: t('test_tab_active') },
          { id: 1, label: t('test_tab_upcoming') },
          { id: 2, label: t('test_tab_ended') },
          { id: 3, label: t('test_tab_drafts') },
          { id: 4, label: t('test_tab_deleted') },
          // Implementation note: Not used at this time. adding categories later if needed for tests.
          // { id: 5, label: t('test_tab_categories') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        <PageContent>{renderContent()}</PageContent>
      </PageContainer>
    </>
  );
};

export default observer(Tests);
