import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, NotificationTable, DataActions, PageContainer, PageContent } from '../../../Components'
import { UserSortOptions } from '../../../Constants'
import { mergeNotificationAnalytics } from '../../../Utils/analytics'

function Notifications(props) {
  const { t } = useTranslation()
  const { notificationStore, analyticsStore } = useStore()

  useEffect(() => {
    notificationStore.getNotifications()
    analyticsStore.getActiveDeviceDistribution()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)
  const [filterValue, setFilterValue] = useState('active')

  const toCreate = () => {
    props.history.push('/notifications/add')
  }

  /* Notifications */
  const getNotifications = () => {
    let notifications = notificationStore.notifications
    if (searchValue) {
      notifications = notifications.filter((notification) => {
        const { titleFi = '', contentFi = '', titleEn = '', contentEn = '' } = notification
        const targets = [titleFi, titleEn, contentFi, contentEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      notifications = orderBy(notifications, 'createdAt', 'desc')
    } else {
      notifications = orderBy(notifications, 'titleFi', 'asc')
    }
    // Filter
    notifications = notifications.filter(item => {
      if (filterValue === 'active') return item.active
      else if (filterValue === 'deleted') return !item.active
      return true
    })

    // Sort deleted last
    notifications = orderBy(notifications, 'active', 'desc')

    return mergeNotificationAnalytics(notifications, analyticsStore.activeDeviceDistribution, analyticsStore.bundledNotificationViews)
  }

  const getFilterOptions = () => {
    return [
      { label: t('view_active'), value: 'active' },
      { label: t('view_all'), value: 'all' },
      { label: t('view_deleted'), value: 'deleted' },
    ]
  }

  const renderTable = () => {
    return <NotificationTable items={getNotifications()} />
  }

  return (
    <>
      <PageHeader title={t('notifications')} onCreateClick={toCreate} />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
            filterOptions={getFilterOptions()}
            filterValue={filterValue}
            onFilterChange={setFilterValue}
          />
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Notifications))
