import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import LangButton from './LangButton';
import { Button, Title, Input, Select, Checkbox } from '..';
import OrderUp from '../../Assets/Icons/order-up.svg';
import OrderDown from '../../Assets/Icons/order-down.svg';
import DeleteOutlined from '../../Assets/Icons/delete-outlined.svg';
import IconLock from '../../Assets/Icons/lock.svg';
import IconLockOpen from '../../Assets/Icons/lock-open.svg';
import AddIcon from '../../Assets/Icons/add-red.svg';
import { Colors } from '../../Utils/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: Colors.white,
    padding: '1.5rem 1.5rem 2rem',
    border: `1px solid ${Colors.black50}`,
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: '0.1875rem',
  },
  header: {
    flexDirection: 'row',
    paddingBottom: '1rem',
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    color: Colors.heading,
    margin: 0,
    padding: '0 0 0.75rem',
    flex: 1,
  },
  mandatoryContainer: {
    marginBottom: '.5rem',
  },
  mandatoryTitle: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: '.25rem',
  },
  switchContainer: {
    paddingLeft: '.25rem',
  },
  actionButtons: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  answerOptionContainer: {
    display: 'flex',
    gap: 16,
  },
  actionButton: {
    margin: 0,
    padding: '.5rem 0',
    borderRadius: 5,
    background: Colors.inputGrey,
    '&:hover': {
      background: Colors.inputGrey,
    },
    width: '2.25rem',
    height: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    cursor: 'pointer',
  },
  buttonIcon: {
    maxHeight: '1rem',
    maxWidth: '1rem',
  },
  deleteButton: {
    border: `1px solid ${Colors.red}`,
    background: 'transparent',
    marginTop: '1.85rem',
    marginLeft: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${Colors.red}`,
      background: 'transparent',
    },
  },
  smallSpacer: {
    display: 'inline-block',
    width: '2rem',
  },
}));

function QuestionForm(props) {
  const classes = useStyles();

  const { t } = useTranslation();

  const getQuestionTypeOptions = () => [
    {
      label: t('answer_type_select'),
      value: 'select',
    },
    {
      label: t('answer_type_multiselect'),
      value: 'multiselect',
    },
    {
      label: t('answer_type_text'),
      value: 'text',
    },
  ];

  const langTab = props.currentLang;

  const charIndex = (number) =>
    number < 26 ? String.fromCharCode(number + 65) : number;

  const getQuestionStatus = (lang) => {
    const question = props.question;

    if (lang === 'en') {
      if (!question.titleEn) return 'red';

      if (question.type !== 'text') {
        if (
          question.answersEn.length !==
          question.answersEn.filter((a) => !!a).length
        ) {
          return 'yellow';
        }
      }
      return 'green';
    } else {
      if (!question.titleFi) return 'red';

      if (question.type !== 'text') {
        if (
          question.answersFi.length !==
          question.answersFi.filter((a) => !!a).length
        ) {
          return 'yellow';
        }
      }
      return 'green';
    }
  };

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field'),
      };
    }
    return {};
  };

  const renderQuestionAnswers = () => {
    const { question, points } = props;
    const fieldName = langTab === 'en' ? 'answersEn' : 'answersFi';
    const inactiveFieldName = langTab === 'en' ? 'answersFi' : 'answersEn';

    if (question.type === 'text') return null;

    return question[fieldName].map((answer, answerIndex) => (
      <div key={answerIndex} className={classes.answerOptionContainer}>
        <div style={{ flex: 1 }}>
          <Input
            value={answer}
            onChange={(val) => props.setAnswer(answerIndex, langTab, val)}
            label={`${t('answer_option_num')} ${charIndex(answerIndex)}`}
            {...validationMessage(
              answer || question[inactiveFieldName][answerIndex]
            )}
          />
        </div>
        {points ? (
          <div style={{ flex: 1 }}>
            <Input
              value={points[answerIndex]}
              onChange={(val) => props.setPoints(answerIndex, val)}
              label={`${t('answer_points_num')} ${charIndex(answerIndex)}`}
              {...validationMessage(
                answer || question[inactiveFieldName][answerIndex]
              )}
            />
          </div>
        ) : null}

        {question[fieldName].length > 2 ? (
          <Button
            onClick={() => props.deleteAnswer(answerIndex)}
            icon={DeleteOutlined}
            buttonStyle={classes.deleteButton}
          />
        ) : null}
      </div>
    ));
  };

  const renderQuestion = () => {
    const { question } = props;

    const title = question[langTab === 'en' ? 'titleEn' : 'titleFi'];
    const content = question[langTab === 'en' ? 'contentEn' : 'contentFi'];

    if (props.locked) {
      return (
        <Input
          label={`${t('question_title')}`}
          value={title}
          onChange={(val) => props.setTitle(langTab, val)}
          {...validationMessage(question.titleFi || question.titleEn)}
          disabled
        />
      );
    }

    return (
      <>
        <Input
          label={`${t('question_title')}`}
          value={title}
          onChange={(val) => props.setTitle(langTab, val)}
          {...validationMessage(question.titleFi || question.titleEn)}
        />
        <Input
          label={`${t('question_content')}`}
          value={content}
          onChange={(val) => props.setContent(langTab, val)}
          multiline
        />
        {question.type === 'text' && props.allowInputRows ? <Input
          label={`${t('question_rows')}`}
          value={question.inputRows || ''}
          onChange={(val) => props.setRows(langTab, val)}
        /> : null}
        <Select
          label={`${t('question_type')}`}
          value={question.type}
          onChange={(val) => props.setType(val)}
          options={getQuestionTypeOptions()}
          {...validationMessage(question.type)}
        />
          <div className={classes.mandatoryContainer}>
            <div className={classes.mandatoryTitle}>
              {t('is_question_required_to_answer')}
            </div>
            <div className={classes.switchContainer}>
              <Checkbox
                checked={!question.isOptional}
                label={t('yes')}
                onChange={
                  !question.isOptional ? () => null : props.toggleOptional
                }
              />
              <Checkbox
                checked={!!question.isOptional}
                label={t('no')}
                onChange={
                  !!question.isOptional ? () => null : props.toggleOptional
                }
              />
            </div>
          </div>
        {question.type === 'text' ? null : (
          <>
            <Title title={t('answer_options')} type="subtitle" />
            {renderQuestionAnswers()}
            <Button
              text={t('add_answer')}
              onClick={props.addAnswer}
              rightIcon={AddIcon}
              secondary
              variant
            />
            <div className={classes.smallSpacer} />
            <Checkbox
              checked={!!question.otherEnabled}
              onChange={props.toggleOtherEnabled}
              label={t('poll_other_enabled')}
            />
          </>
        )}
      </>
    );
  };

  const renderArrangeButtons = () => {
    const { index, totalQuestions } = props;
    return (
      <>
        {index > 0 ? (
          <div onClick={props.moveQuestionUp} className={classes.actionButton}>
            <img src={OrderUp} alt="up" className={classes.buttonIcon} />
          </div>
        ) : null}
        {index < totalQuestions - 1 ? (
          <div
            onClick={props.moveQuestionDown}
            className={classes.actionButton}
          >
            <img src={OrderDown} alt="down" className={classes.buttonIcon} />
          </div>
        ) : null}
      </>
    );
  };

  const renderDeleteButton = () => {
    return (
      <div onClick={props.deleteQuestion} className={classes.actionButton}>
        <img src={DeleteOutlined} alt="delete" className={classes.buttonIcon} />
      </div>
    );
  };

  const renderLockButton = () => {
    const icon = props.locked ? IconLock : IconLockOpen;
    return (
      <div onClick={props.toggleLock} className={classes.actionButton}>
        <img src={icon} alt="lock" className={classes.buttonIcon} />
      </div>
    );
  };

  const renderLangSelect = () => {
    return (
      <div className={classes.actionButtons}>
        {renderLockButton()}
        {renderArrangeButtons()}
        {['fi', 'en'].map((lang) => (
          <LangButton
            key={lang}
            lang={lang}
            active={langTab === lang}
            onClick={() => props.setLang(lang)}
            status={getQuestionStatus(lang)}
          />
        ))}
        {renderDeleteButton()}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.title}>{`${t('question')} ${
          props.index + 1
        }.`}</h3>
        {renderLangSelect()}
      </div>
      {renderQuestion()}
    </div>
  );
}

export default QuestionForm;
