import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: '1rem'
  },
  label: {
    fontSize: '1.125rem',
    color: Colors.content,
    fontWeight: 300
  }
}))

export default function RadioSelect(props) {
  const classes = useStyles()

  const onChange = (evt) => {
    props.onChange(evt.target.value)
  }

  const renderOptions = () => {
    return props.options.map(item => (
      <FormControlLabel
        value={item.value}
        control={<Radio />}
        label={item.label}
        classes={{
          label: classes.label
        }}
      />
    ))
  }

  return (
    <FormControl variant='outlined' className={classes.container}>
      <RadioGroup value={props.value} onChange={onChange}>
        {renderOptions()}
      </RadioGroup>
    </FormControl>
  )
}
