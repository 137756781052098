import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '41.25rem'
  },
  description: {
    marginBottom: '3rem'
  },
  backLink: {
    textAlign: 'center',
    display: 'block',
    marginTop: '2rem'
  }
}))

const EmailChange = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore } = useStore()

  let initialCode = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      initialCode = parts[1].split('&')[0]
    }
  }

  useEffect(() => {
    if (initialCode) {
      sessionStore.getEmailChange(initialCode)
    }
  }, [])

  const verifyEmailChange = () => sessionStore.verifyEmailChange(initialCode)

  const getDescription = () => {
    if (sessionStore.loading) return null
    if (sessionStore.emailChange) {
      return sessionStore.emailChange.email
    } else {
      return t('invalid_or_expired_code_description')
    }
  }

  return (
    <div className={classes.root}>
      <Title
        title={t('verify_email_change')}
        description={getDescription()}
        type='main'
        descriptionStyle={classes.description}
      />
      {(initialCode && !!sessionStore.emailChange) && <Button text={t('verify')} onClick={verifyEmailChange} margin />}
      <Link className={classes.backLink} to='/'>{t('back_to_login_page')}</Link>    </div>
  )
}

export default observer(EmailChange)
