import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { Select } from '../../Components'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '.3125rem',
    border: `1px solid ${Colors.lightBorder}`,
    padding: '2rem 1rem 1rem',
    position: 'relative'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    paddingLeft: '1rem',
    fontWeight: 700,
    flex: 1,
    fontSize: '1.125rem'
  },
  horizontalSpacer: {
    width: '1.5rem'
  }
}))

function CouponCampaignParticipationChart(props) {
  const [year, setYear] = useState(moment().year())
  const [mode, setMode] = useState('month')

  const { t } = useTranslation()
  const classes = useStyles()

  const getMaxWeeks = () => {
    // Get max weeks of selected year
    return moment(`${year}-12-31 12:00`).isoWeek()
  }

  const getSeries = () => {
    if (mode === 'month') {
      const counts = []
      for (let month = 1; month <= 12; month++) {
        const monthData = props.monthData.find(item => item.month === month && item.year === year)


        if (monthData) {
          counts.push(monthData.count || 0)
        } else {
          counts.push(0)
        }

      }

      return [
        {
          name: t('coupon_uses'),
          data: counts
        }
      ]
    } else {
      // Week data
      const counts = []
      const maxWeeks = getMaxWeeks()
      for (let week = 1; week <= maxWeeks; week++) {
        const weekData = props.weekData.find(item => item.week === week && item.year === year)

        if (weekData) {
          counts.push(weekData.count || 0)
        } else {
          counts.push(0)
        }

      }

      return [
        {
          name: t('campaign_participations'),
          data: counts
        }
      ]
    }
  }


  const getOptions = () => {
    let chartXAxisCategories
    if (mode === 'month') {
      chartXAxisCategories = Array(12).fill(null).map((_, index) => t(`month_${index + 1}`))
    } else {
      // Check how many weeks on selected year
      chartXAxisCategories = Array(getMaxWeeks()).fill(null).map((_, index) => `${index + 1}`)
    }

    const chartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          endingShape: 'rounded',
          borderRadius: mode === 'month' ? 5 : 3,
          columnWidth: mode === 'month' ? '20%' : '50%',
          horizontal: false
        }
      },
      xaxis: {
        categories: chartXAxisCategories,
        axisBorder: {
          show: false // According to layout
        },
        axisTicks: {
          show: false, // According to layout
        }
      },
      legend: {
        show: false,
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      },
      colors: [Colors.red],
      grid: {
        show: false // According to layout
      },
      dataLabels: {
        enabled: false
      }
    }

    return chartOptions
  }

  const getYearOptions = () => {
    const currentYear = moment().year()
    let minYear = currentYear
    for (const item of props.monthData) {
      minYear = Math.min(item.year, minYear)
    }
    const options = []
    for (let i = minYear; i <= currentYear; i++) {
      options.push({
        value: i,
        label: `${i}`
      })
    }
    return options.reverse()
  }

  const getModeOptions = () => {
    return [
      {
        label: t('group_by_month'),
        value: 'month'
      },
      props.weekData ? {
        label: t('group_by_week'),
        value: 'week'
      } : null
    ].filter(val => !!val)
  }

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.title}>
          {t('campaign_participation_division')}
        </div>

        <Select
          value={mode}
          options={getModeOptions()}
          onChange={setMode}
        />
        <div className={classes.horizontalSpacer} />
        <Select
          value={year}
          options={getYearOptions()}
          onChange={setYear}
        />
      </div>
      <ReactApexChart
        key={`chart-${year}`}
        options={getOptions()}
        series={getSeries()}
        type='bar'
        height={350}
      />
    </div>
  )
}

export default CouponCampaignParticipationChart

