import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { Select } from '../../Components'
import { APP_RELEASE_DATES } from '../../Constants'
import { Colors, Fonts } from '../../Utils/theme'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '.3125rem',
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1.5rem 0 .75rem 1rem',
    position: 'relative'
  },
  topContainer: {
    position: 'relative',
    marginBottom: '1rem'
  },
  title: {
    position: 'absolute',
    top: '.25rem',
    left: '1rem',
    fontWeight: 700,
    flex: 1,
    fontSize: '1.125rem',
    userSelect: 'none',
    lineHeight: 1.1,
    fontFamily: Fonts.heading
  },
  subTitle: {
    marginTop: '.125rem',
    color: Colors.boxTitle,
    fontSize: '0.75rem',
    fontWeight: 300,
    fontFamily: Fonts.content
  },
  topRightContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: '2.5rem'
  },
  horizontalSpacer: {
    width: '1.5rem'
  }
}))

function ActiveUsersChart(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [scope, setScope] = useState('1y')

  const getData = () => {

    let startMoment = moment('2000-01-01') // max
    if (scope === '3y') {
      startMoment = moment().subtract(3, 'years').startOf('day').subtract('12', 'hours')
    } else if (scope === '1y') {
      startMoment = moment().subtract(1, 'year').startOf('day').subtract('12', 'hours')
    } else if (scope === '6m') {
      startMoment = moment().subtract(6, 'months').startOf('day').subtract('12', 'hours')
    } else if (scope === '3m') {
      startMoment = moment().subtract(3, 'months').startOf('day').subtract('12', 'hours')
    } else if (scope === '1m') {
      startMoment = moment().subtract(1, 'months').startOf('day').subtract('12', 'hours')
    }

    return props.data.filter((item, index) => {
      return startMoment.isBefore(item.date)
    })
  }

  const getSeries = () => {
    const currentData = getData()
    return [
      {
        name: 'Kaikki',
        data: currentData.map(row => {
          return row.android + row.ios
        })
      },
      {
        name: 'Android',
        data: currentData.map(row => {
          return row.android
        })
      },
      {
        name: 'iOS',
        data: currentData.map(row => {
          return row.ios
        })
      },
      {
        name: 'Päivitys',
        data: currentData.map(_ => null)
      }
    ]
  }

  const getOptions = () => {
    return {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      legend: {
        position: 'top',
        fontFamily: Fonts.content,
        itemMargin: {
          horizontal: 16
        }
      },
      xaxis: {
        categories: getData().map(row => row.date),
        axisBorder: {
          show: false // According to layout
        },
        axisTicks: {
          show: false, // According to layout
        },
        tickAmount: 10,
        labels: {
          rotate: 0,
          formatter: function (value) {
            return moment(value).format(DATE_FORMAT);
          }
        }
      },
      grid: {
        show: false // According to layout
      },
      colors: [
        Colors.chartYellow,
        Colors.chartRed,
        Colors.chartBlue,
        Colors.chartGrey
      ],
      annotations: {
        position: 'back',
        xaxis: APP_RELEASE_DATES.filter(item => {
          return true
          // const versionParts = item.name.split('.')
          // return (versionParts[versionParts.length - 1] === '0')
        }).map(item => {
          return {
            x: moment(item.date).format(DATE_FORMAT),
            strokeDashArray: 3,
            borderColor: '#aaa',
            label: {
              borderColor: '#aaa',
              style: {
                color: '#333',
                background: '#fff',
              },
              text: item.name,
            }
          }
        })
      }
    }
  }

  const getScopeOptions = () => {
    return [
      {
        label: t('scope_1m'),
        value: '1m'
      },
      {
        label: t('scope_3m'),
        value: '3m'
      },
      {
        label: t('scope_6m'),
        value: '6m'
      },
      {
        label: t('scope_1y'),
        value: '1y'
      },
      {
        label: t('scope_3y'),
        value: '3y'
      },
      {
        label: t('scope_max'),
        value: 'max'
      }
    ]
  }

  if (!props.data) return null

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.title}>
          {t('active_users')}
          <div className={classes.subTitle}>
            {t('active_users_28d_description')}
          </div>
        </div>

        <div className={classes.topRightContainer}>
          <Select
            value={scope}
            options={getScopeOptions()}
            onChange={setScope}
            small
          />
        </div>
      </div>
      <ReactApexChart
        key={`chart-`}
        options={getOptions()}
        series={getSeries()}
        type='area'
        height={350}
      />

    </div>
  )
}

export default ActiveUsersChart

