import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1rem',
    backgroundColor: Colors.cardPreviewBackground,
    padding: '1.5rem 1.5rem 1rem',
    border: `1px solid ${Colors.lightBorder}`
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem'
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    paddingBottom: '.25rem'
  },
  description: {
    fontSize: '1rem',
    fontWeight: 300,
    paddingBottom: '.75rem',
    wordBreak: 'break-word'
  }
}))

const CouponSummary = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getCategory = () => {
    const matches = props.categories.filter(cat => (props.categoryIds || []).includes(cat.id))
    const names = matches.map(match => match.titleFi)
    return names.length ? names.join(', ') : '-'
  }

  const getOrganization= () => {
    const match = props.organizations.find(org => org.id === props.organizationId)
    if (match) return match.name
    return '-'
  }

  const getTitle = () => {
    return props.titleFi || '-'
  }

  const getContent = () => {
    return props.contentFi || '-'
  }

  const getValidity = () => {
    const { validFrom, validUntil } = props
    const a = validFrom ? moment(validFrom).format('DD.MM.YYYY') : ''
    const b = validUntil ? moment(validUntil).format('DD.MM.YYYY') : ''
    return `${a} - ${b}`.trim()
  }

  const getUseTimes = () => {
    if (props.useTimes === 0) return t('no_limit')
    return props.useTimes
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {t('coupon_summary')}
      </div>
      {!props.categories ? null : (
        <>
          <div className={classes.subTitle}>
            {t('category')}
          </div>
          <div className={classes.description}>
            {getCategory()}
          </div>
        </>
      )}

      <div className={classes.subTitle}>
        {t('organization')}
      </div>
      <div className={classes.description}>
        {getOrganization()}
      </div>

      <div className={classes.subTitle}>
        {t('title')}
      </div>
      <div className={classes.description}>
        {getTitle()}
      </div>

      <div className={classes.subTitle}>
        {t('description')}
      </div>
      <div className={classes.description}>
        {getContent()}
      </div>

      <div className={classes.subTitle}>
        {t('validity')}
      </div>
      <div className={classes.description}>
        {getValidity()}
      </div>

      <div className={classes.subTitle}>
        {t('use_times')}
      </div>
      <div className={classes.description}>
        {getUseTimes()}
      </div>
    </div>
  )
}

export default CouponSummary
