import { get } from 'lodash'
import moment from 'moment'

// This will return error translation key if not valid or null if all ok
export const validateCouponCampaign = (couponCampaign, onlyFi = false) => {
  // Don't validate drafts
  if (couponCampaign.draft) return null

  const isMinLength = (str, minLength) => str && str.trim().length >= minLength

  const {
    numRequiredCodes,
    startDate,
    endDate,
    organizationIds
  } = couponCampaign

  const requiredLocalizedFields = [
    'name',
    'title',
    'html',
    'notificationTitle',
    'notificationHtml'
  ]

  if (!numRequiredCodes) {
    return 'fill_all_required_fields'
  }

  // Validate Fi fields
  for (const field of requiredLocalizedFields) {
    if (!isMinLength(get(couponCampaign, `${field}Fi`), 1)) {
      return 'fill_all_required_fields'
    }
  }

  // Validate En fields
  if (!onlyFi) {
    for (const field of requiredLocalizedFields) {
      if (!isMinLength(get(couponCampaign, `${field}En`), 1)) {
        return 'fill_all_required_fields'
      }
    }
  }

  // Check that start date is not after end date
  if (startDate && endDate) {
    if (moment(endDate).isBefore(startDate)) {
      return 'error_start_date_after_end_date'
    }
  }


  // Check required QR codes <= participating organizations
  if (numRequiredCodes > organizationIds.length) {
    return 'error_required_qr_codes_higher_than_participating_organizations'
  }

  // All seems to be ok
  return null
}
