import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { get } from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../../../Models/RootStore'
import { Colors } from '../../../Utils/theme'
import { PageContainer, PageContent, PageHeader, UsageStatisticsSummary, ScreenAnalyticsTable, ScreenGroupUsageChart, AnalyticsDateRangeSelector, FeedbackFaultReportsChart } from '../../../Components'
import ActiveUsersChart from '../../../Components/Analytics/ActiveUsersChart'

const useStyles = makeStyles({
  pageContentInner: {
    maxWidth: 12000
  },
  monthlyContainer: {
    padding: '.75rem'
  },
  topCotainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem'
  },
  countCard: {
    flex: 1,
    textAlign: 'center',
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1rem 2rem',
    margin: '.5rem',
    borderRadius: '.3125rem'
  },
  countTitle: {
    fontWeight: 700,
    fontSize: '1.125rem',
    color: Colors.boxTitle
  },
  countValue: {
    fontSize: '3rem',
    color: Colors.brandColor2,
    fontWeight: 700
  },
  countInfo: {
    opacity: 0.6
  },
  spacer: {
    height: '1.85rem'
  },
  bigSpacer: {
    height: '2.5rem'
  },
  scrollContainer: {
    width: '100%',
    overflowX: 'auto'
  }
})

function Analytics() {
  const classes = useStyles()
  const { t } = useTranslation()

  const { analyticsStore, pollStore } = useStore()
  const [tab, setTab] = useState(0)
  const selectTab = (evt, value) => {
    setTab(value)
    setActiveGroup(null)
  }

  // eslint-disable-next-line
  const [mountedAt, setMountedAt] = useState(Date.now())
  const [startDate, setStartDate] = useState(moment().subtract(28, 'days').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [activeGroup, setActiveGroup] = useState(null)


  const loadDateData = (start, end) => {
    analyticsStore.getUsageSummary(start, end)
    analyticsStore.getFullDimensionalScreenViews(start, end)
    analyticsStore.getFullDimensionalScreenViews(start, end)
    analyticsStore.getDemographics28d(end)
    analyticsStore.getFeedbackSums(start, end)
    analyticsStore.getCustomEvents(start, end)
  }

  useEffect(() => {
    analyticsStore.getAnalytics()

    // Load full set of data
    analyticsStore.getActiveUsers('2019-02-01', moment().format('YYYY-MM-DD'))

    // Load date specific stuff
    loadDateData(startDate, endDate)

    analyticsStore.getInvolvementAnalytics()
    pollStore.getPolls()
  }, [])

  const toPreviousDateSet = () => {
    const newStartDate = moment(startDate).subtract(28, 'days').format('YYYY-MM-DD')
    const newEndDate = moment(endDate).subtract(28, 'days').format('YYYY-MM-DD')
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    // Load date specific stuff
    loadDateData(newStartDate, newEndDate)
  }

  const toNextDateSet = () => {
    const newStartDate = moment(startDate).add(28, 'days').format('YYYY-MM-DD')
    const newEndDate = moment(endDate).add(28, 'days').format('YYYY-MM-DD')
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    // Load date specific stuff
    loadDateData(newStartDate, newEndDate)
  }

  const handleSetStartDate = (date) => {
    const newStartDate = moment(date).format('YYYY-MM-DD')
    setStartDate(newStartDate)

    if (newStartDate && endDate && !moment(endDate).isBefore(newStartDate)) {
      loadDateData(newStartDate, endDate)
    }
  }

  const handleSetEndDate = (date) => {
    const newEndDate = moment(date).format('YYYY-MM-DD')
    setEndDate(newEndDate)

    if (startDate && newEndDate && !moment(newEndDate).isBefore(startDate)) {
      loadDateData(startDate, newEndDate)
    }
  }

  const renderDownloadCounts = () => {
    if (analyticsStore.analytics) {
      const downloadsProgress = `+${(Math.round(analyticsStore.analytics.downloadsProgress30d * 1000)/10)}%`
      return (
        <div className={classes.topCotainer}>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('all_downloads')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.totalDownloads}</p>
            <p className={classes.countInfo}>{t('psc_total')}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('android_downloads')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.androidDownloads}</p>
            <p className={classes.countInfo}>{t('device_installs')}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('ios_downloads')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.iosDownloads}</p>
            <p className={classes.countInfo}>{t('device_installs')}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('downloads_progress')}</p>
            <p className={classes.countValue}>{downloadsProgress}</p>
            <p className={classes.countInfo}>{t('in_last_28d')}</p>
          </div>
          <div className={classes.countCard}>
            <p className={classes.countTitle}>{t('downloads_today')}</p>
            <p className={classes.countValue}>{analyticsStore.analytics.todayDownloads}</p>
            <p className={classes.countInfo}>{t('updated_at_time')} {moment(mountedAt).format('HH.mm')}</p>
          </div>
        </div>
      )
    }
    return null
  }

  const renderActiveGroupAdditionalCharts = () => {
    if (activeGroup === 'influence') {
      return (
        <>
          <FeedbackFaultReportsChart
            feedbackSums={analyticsStore.feedbackSums}
          />
          <div className={classes.spacer} />
        </>
      )
    }
    return null
  }

  const renderContent = () => {
    if (tab === 1) {
      if (activeGroup === null) {
        return (
          <>
            <AnalyticsDateRangeSelector
              startDate={startDate}
              endDate={endDate}
              setStartDate={handleSetStartDate}
              setEndDate={handleSetEndDate}
              onNext={toNextDateSet}
              onPrevious={toPreviousDateSet}
            />
            <div className={classes.spacer} />
            <div className={classes.scrollContainer}>
              <UsageStatisticsSummary
                activeUserTypes={get(analyticsStore.analytics, 'activeUserTypes', null)}
                appVersions={analyticsStore.appVersions}
                demographics={analyticsStore.demographics28d}
              />
            </div>
            <div className={classes.scrollContainer}>
              <ScreenAnalyticsTable
                items={analyticsStore.screenViews}
                previousItems={analyticsStore.previousScreenViews}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
              />
            </div>
          </>
        )
      } else {
        return (
          <>
            <AnalyticsDateRangeSelector
              startDate={startDate}
              endDate={endDate}
              setStartDate={handleSetStartDate}
              setEndDate={handleSetEndDate}
              onNext={toNextDateSet}
              onPrevious={toPreviousDateSet}
            />
            <div className={classes.spacer} />
            <div className={classes.scrollContainer}>
              <ScreenGroupUsageChart
                data={analyticsStore.fullDimensionalScreenViews || []}
                activeGroup={activeGroup}

                startDate={startDate}
                endDate={endDate}
                onNext={toNextDateSet}
                onPrevious={toPreviousDateSet}
              />
            </div>
            <div className={classes.bigSpacer} />
            <div className={classes.scrollContainer}>
              {renderActiveGroupAdditionalCharts()}
            </div>
            <div className={classes.scrollContainer}>
              <ScreenAnalyticsTable
                items={analyticsStore.screenViews}
                previousItems={analyticsStore.previousScreenViews}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
                activeUsers={analyticsStore.screenViewActiveUsers}

                startDate={startDate}
                endDate={endDate}
                onNext={toNextDateSet}
                onPrevious={toPreviousDateSet}

                customEvents={analyticsStore.customEvents}
              />
            </div>
          </>
        )
      }
    }

    return (
      <>
        {renderDownloadCounts()}
        <div className={classes.monthlyContainer}>
          <ActiveUsersChart
            startDate={startDate}
            endDate={endDate}
            data={get(analyticsStore, 'activeUsers', null)}
          />
        </div>
      </>
    )
  }

  if (!analyticsStore.analytics) return null

  return (
    <>
      <PageHeader
        title={t('analytics')}
        tabs={[
          { id: 0, label: t('downloads_and_user_counts') },
          { id: 1, label: t('usage_statistics') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        <PageContent>
          <div className={classes.pageContentInner}>
            {renderContent()}
          </div>
        </PageContent>
      </PageContainer>
    </>
  )
}

export default observer(Analytics)
