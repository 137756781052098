import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  spacer: {
    width: '.75rem'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  tab: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    backgroundColor: Colors.inputGrey,
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.125rem',
    padding: '.45rem .625rem',
    borderRadius: '.3125rem'
  },
  tabActive: {
    fontWeight: 600,
  },
  redLight: {
    background: Colors.red,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  greenLight: {
    background: Colors.green,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  yellowLight: {
    background: Colors.yellow,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  }
}))

export default function LangSwitch(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderStatus = (lang) => {
    const status = lang === 'fi' ? props.statusFi : props.statusEn
    if (status === 'red') {
      return <div className={classes.redLight} />
    } else if (status === 'green') {
      return <div className={classes.greenLight} />
    } else if (status === 'yellow') {
      return <div className={classes.yellowLight} />
    }
    return null
  }

  const renderTabs = () => {
    const fiActive = props.currentLanguage === 'fi'
    const enActive = props.currentLanguage === 'en'

    return (
      <div className={classes.tabsContainer}>
        <button
          className={fiActive ? [classes.tab, classes.tabActive].join(' ') : classes.tab}
          onClick={() => props.setLanguage('fi')}
        >
          {t('lang_short_fi')}
          {renderStatus('fi')}
        </button>
        {!props.disableEn && (
          <>
            <div className={classes.spacer} />
            <button
              className={enActive ? [classes.tab, classes.tabActive].join(' '): classes.tab}
              onClick={() => props.setLanguage('en')}
            >
              {t('lang_short_en')}
              {renderStatus('en')}
            </button>
          </>
        )}
      </div>
    )
  }

  return renderTabs()
}
