import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1rem',
    backgroundColor: Colors.cardPreviewBackground,
    padding: '1.5rem 1.5rem 1rem',
    border: `1px solid ${Colors.lightBorder}`
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem'
  },
  subTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    paddingBottom: '.25rem'
  },
  description: {
    fontSize: '1rem',
    fontWeight: 300,
    paddingBottom: '.75rem',
    wordBreak: 'break-word'
  }
}))

const CouponOrganizationSummary = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderCategory = () => {
    if (props.categoryId) {
      const match = (props.categories || []).find(cat => cat.id === props.categoryId)
      if (match) return match.titleFi || match.titleEn || '-'
    }
    return '-'
  }

  const renderAltCategories = () => {
    const altCats = props.altCategoryIds || []
    const names: string[] = []
    for (const catId of altCats) {
      const match = (props.categories || []).find(cat => cat.id === catId)
      if (match) names.push(match.titleFi || match.titleEn || '-')
    }
    return names.join(', ') || '-'
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {t('company_summary')}
      </div>
      <div className={classes.subTitle}>
        {t('company_name')}
      </div>
      <div className={classes.description}>
        {props.name || '-'}
      </div>
      <div className={classes.subTitle}>
        {t('primary_category')}
      </div>
      <div className={classes.description}>
        {renderCategory()}
      </div>
      <div className={classes.subTitle}>
        {t('additional_categories')}
      </div>
      <div className={classes.description}>
        {renderAltCategories()}
      </div>
      <div className={classes.subTitle}>
        {t('url')}
      </div>
      <div className={classes.description}>
        {props.url || '-'}
      </div>
      <div className={classes.subTitle}>
        {t('phone')}
      </div>
      <div className={classes.description}>
        {props.phone || '-'}
      </div>
      <div className={classes.subTitle}>
        {t('email')}
      </div>
      <div className={classes.description}>
        {props.email || '-'}
      </div>
      <div className={classes.subTitle}>
        {t('address')}
      </div>
      <div className={classes.description}>
        {props.address || '-'}
      </div>
    </div>
  )
}

export default CouponOrganizationSummary
