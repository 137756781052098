import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import moment from 'moment'
import PollUserTable from '../Poll/PollUserTable/PollUserTable'

const useStyles = makeStyles((theme) =>({
  container: {
  }
}))

interface Answer {
  id: number;
  deviceId: string;
  testId: number;
  questionId: string | null;
  answerIndex: number | null;
  textAnswer: string | null;
  createdAt: string;
  updatedAt: string;
  device?: Device
}


interface Device {
  id: string;
  usageType: string | null;
  locale: string;
  os: string;
  index: number;
}

type UserAnswersProps = {
  devices: Device[]
  answers: Answer[]
  id: number
  path: string 
}

function UserAnswers ({devices = [], answers = [], id, path}: UserAnswersProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getItems = () => {
    if (!devices.length) return null
    return devices.map(device => {
      let createdAt = ''
      const firstAnswer = answers.find(ans => ans.deviceId === device.id)
      if (firstAnswer) createdAt = firstAnswer.createdAt
      return {
        href: `/${path}/${id}/users/${device.index + 1}`,
        index: `${t('user')} ${device.index + 1}`,
        createdAt: createdAt ? moment(createdAt).format('DD.MM.YYYY') : '-',
        usageType: device.usageType ? t(device.usageType) : t('not_set'),
        os: device.os === 'ios' ? t('ios') : t('android'),
        locale: device.locale === 'en' ? t('english') : t('finnish')
      }
    })
  }

  return (
    <div className={classes.container}>
      <PollUserTable
        items={getItems()}
      />
    </div>
  )
}

export default UserAnswers
