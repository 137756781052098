import React from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import CouponCampaignParticipationChart from './CouponCampaignParticipationChart'

const useStyles = makeStyles((theme) =>({
  boxRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  spacer: {
    width: '2rem'
  },
  box: {
    borderRadius: '.3125rem',
    flex: 1,
    border: `1px solid ${Colors.lightBorder}`,
    padding: '1.5rem 1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '1.125rem',
    color: Colors.boxTitle,
    lineHeight: 1,
    marginBottom: '.75rem'
  },
  value: {
    textAlign: 'center',
    fontSize: '3rem',
    color: Colors.brandColor2,
    fontWeight: 700,
    lineHeight: 1
  }
}))

function CouponCampaignAnalytics (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const spacePadInteger = (number) => {
    const result = `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    if (result === 'undefined') return '-'
    return result
  }

  const formatRatio = (ratio) => {
    return `${Math.round(ratio * 1000) / 10} %`
  }

  const renderBoxes = () => {
    const {
      isParticipatingCount,
      notParticipatingCount,
      useCount,
      allCodesCollectedCount
     } = props.analytics

    const data = [
      [
        {
          title: t('campaign_analytics_openings'),
          value: spacePadInteger(isParticipatingCount + notParticipatingCount)
        },
        {
          title: t('campaign_analytics_participations'),
          value: spacePadInteger(isParticipatingCount)
        },
        {
          title: t('campaign_analytics_conversion'),
          value: (isParticipatingCount || notParticipatingCount) ? formatRatio(isParticipatingCount / (isParticipatingCount + notParticipatingCount)) : '-'
        }
      ],
      [
        {
          title: t('campaign_analytics_all_codes_collected'),
          value: spacePadInteger(allCodesCollectedCount)
        },
        {
          title: t('campaign_analytics_prize_received'),
          value: spacePadInteger(useCount)
        },
        {
          title: t('campaign_analytics_lottery_directed'),
          value: props.lotteryLinkOpensCount || '-'
        }
      ]
    ]

    return data.map(row => {

      return (
        <div className={classes.boxRow}>
          {row.map((item, index) => {
            return (
              <>
                {!!index && <div className={classes.spacer} />}
                <div className={classes.box}>
                  <div className={classes.title}>{item.title}</div>
                  <div className={classes.value}>{item.value}</div>
                </div>
              </>
            )
          })}
        </div>
      )
    })
  }

  return (
    <div>
      {renderBoxes()}
      <CouponCampaignParticipationChart
        monthData={get(props.analytics, 'participationsByMonth', [])}
        weekData={get(props.analytics, 'participationsByWeek', [])}
      />
    </div>
  )
}

export default CouponCampaignAnalytics
