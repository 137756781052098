import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import DataTableRow from './DataTableRow'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500
  },
  spacer: {
    height: '1.25rem'
  },
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: 50,
    padding: '0 1rem',
    color: Colors.label,
    fontFamily: Fonts.content,
    fontSize: '1.25rem',
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }
}))

function DataTable (props) {
  const classes = useStyles()

  const renderRows = () => {
    if (props.data) {
      return props.data.map((columns, index) => <DataTableRow key={index} data={columns} />)
    }
    return null
  }

  const renderHeader = () => {
    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          {props.columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.firstCell}`
            else if (index === props.columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`

            return <TableCell key={columnName} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <Table classes={{ root: classes.root }}>
      {renderHeader()}
      <div className={classes.spacer} />
      <TableBody>{renderRows()}</TableBody>
    </Table>
  )

}

export default DataTable
