import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { createStore, StoreProvider } from './Models/RootStore'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './Utils/theme'
import './Localization'
import './index.css'

const rootStore = createStore()

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
