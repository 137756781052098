import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import * as moment from 'moment'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter, useHistory } from 'react-router-dom'
import { PageHeader,  DataActions, CouponCampaignTable, PageContainer, PageContent } from '../../../Components'
import { CouponSortOptions, UserSortOptions } from '../../../Constants'

function CouponCampaigns(props) {
  const { t } = useTranslation()
  const { couponStore, couponCampaignStore } = useStore()

  let initialTab = parseInt(qs.parse(props.location.search, { ignoreQueryPrefix: true }).tab || '0', 10)
  const history = useHistory()

  useEffect(() => {
    couponCampaignStore.getCouponCampaigns()
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getNotifications()
  }, [])

  const [tab, setTab] = useState(initialTab)

  const selectTab = (evt, value) => {
    const params = new URLSearchParams()
    params.append('tab', value)

    history.push({search: params.toString()})
    setTab(value)
  }

  const tabs = [
    { id: 0, label: t('active_campaigns') },
    { id: 1, label: t('ended_campaigns') },
    { id: 2, label: t('draft_campaigns') },
    { id: 3, label: t('deleted_campaigns') }
  ]

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  const toCreate = () => {
    props.history.push('/campaigns/add')
  }


  const getCouponCampaigns = () => {
    let couponCampaigns = couponCampaignStore.couponCampaigns

    couponCampaigns = couponCampaigns.filter(item => {
      if (tab === 0) {
        // Active
        return item.active && !item.draft && (!item.endDate || moment().isBefore(item.endDate))
      }
      if (tab === 1) {
        // Ended
        return !item.draft && item.endDate && moment().isAfter(item.endDate)
      }
      if (tab === 2) {
        // Draft
        return item.draft
      }
      if (tab === 3) {
        return !item.active
      }
      return true
    })

    // Sort deleted last
    couponCampaigns = orderBy(couponCampaigns, 'active', 'desc')

    return couponCampaigns
  }


  const renderTable = () => {
    return <CouponCampaignTable items={getCouponCampaigns()} />
  }

  return (
    <>
      <PageHeader
        title={t('coupon_campaigns')}
        tabs={tabs}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate}
        createText={t('add_new')}
      />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={CouponSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(CouponCampaigns))
