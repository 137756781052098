import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as moment from 'moment'
import * as get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { Checkbox } from '../../Components'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    cursor: 'pointer',
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  organizationName: {
    fontWeight: 500,
    fontSize: '1.125rem',
    color: Colors.black
  },
  noPaddingCell: {
    paddingLeft: 0
  },
  checkboxCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none',
    maxWidth: '2.5rem'
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  categoryBadge: {
    fontFamily: Fonts.content,
    background: Colors.categoryBadgeBackground,
    color: Colors.categoryBadgeText,
    fontSize: '0.875rem',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: '.3125rem',
    padding: '.5rem .75rem',
    fontWeight: 400
  }
}))

function CouponOrganizationTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = () => props.history.push(`/coupons/organization/${item.id}`)

  const statusClass = item.active ? '' : classes.deleted

  const renderCategory = () => {
    if (item.category) {
      return (
        <div className={classes.categoryBadge}>
          {item.category.titleFi || item.category.titleEn}
        </div>
      )
    }
  }

  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell className={classes.checkboxCell}>
        <Checkbox
          checked={item.selected}
          onChange={() => props.toggleSelectedOrganization(item.id)}
          small
        />
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.noPaddingCell].join(' ')}>
        <div className={classes.row}>
          <span className={classes.organizationName}>{item.name}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        <span>{item.couponCount}</span>
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        <span>{item.alwaysVisible ? t('yes') : t('no')}</span>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{get(item, 'address')}</TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{moment(item.createdAt).format('DD.MM.YYYY')}</TableCell>
      <TableCell onClick={handleClick} className={`${classes.cell} ${classes.lastCell}`}>
        {renderCategory()}
      </TableCell>
    </TableRow>
  )
}

export default withRouter(CouponOrganizationTableRow)
