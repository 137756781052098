import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({

  container: {
    overflowX: 'auto',
    padding: '1rem',
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: '.3125rem',
    marginBottom: '1rem'
  },
  title: {
    fontWeight: 600,
    color: Colors.boxTitle,
    fontSize: '1.5rem'
  },
  subTitle: {
    color: Colors.boxTitle,
    fontSize: '.875rem',
    marginBottom: '1rem'
  }
}))

function FeedbackFaultReportsChart(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getSeries = () => {
    return [
      {
        name: 'Palautteet',
        data: props.feedbackSums.map(x => {
          return x.cityFeedbacks
        })
      }, {
        name: 'Vikaraportit',
        data: props.feedbackSums.map(x => {
          return x.faultReports
        })
      }
    ]
  }

  const getOptions = () => {
    return {
      chart: {
        type: 'bar',
        height: 350,
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },
      colors: [
        Colors.chartBlue,
        Colors.chartRed,
        Colors.chartGrey
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['#fff']
      },
      xaxis: {
        categories: props.feedbackSums.map(item => moment(item.date).format('DD.MM.')),
        axisBorder: {
          show: false // According to layout
        },
        axisTicks: {
          show: false, // According to layout
        }
      },
      legend: {
        position: 'top',
        fontFamily: Fonts.content,
        itemMargin: {
          horizontal: 16
        },
        offsetY: -2
      },
      fill: {
        opacity: 1
      },
      grid: {
        show: false // According to layout
      },
      dataLabels: {
        enabled: false,
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t('feedbacks_and_fault_reports')}</div>
      <div className={classes.subTitle}>{t('in_28d_time_range')}</div>
      <Chart
        key={`chart-`}
        options={getOptions()}
        series={getSeries()}
        type='bar'
        height={350}
      />
    </div>
  )
}

export default FeedbackFaultReportsChart
