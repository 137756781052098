import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../Models/RootStore';
import { PageHeader, PageContainer, PageContent } from '../../../Components';
import UserAnswerResults from '../../../Components/Common/UserAnswerResults';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
}));

const PollUserAnswer = () => {
  const classes = useStyles();
  const { pollStore } = useStore();
  const { pollId, userId }: any = useParams();

  useEffect(() => {
    pollStore.getPoll(pollId);
    // Note: device indexes are zero indexed
    pollStore.getPollUserAnswers(Number(pollId), (Number(userId) || 0) - 1);
  }, []);

  const renderContent = () => {

    return (
      <div className={classes.container}>
        <UserAnswerResults
          totalAnswers={pollStore.poll.totalAnswers}
          userAnswers={pollStore.pollUserAnswers || []}
          totalViews={pollStore.poll.totalViews}
          questions={pollStore.poll.questions}
        />
      </div>
    );
  };

  const getTitle = () => {
    return (
      get(pollStore.poll, 'titleFi') || get(pollStore.poll, 'titleEn') || null
    );
  };

  return (
    <>
      <PageHeader title={getTitle()} backButtonLink={`/polls/${pollId}`} />
      <PageContainer>
        <PageContent size="medium">{renderContent()}</PageContent>
      </PageContainer>
    </>
  );
};

export default observer(PollUserAnswer);
