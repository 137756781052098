import { createMuiTheme, darken } from '@material-ui/core/styles'
import Color from 'color'

export const Fonts = {
  heading: 'Rubik',
  content: 'Roboto'
}

// Brand color palette
const BRAND = {
  heading: '#000000',
  content: '#333333',
  brandColor1: '#EB5E57',
  brandColor2: '#00417D'
}

// Brand colors
const THEME = {
  brandColor1: BRAND.brandColor1,
  brandColor2: BRAND.brandColor2,

  heading: BRAND.heading,
  content: BRAND.content,

  primaryButton: BRAND.brandColor1,
  secondaryButton: '#000000',
  sidebarBackground: BRAND.brandColor2,
  tableHeaderBackground: BRAND.brandColor2,
  cardBackground: Color(BRAND.brandColor2).alpha(0.05).string()
}

export const Colors = {
  // Named colors
  black: '#000000',
  black20: 'rgba(0, 0, 0, .2)',
  black50: 'rgba(0, 0, 0, .5)',
  black70: 'rgba(0, 0, 0, .7)',
  white: '#ffffff',
  white80: 'rgba(255, 255, 255, .8)',
  green: '#3EB973', // use only for success, valid etc.
  yellow: '#fcdb03',
  red: '#FF1744', // use only for failure / alert / delete etc.
  lightGrey: '#f4f4f4',
  brandColor1: BRAND.brandColor1,
  brandColor2: BRAND.brandColor2,
  excelGreen: '#20744B',
  boxTitle: '#4f4f4f',
  border: '#cccccc',

  // Inputs
  inputGrey: '#F2F6F9',
  disabledInput: '#dddfe0',
  // Buttons
  primaryButtonColor: THEME.primaryButton,
  primaryButtonHoverColor: Color(THEME.primaryButton).alpha(0.9).string(),
  secondaryButtonColor: THEME.secondaryButton,
  secondaryButtonHoverColor: Color(THEME.secondaryButton).alpha(0.9).string(),

  // Texts
  heading: THEME.heading,
  content: THEME.content,
  label: '#4c4c4c',

  // Backgrounds
  sidebarBackgroundColor: THEME.sidebarBackground,
  sidebarButton: darken(THEME.sidebarBackground, 0.2),
  sidebarButtonHover: darken(THEME.sidebarBackground, 0.4),
  pageHeaderBackgroundColor: THEME.cardBackground,
  cardBackgroundColor: THEME.cardBackground,

  deletedOverlayColor: 'rgba(225, 225, 225, .5)',

  // Tables
  tableHeaderBackgroundColor: THEME.tableHeaderBackground,
  tableHeaderTextColor: '#ffffff',
  tableContentColor: THEME.heading,
  tableRowBackgroundColor: THEME.cardBackground,
  tableRowBackgroundHover: Color(THEME.cardBackground).alpha(0.1).string(),

  cardPreviewBackground: '#F2F6F9',
  categoryBadgeBackground: 'rgba(0, 65, 125, 0.05)',
  categoryBadgeText: '#00417D',

  // Border
  lightBorder: 'rgba(0,0,0,.2)',

  // Chart colors
  chartRed: '#EB5E57',
  chartBlue: '#00417D',
  chartYellow: '#F4D240',
  chartGrey: '#aaaaaa'
}

// A custom theme for this app
const Theme = createMuiTheme({
  palette: {
    primary: {
      main: THEME.heading
    },
    secondary: {
      main: THEME.brandColor1
    },
    error: {
      main: Colors.red
    },
    background: {
      default: Colors.white
    }
  }
})

export default Theme
