import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, Grid, DataActions, PageContainer, PageContent, Dialog } from '../../../Components'
import { UserSortOptions } from '../../../Constants'
import PollCategories from './PollCategories'

function Polls(props) {
  const { t } = useTranslation()
  const { pollStore, userStore } = useStore()

  useEffect(() => {
    pollStore.getPolls()
    pollStore.getPollCategories()
    userStore.getMyOrganizationUsers()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)
  const [categoryValue, setCategoryValue] = useState('all')
  const [deleteId, setDeleteId] = useState(null)

  const [tab, setTab] = useState(0)
  const selectTab = (_, value) => {
    setTab(value)
  }
  const toCreate = () => {
    props.history.push('/polls/add')
  }

  const handleDelete = (id) => {
    if (deleteId) {
      pollStore.updatePoll(id, {
        active: false
      })
      setDeleteId(null)
    } else {
      setDeleteId(id)
    }
  }

  /* Polls */
  const getPolls = () => {
    let polls = pollStore.polls
    if (searchValue) {
      polls = polls.filter((poll) => {
        const { titleFi = '', contentFi = '', titleEn = '', contentEn = '' } = poll
        const targets = [titleFi, titleEn, contentFi, contentEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      polls = orderBy(polls, 'createdAt', 'desc')
    } else {
      polls = orderBy(polls, 'titleFi', 'asc')
    }

    if (categoryValue && categoryValue !== 'all') {
      polls = polls.filter(poll => poll.categoryId === categoryValue)
    }

    // Filter
    polls = polls.filter(item => {
      // Deleted
      if (tab === 4) return !item.active

      // Draft
      if (tab === 3) return item.draft

      // Dont display deleted or drafts for other tabs
      if (!item.active || item.draft) return false

      // Ended
      if (tab === 2) {
        return moment().isAfter(item.validUntil)
      }

      // Upcoming
      if (tab === 1) {
        return moment().isBefore(item.validFrom)
      }

      // Active
      if (tab === 0) {
        return moment().isAfter(item.validFrom) && (!item.validUntil || moment().isBefore(item.validUntil))
      }

      return true
    })

    // Sort deleted last
    polls = orderBy(polls, 'active', 'desc')

    return polls
  }

  const renderGrid = () => {
    return <Grid items={getPolls()} onDelete={handleDelete} name="polls" />
  }

  const renderContent = () => {
    if (tab === 5) {
      // Render categories
      return (
        <PollCategories />
      )
    }

    // Render polls
    return (
      <>
        <DataActions
          searchPlaceholder={t('search_by_name')}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          sortOptions={UserSortOptions}
          sortValue={sortValue}
          onSortChange={setSortValue}
          categoryOptions={getCategoryOptions()}
          categoryValue={categoryValue}
          onCategoryChange={setCategoryValue}
        />
        {renderGrid()}
      </>
    )
  }

  const getCategoryOptions = () => {
    const categories = pollStore.pollCategories.map(item => ({
      label: item.titleFi || item.titleEn,
      value: item.id
    }))
    return [
      {
        label: t('all_categories'),
        value: 'all'
      },
      ...categories
    ]
  }

  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={!!deleteId}
        handleClose={() => setDeleteId(null)}
        handleOk={() => handleDelete(deleteId)}
        title={t('delete_poll_title')}
        description={t('delete_poll_description')}
      />
    )
  }

  return (
    <>
      <PageHeader
        title={t('polls')}
        onCreateClick={tab !== 5 ? toCreate : null}
        tabs={[
          { id: 0, label: t('poll_tab_active') },
          { id: 1, label: t('poll_tab_upcoming') },
          { id: 2, label: t('poll_tab_ended') },
          { id: 3, label: t('poll_tab_drafts') },
          { id: 4, label: t('poll_tab_deleted') },
          { id: 5, label: t('poll_tab_categories') }
        ]}
        currentTab={tab}
        onTabChange={selectTab}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
          {renderDeleteDialog()}
        </PageContent>
      </PageContainer>
    </>
  )
}
export default withRouter(observer(Polls))
