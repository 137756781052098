import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PollUserTableRow from './PollUserTableRow'
import { Colors, Fonts } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  spacer: {
    height: '1.25rem'
  },
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: 50,
    padding: '0 1rem',
    color: Colors.label,
    fontFamily: Fonts.content,
    fontSize: '1.25rem',
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  emptyMessage: {
    fontWeight: 400,
    marginTop: '2rem',
    fontSize: '1.125rem',
    opacity: 0.5,
    textAlign: 'center'
  }
}))

function PollUserTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item, index) => (
        <PollUserTableRow
          key={index}
          item={item}
          odd={index % 2 === 0}
        />
      ))
    }
    return null
  }

  const renderHeader = () => {
    const columnNames = [
      t('id').toUpperCase(),
      t('answer_date_short'),
      t('user_profile'),
      t('device'),
      t('language_selection')
    ]

    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          {columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.firstCell}`
            else if (index === columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`

            return <TableCell key={columnName} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  const renderEmptyMessage = () => {
    if (!props.items || !props.items.length) {
      return (
        <div className={classes.emptyMessage}>{t('no_answers_yet')}</div>
      )
    }
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        {renderHeader()}
        <div className={classes.spacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {renderEmptyMessage()}
    </>
  )
}

export default PollUserTable
