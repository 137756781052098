import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, Dialog, TestForm, PageContainer, PageContent, PageActions } from '../../../Components'
import { useHistory } from 'react-router-dom'
import { Test } from '../../../Models/TestStore'
import TestActions from '../../../Components/Test/TestActions'
import { convertQuestionPointsToNumber } from './convertQuestionPointsToNumber'

const defaultTest: Test = {
  validFrom: null,
  validUntil: null,
  titleFi: '',
  titleEn: '',
  contentFi: '',
  contentEn: '',
  groups: [{
    id: uuidv4(),
    titleFi: '',
    titleEn: '',
    contentFi: '',
    contentEn: '',
    questions: [],
  }],
  userLang: null,
  userTypes: null,
  categoryId: null,
  photo: null,
  video: null,
  active: false,
  draft: true,
  notificationSentAt: null
}

const CreateTest = () => {
  const { t } = useTranslation()
  const history = useHistory();
  const { testStore } = useStore()

  // Implementation note: Not used at this time. adding categories later if needed for tests.
  // useEffect(() => {
  //   testStore.getTestCategories()
  // }, [])

  useEffect(() => {
    if (testStore.createOk) {
      history.push('/tests')
    }
  }, [testStore.createOk])


  const [test, setTest] = useState<Test>(defaultTest)
  const [missingTranslationsWarning, setMissingTranslationsWarning] = useState(false)

  const missingTranslations = () => {
    let missingTranslation = !test.titleFi || !test.titleEn || !test.contentFi || !test.contentEn
    // Check questions
    for (const g of test.groups) {
      for (const q of g.questions) {
        if (!q.titleFi || !q.titleEn || !q.contentFi || !q.contentEn) {
          missingTranslation = true
        }
      }
    }
    return missingTranslation
  }


  const handleSave = () => {
    if (!test.userLang && missingTranslations() && !missingTranslationsWarning) {
      return setMissingTranslationsWarning(true)
    }

    const testToCreate = {...test }
    if (!test.validFrom) {
      testToCreate.validFrom = moment().startOf('day').toISOString()
    } else {
      testToCreate.validFrom = moment(test.validFrom).seconds(0).toISOString()
    }

    if (test.validUntil) {
      testToCreate.validUntil = moment(test.validUntil).seconds(0).toISOString()
    }

    // Handle upload
    if (test.photo instanceof File) {
      testToCreate.newPhoto = test.photo
    }
  
    testStore.createTest(convertQuestionPointsToNumber(testToCreate))
  }

  return (
    <>
      <PageHeader
        title={t('create_test')}
      />
      <PageContainer>
        <PageContent size='small'>
          <TestForm test={test} onChange={setTest}  categories={[]} />
        </PageContent>
        <PageActions>
          <TestActions
            test={test}
            onChange={setTest}
            onCancel={() => window.location.reload()}
            onSave={handleSave}
          />
          <Dialog
            open={missingTranslationsWarning}
            handleClose={() => setMissingTranslationsWarning(false)}
            handleOk={handleSave}
            title={t('test_missing_translation_title')}
            description={t('test_missing_translation_content')}
            okText={t('save_without_translation')}
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateTest)
