import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  infoText: {
    fontSize: '1.5rem',
    color: Colors.content,
    fontFamily: Fonts.content
  }
}))

function DataMessage (props) {
  const classes = useStyles()
  if (!props.data || !props.data.length) {
    return (
      <div className={classes.infoContainer}>
        <p className={classes.infoText}>{props.message}</p>
      </div>
    )
  }
  return null
}

export default DataMessage
