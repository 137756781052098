import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from './Button'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1rem 1rem 0'
  },
  titleText: {
    fontSize: '1.75rem',
    fontWeight: 400,
    color: Colors.black
  },
  description: {
    fontSize: '1.125rem',
    color: Colors.black
  },
  footerContainer: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    width: '1rem'
  }
}))

export default function BaseDialog(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='dialog-title'
        aria-describedby='dialog-description'
      >
        <div className={classes.dialogContainer}>
          <DialogTitle id='dialog-title'>
            <div className={classes.titleText}>
              {props.title}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='dialog-description'>
              <div className={classes.description}>
                {props.description}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className={classes.footerContainer}>
              <Button
                onClick={props.handleClose}
                text={props.dismissText || t('cancel')}
                secondary
                variant
              />
              <div className={classes.spacer} />
              <Button
                onClick={props.handleOk}
                text={props.okText || t('ok')}
              />
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}
