import React from 'react'
import { Button, Input, Select } from '..'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '../../Assets/Icons/search.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  searchContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '18.75rem',
    marginRight: '2.5rem'
  },
  sortContainer: {
    marginLeft: '1rem'
  },
  categoryContainer: {
    marginLeft: '-.5rem'
  },
  spacer: {
    flex: 1
  },
  smallSpacer: {
    width: '1rem'
  },
  searchIcon: {
    position: 'absolute',
    top: '0.9375rem',
    right: '1rem',
    opacity: 0.66,
    height: '1.25rem',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
})

function DataActions (props) {
  const classes = useStyles()

  const { t } = useTranslation()

  const renderMassActions = () => {
    if (!props.massActionsEnabled) return null

    return (
      <div className={classes.row}>
        <Select
          value={props.massAction}
          options={props.massActionOptions}
          onChange={props.setMassAction}
        />
        <div className={classes.smallSpacer} />
        <Button
          onClick={props.onPerformMassAction}
          text={t('accept')}
          disabled={props.massAction === 'none'}
        />
      </div>
    )
  }

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.searchContainer}>
        <Input
          placeholder={props.searchPlaceholder}
          value={props.searchValue}
          onChange={props.onSearchChange}
          whiteBackground
        />
        <img src={SearchIcon} className={classes.searchIcon} alt='search' />
      </div>
      {renderMassActions()}
      {!props.categoryOptions ? null : (
        <div className={classes.categoryContainer}>
          <Select
            options={props.categoryOptions}
            value={props.categoryValue}
            onChange={props.onCategoryChange}
            whiteBackground
          />
        </div>
      )}
      <div className={classes.spacer} />
      {!(props.filterOptions && props.filterOptions.length) ? null : (
        <div className={classes.sortContainer}>
          <Select
            options={props.filterOptions}
            value={props.filterValue}
            onChange={props.onFilterChange}
            whiteBackground
          />
        </div>
      )}
      {!(props.sortOptions && props.sortOptions.length) ? null : (
        <div className={classes.sortContainer}>
          <Select
            options={props.sortOptions}
            value={props.sortValue}
            onChange={props.onSortChange}
            whiteBackground
          />
        </div>
      )}
    </div>
  )
}

export default DataActions
