import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, CouponOrganizationTable, DataActions, PageContainer, PageContent } from '../../../Components'
import { UserSortOptions } from '../../../Constants'
import { makeStyles } from '@material-ui/core'
import { Colors } from '../../../Utils/theme'

const MASS_ACTIONS = {
  none: 'none',
  export: 'export', // Export coupon organization coupon uses as csv
  show: 'show', // Set alwaysVisible = true
  hide: 'hide' // Set alwaysVisible = false
}

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  innerLeft: {
    flex: 5
  },
  innerRight: {
    flex: 3
  },
  innerRightOffset: {
    position: 'relative',
    paddingLeft: '6rem',
    marginTop: '-5rem'
  },
  hr: {
    position: 'absolute',
    left: '3rem',
    top: '-1.75rem',
    width: 1,
    minHeight: 'calc(100vh - 4rem)',
    backgroundColor: Colors.lightBorder
  }
}))

function CouponOrganizations(props) {
  const { t } = useTranslation()
  const { couponStore } = useStore()
  const classes = useStyles()

  useEffect(() => {
    couponStore.getOrganizations()
  }, [])

  const [tab, setTab] = useState(0)

  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [massAction, setMassAction] = useState(MASS_ACTIONS.none)

  const performMassAction = () => {
    if (massAction === MASS_ACTIONS.none) {
      return // Do nothing
    }
    if (massAction === MASS_ACTIONS.export) {
      couponStore.exportOrganizationCouponUseCsv(selectedOrganizations)
    }
    if (massAction === MASS_ACTIONS.show) {
      couponStore.massEditOrganizations(selectedOrganizations, { alwaysVisible: true })
    }
    if (massAction === MASS_ACTIONS.hide) {
      couponStore.massEditOrganizations(selectedOrganizations, { alwaysVisible: false })
    }

    // Clear organization select after action
    setSelectedOrganizations([])
    setMassAction(MASS_ACTIONS.none)
  }

  const selectTab = (evt, value) => setTab(value)

  const tabs = [
    { id: 0, label: t('active_ones') },
    { id: 1, label: t('deleted_ones') }
  ]

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  const toggleSelectedOrganization = (id) => {
    // Try to remove organization
    const newOrgs: any = selectedOrganizations.filter(orgId => orgId !== id)
    if (newOrgs.length === selectedOrganizations.length) {
      // Need to add
      newOrgs.push(id)
    }
    setSelectedOrganizations(newOrgs)
  }

  const isAllOrganizationsSelected = () => {
    if (!selectedOrganizations.length) return false
    const visibleOrganizations = getOrganizations()
    if (visibleOrganizations.length !== selectedOrganizations.length) return false
    const filtered = visibleOrganizations.filter(org => !selectedOrganizations.includes(org.id))
    if (filtered.length !== 0) return false
    return true
  }

  const toggleSelectAllOrganizations = () => {
    if (isAllOrganizationsSelected()) {
      setSelectedOrganizations([])
    } else {
      const visibleOrganizations = getOrganizations()
      setSelectedOrganizations(visibleOrganizations.map(org => org.id))
    }
  }

  const toCreate = () => {
    props.history.push('/coupons/add-organization')
  }

  /* Organizations */
  const getOrganizations = () => {
    let organizations = couponStore.organizations.map(org => {
      return {
        ...org,
        selected: !!selectedOrganizations.includes(org.id)
      }
    })

    // Filter
    organizations = organizations.filter(item => {
      return tab === 1 ? !item.active : item.active
    })

    if (searchValue) {
      organizations = organizations.filter(org => {
        const { name, address, email, phone } = org
        const catFi = get(org, 'category.titleFi', '')
        const catEn = get(org, 'category.titleEn', '')
        const targets = [name, address, email, phone, catFi, catEn]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      organizations = orderBy(organizations, 'createdAt', 'desc')
    } else {
      organizations = orderBy(organizations, 'name', 'asc')
    }

    // Sort deleted last
    organizations = orderBy(organizations, 'active', 'desc')

    return organizations
  }

  const renderTable = () => {
    return (
      <CouponOrganizationTable
        items={getOrganizations()}
        selectedOrganizations={selectedOrganizations}
        toggleSelectedOrganization={toggleSelectedOrganization}
        allSelected={isAllOrganizationsSelected()}
        toggleSelectAll={toggleSelectAllOrganizations}
      />
    )
  }

  const getMassActionOptions = () => {
    return [
      {
        label: t('mass_actions'),
        value: MASS_ACTIONS.none
      },
      {
        label: t('export_organization_coupon_uses_csv'),
        value: MASS_ACTIONS.export
      },
      {
        label: t('coupon_organization_mass_action_show'),
        value: MASS_ACTIONS.show
      },
      {
        label: t('coupon_organization_mass_action_hide'),
        value: MASS_ACTIONS.hide
      }
    ]
  }

  const renderDataActions = () => {
    return (
      <div className={classes.innerContainer}>
        <div className={classes.innerLeft}>
          <DataActions
            massActionsEnabled={selectedOrganizations.length}
            massAction={massAction}
            setMassAction={setMassAction}
            massActionOptions={getMassActionOptions()}
            onPerformMassAction={performMassAction}
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <PageHeader
        title={t('organizations')}
        tabs={tabs}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate} // No create action for categories
        createText={t('create_organization')}
      />
      <PageContainer>
        <PageContent>
          {renderDataActions()}
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(CouponOrganizations))
