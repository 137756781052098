import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from './Models/RootStore'
import { Authorized, Unauthorized } from './Containers'
import { observer } from 'mobx-react'

const App = () => {
  const { sessionStore } = useStore()

  useEffect(() => {
    sessionStore.getMe()
  }, [])

  const renderRouter = () => {
    if (!sessionStore.initialized) return null
    if (sessionStore.isLoggedIn) {
      return <Authorized />
    }
    return <Unauthorized />
  }

  return (
    <BrowserRouter>
      {renderRouter()}
    </BrowserRouter>
  )
}

export default observer(App)
