import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  oddRoot: {
    backgroundColor: Colors.lightGrey
  },
  root: {
    backgroundColor: Colors.white
  },
  cell: {
    fontWeight: 300,
    color: Colors.tableContentColor,
    fontSize: '1.125rem'
  },
  link: {
    display: 'inline-block',
    textDecoration: 'none',
    color: Colors.black
  }
}))

function PollUserTableRow(props: any) {
  const classes = useStyles()

  const { item } = props

  const getCells = () => {
    return [
      item.index,
      item.createdAt,
      item.usageType,
      item.os,
      item.locale
    ]
  }

  return (
    <TableRow classes={{ root: props.odd ? classes.oddRoot : classes.root }}>
      {(getCells()).map((value, index) => {
        return (
          <TableCell classes={{ root: classes.cell }}>
            <Link key={index} to={item.href} className={classes.link}>
              {value}
            </Link>
          </TableCell>
        )
      })}
    </TableRow>
  )

}

export default PollUserTableRow
