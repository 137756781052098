import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PollCategoryTableRow from './PollCategoryTableRow'
import { Colors, Fonts } from '../../../Utils/theme'
import { TableHeader } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  spacer: {
    height: '1.25rem'
  },
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: 50,
    padding: '0 1rem',
    color: Colors.label,
    fontFamily: Fonts.content,
    fontSize: '1.25rem',
    fontWeight: 700
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  emptyMessage: {
    fontWeight: 400,
    marginTop: '2rem',
    fontSize: '1.125rem',
    opacity: 0.5,
    textAlign: 'center'
  }
}))

function PollCategoryTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item, index) => (
        <>
          <PollCategoryTableRow
            key={index}
            item={item}
            odd={index % 2 === 0}
          />
          {index !== props.items.length - 1 && (<div className={classes.spacer} />)}
        </>
      ))
    }
    return null
  }

  const renderHeader = () => {
    const columnNames = [
      t('category'),
      t('num_polls'),
      t('added_at'),
      t('added_by'),
      t('actions')
    ]

    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          {columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.firstCell}`
            else if (index === columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`

            return <TableCell key={columnName} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  const renderEmptyMessage = () => {
    if (!props.items || !props.items.length) {
      return (
        <div className={classes.emptyMessage}>{t('no_items')}</div>
      )
    }
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        <TableHeader columnNames={[t('category'), t('num_polls'), t('added_at'), t('added_by'), t('actions')]} />
        <div className={classes.spacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {renderEmptyMessage()}
    </>
  )
}

export default PollCategoryTable
