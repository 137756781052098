import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../../Utils/theme'
import { withRouter } from 'react-router'
import EditIcon from '../../../Assets/Icons/edit2.svg'
import DeleteIcon from '../../../Assets/Icons/delete3.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  truncated: {
    maxWidth: '16rem',
    maxHeight: '4em',
    overflow: 'hidden'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  firstCell: {
    fontWeight: 500,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontWeight: 500,
    width: '5rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionButton: {
    cursor: 'pointer',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.white,
    borderRadius: '.3125rem',
    height: '2rem',
    width: '2rem',
    '&:hover': {
      opacity: 0.8
    }
  },
  icon: {
    width: '1rem'
  }
}))

function PollCategoryTableRow (props) {
  const { item } = props
  const classes = useStyles()
  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={[classes.cell, classes.firstCell].join(' ')}>
        <div className={classes.truncated}>
          <span>{item.title}</span>
        </div>
      </TableCell>
      <TableCell className={[classes.cell].join(' ')}>
        {item.numPolls}
      </TableCell>
      <TableCell className={classes.cell}>
        {item.createdAt}
      </TableCell>
      <TableCell className={classes.cell}>
        <div className={classes.truncated}>
          <span>{item.creator}</span>
        </div>
      </TableCell>
      <TableCell className={[classes.cell, classes.lastCell].join(' ')}>
        <div className={classes.actions}>
          <div className={classes.actionButton} onClick={item.onEdit}>
            <img src={EditIcon} className={classes.icon} alt='edit' />
          </div>
          <div className={classes.actionButton} onClick={item.onDelete}>
            <img src={DeleteIcon} className={classes.icon} alt='delete' />
          </div>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default withRouter(PollCategoryTableRow)
