import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { useTranslation } from 'react-i18next'
import { DataMessage, Checkbox } from '..'
import CouponOrganizationTableRow from './CouponOrganizationTableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  root: {
  },
  smallSpacer: {
    height: '0.25rem'
  },
  spacer: {
    height: '1rem'
  },
  outerContainer: {
    borderBottom: 'none'
  },
  container: {
    borderBottom: 'none'
  },
  cell: {
    height: '2.5rem',
    padding: '0 1rem',
    backgroundColor: Colors.tableHeaderBackgroundColor,
    color: Colors.tableHeaderTextColor,
    fontWeight: 700,
    fontFamily: Fonts.heading,
    textTransform: 'uppercase'
  },
  firstCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    maxWidth: '2.5rem'
  },
  noPaddingCell: {
    paddingLeft: 0
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  }
}))

function CouponOrganizationTable (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.spacer} />
          <CouponOrganizationTableRow
            item={item}
            selectedOrganizations={props.selectedOrganizations}
            toggleSelectedOrganization={props.toggleSelectedOrganization}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const renderHeader = () => {
    const columnNames = [
      t('name'),
      t('coupons'),
      t('always_visible'),
      t('address'),
      t('added_at'),
      t('category')
    ]

    return (
      <TableHead className={classes.outerContainer}>
        <TableRow className={classes.container}>
          <TableCell key='checkbox' className={[classes.cell, classes.firstCell].join(' ')}>
            <Checkbox
              checked={props.allSelected}
              onChange={props.toggleSelectAll}
              small
            />
          </TableCell>
          {columnNames.map((columnName, index) => {
            let className = classes.cell
            if (index === 0) className = `${classes.cell} ${classes.noPaddingCell}`
            if (index === columnNames.length - 1) className = `${classes.cell} ${classes.lastCell}`
            return <TableCell key={index} className={className}>{columnName}</TableCell>
          })}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <>
      <Table classes={{ root: classes.root }}>
        {renderHeader()}
        <div className={classes.smallSpacer} />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage data={props.items} message={t('no_items')} />
    </>
  )
}

export default CouponOrganizationTable
