import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { formatDateTime } from '../../Utils/dateTime'
import { Button } from '../../Components'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  firstCell: {
    fontWeight: 500,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  valid: {
    color: Colors.green,
    fontWeight: 500
  },
  expired: {
    color: Colors.red,
    fontWeight: 500
  }
}))

function UserInviteTableRow (props) {
  const { user } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const sendInviteAgain = () => props.inviteUser(user.email, user.role)
  // const deleteInvite = () => props.deleteInvite(user.email)

  return (
    <TableRow classes={{ root: classes.root }} hover>
      <TableCell className={[classes.cell, classes.firstCell].join(' ')}>{user.email}</TableCell>
      <TableCell className={classes.cell}>{t(user.role)}</TableCell>
      <TableCell className={classes.cell}>
        <span className={user.isValid ? classes.valid : classes.expired}>
          {formatDateTime(user.validUntil)}
        </span>
      </TableCell>
      <TableCell className={[classes.cell, classes.lastCell].join(' ')}>
        <Button text={t('send_again')} onClick={sendInviteAgain} small />
      </TableCell>
    </TableRow>
  )
}

export default UserInviteTableRow
